import React from 'react';

import { isAuthenticated } from './services/auth';

import Error404 from './pages/errors/404';
import Blinds from './pages/maintenance/blinds';
import BuyIn from './pages/maintenance/buy-in';
import Company from './pages/maintenance/company';
import Employees from './pages/maintenance/employees';
import GameAdmin from './pages/maintenance/game-admin';
import GameType from './pages/maintenance/game-type';
import Occupations from './pages/maintenance/occupation';
import Profile from './pages/maintenance/profile';
import ReportsPoints from './pages/maintenance/reports-points';
import ReportsTables from './pages/maintenance/reports-tables';
import ReportsRakes from './pages/maintenance/reports-rakes';
import Reports from './pages/maintenance/reports-times';
import PermissionGroup from './pages/maintenance/permissions-group';
import PermissionUser from './pages/maintenance/permissions-user';
import TableSize from './pages/maintenance/table-size';
import Tables from './pages/maintenance/tables';
import TablesOpened from './pages/maintenance/Tables-opened';
import UserGroup from './pages/maintenance/user-group';
import User from './pages/maintenance/users';
import DonuzHistoric from './pages/maintenance/donuz-historic';
import DonuzTournament from './pages/maintenance/donuz-tournament';
import Entries from './pages/maintenance/entries';
import Alert from './pages/maintenance/alert';
import AlertAll from './pages/maintenance/alerts-all';
import BenefitCategory from './pages/maintenance/benefit-category';
import BettingMirror from './pages/maintenance/betting-mirror';
import CashRegister from './pages/maintenance/cash-register';
import AvailableProducts from './pages/maintenance/available-products';

const getObject = (uri, id, props, idmenu) => {
  switch (uri) {
    case '/employees':
      return <Employees />;
    case '/entries':
      return <Entries />;
    case '/game-type':
      return <GameType />;
    case '/blinds':
      return <Blinds />;
    case '/table-size':
      return <TableSize />;
    case '/buy-in':
      return <BuyIn />;
    case '/tables':
      return <Tables />;
    case '/play-game':
      return <GameAdmin props={props} id={id} />;
    case '/all-games':
      return <TablesOpened />;
    case '/company':
      return <Company />;
    case '/profile':
      return <Profile />;
    case '/users':
      return <User />;
    case '/user-groups':
      return <UserGroup />;
    case '/times':
      return <Reports />;
    case '/game-table':
      return <ReportsTables />;
    case '/score':
      return <ReportsPoints />;
    case '/alert':
      return <Alert id={id} props={props} />;
    case '/alert-all':
      return <AlertAll />;
    case '/rakes':
      return <ReportsRakes />;
    case '/donuz-tournament':
      return <DonuzTournament />;
    case '/donuz-historic':
      return <DonuzHistoric />;
    case '/occupations':
      return <Occupations />;
    case '/usergroup/permissions':
      return <PermissionGroup id={id} props={props} />;
    case '/benefit-category':
      return <BenefitCategory />;
    case '/mirrors':
      return <BettingMirror />;
    case '/cash-register':
      return <CashRegister />;
    case '/available-products':
      return <AvailableProducts />;
    case '/user/permissions':
      return <PermissionUser id={id} props={props} />;
    default:
      return <Error404 />;
  }
};

export default function getComponent(uri, id, props, idmenu) {
  return isAuthenticated ? getObject(uri, id, props, idmenu) : <Error404 />;
}
