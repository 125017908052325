import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalGameType({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]       = useState(false)

    const [description, setDescription] = useState('');

    const setData = (obj,clear) => {
        setDescription(clear ? '' : obj.blind) 
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-blind'),document.querySelector('#submit-blind').children[0].classList,document.querySelector('#submit-blind').lastChild.nodeValue)

        try {
            let _data = {
                blind: description,
             }

            if (editState)
                await api.put('/blinds/' + data.idblind, _data)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)   
                             } else
                                 loadingButton(false,document.querySelector('#submit-blind'))
                         } else
                             loadingButton(false,document.querySelector('#submit-blind'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-blind'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/blinds', _data)
                      .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-blind'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-blind'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-blind'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-blind'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        if (editState){
            setLoading(true)
            setData(data,false)
            setLoading(false)
        } else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\blinds')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-project-diagram"></i> Propriedades do Blind
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>  
                        <div>
                            <label>Descrição</label>
                            <TextInput 
                                name="registration" 
                                id="registration" 
                                required
                                errorMessage={{ required: "Infome o jogo!" }}
                                className="form-control "
                                value={description} 
                                maxLength={50}
                                autoComplete="off"
                                onChange={e => setDescription(e.target.value)}/>
                        </div>  
                                                        
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-blind" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}