import React, { useEffect, useState } from 'react';
import { Alert, Modal, ModalDialog } from 'react-bootstrap';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import AsyncSelect from 'react-select/async';
import { Button } from 'reactstrap';
import User from '../../assets/user.png';
import ShowMessages from '../../components/Exceptions';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import loadingButton from '../layouts/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import ModalScanner from '../modals/modal-scanner';
import { FaTimes } from 'react-icons/fa';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalAddPlayer({
  showModal,
  handleCloseModal,
  handlePositionPlayer,
  handleReload,
  idgame,
}) {
  const [showScanner, setShowScanner] = useState(false);
  const [scanResult, setScanResult] = useState('');
  const [player, setPlayer] = useState({
    value: '',
    name: '',
    cpf: '',
    nickname: '',
    url: '',
  });
  const [hasCPFMessage, setHasCPFMessage] = useState('');
  const posBoard = useSelector((state) => state.posBoard.posBoardPlayer);

  const handleOpenScanner = () => setShowScanner(true);
  const handleCloseScanner = () => setShowScanner(false);

  const handleScanResult = (result) => {
    setScanResult(result);
    // Atualiza o valor de busca com o resultado do scanner
    searchData(result);
  };

  const handleScanError = (error) => {
    console.error(error);
    handleCloseScanner();
  };

  const searchData = async (value) => {
    // Atualiza o estado do jogador baseado no valor escaneado
    if (value.length < 3) {
      setPlayer({
        value: '',
        name: '',
        cpf: '',
        nickname: '',
        url: '',
      });
    } else {
      try {
        const data = await callApi(value);
        if (data.length > 0) {
          const [playerData] = data; // Assuming the result is an array of players
          setPlayer({
            value: playerData.value,
            name: playerData.label,
            cpf: playerData.cpf,
            url: playerData.url,
            nickname: playerData.nickname,
          });
        } else {
          setPlayer({
            value: '',
            name: '',
            cpf: '',
            nickname: '',
            url: '',
          });
        }
      } catch (error) {
        ShowMessages(error);
      }
    }
  };

  const clearData = () => {
    setPlayer({
      value: '',
      name: '',
      cpf: '',
      nickname: '',
      url: '',
    });
    setHasCPFMessage('');
  };

  useEffect(() => {
    clearData();
  }, [showModal]);

  const handleSubmit = async (event) => {
    handleCloseModal();
    event.preventDefault();

    loadingButton(
      true,
      document.querySelector('#submit-add-player'),
      document.querySelector('#submit-add-player').children[0].classList,
      document.querySelector('#submit-add-player').lastChild.nodeValue,
    );

    if (player.cpf !== '') {
      try {
        let _data = {
          idplayer: player.value,
          player: player.name,
          cpf: player.cpf,
          nickname: player.nickname,
          boardposition: posBoard,
          url: player.url,
          idgame,
        };

        await api
          .post('/setPlayer', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                clearData();
                handlePositionPlayer(posBoard, 'add');
                handleReload(null);
                handleCloseModal();
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-add-player'),
                  'fa fa-play',
                  'Iniciar',
                );
            } else
              loadingButton(
                false,
                document.querySelector('#submit-add-player'),
                'fa fa-play',
                'Iniciar',
              );
          })
          .catch((error) => {
            loadingButton(
              false,
              document.querySelector('#submit-add-player'),
              'fa fa-play',
              'Iniciar',
            );
            ShowMessages(error);
          });
      } catch (response) {
        loadingButton(
          false,
          document.querySelector('#submit-add-player'),
          'fa fa-play',
          'Iniciar',
        );
        ShowMessages(response);
      }
    } else {
      setHasCPFMessage(
        'O jogador que você está tentando adicionar não possui CPF!',
      );
    }
  };

  async function callApi(value) {
    let _data = await api
      .post(`/getPlayers`, { player: value })
      .then((response) => {
        return response.data;
      });
    return _data;
  }

  const handleClientData = (e) => {
    const testerImg = new Image();
    testerImg.src = e.url;

    const isImageLoaded = testerImg.complete;

    if (isImageLoaded) {
      setPlayer({
        ...player,
        value: e.value,
        name: e.label,
        cpf: e.cpf,
        url: e.url,
        nickname: e.nickname,
      });
      return;
    }

    setPlayer({
      ...player,
      value: e.value,
      name: e.label,
      cpf: e.cpf,
      url: '',
      nickname: e.nickname,
    });
  };

  const handleCPFFormat = (value) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return value;
  };

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogClassName="height350 width300"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-project-diagram"></i> Iniciar Jogo
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              <label>Digite o nome para pesquisar</label>
              <div className="d-flex">
                <AsyncSelect
                  cacheOptions
                  placeholder="Nome do jogador"
                  loadingMessage={() => 'Pesquisando...'}
                  noOptionsMessage={() => 'Jogador não encontrado'}
                  value={
                    player ? { label: player.name, value: player.value } : null
                  }
                  onChange={(e) => handleClientData(e)}
                  loadOptions={callApi}
                  defaultOptions
                  className="me-2"
                  styles={{
                    container: (base) => ({
                      ...base,
                      width: 250,
                    }),
                  }}
                />
                <Button
                  onClick={handleOpenScanner}
                  className="btn btn-info"
                  style={{ height: '2.5rem' }}
                >
                  <FontAwesomeIcon icon={faBarcode} />
                </Button>
              </div>
              <Modal show={showScanner} onHide={handleCloseScanner}>
                <Modal.Body>
                  <button
                    type="button"
                    onClick={handleCloseScanner}
                    style={{
                      border: 'none',
                      background: 'transparent',
                      position: 'absolute',
                      top: '-9px',
                      right: '-5px',
                      cursor: 'pointer',
                    }}
                  >
                    <FaTimes size={18} color="black" />
                  </button>
                  <ModalScanner
                    onResult={handleScanResult}
                    onError={handleScanError}
                  />
                </Modal.Body>
              </Modal>
            </div>
            {player?.value !== '' && (
              <div
                style={{
                  border: '1px solid #cacaca',
                  marginTop: '15px',
                  padding: '5px',
                  borderRadius: '5px',
                }}
              >
                <div
                  style={{
                    marginTop: '-15px',
                    background: '#fff',
                    width: 'fit-content',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>Dados do Jogador</span>
                </div>
                <div className="card-add-user">
                  <div className="card-img">
                    <img
                      src={player.url.length !== 0 ? player.url : User}
                      alt="Imagem de Jogador"
                      width="85%"
                      height="85%"
                    />
                  </div>
                  <div className="card-name">
                    <p className="text-center">{player.name}</p>
                    <p className="text-center">Nickname: {player.nickname}</p>
                    <p className="text-center">CPF: {player.cpf}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" id="submit-add-player" className={`btn-theme`}>
            <em className="fa fa-play"></em> Iniciar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
