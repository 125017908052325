import React, { useState, useEffect } from 'react';

import {
  ValidationForm,
  TextInput,
  SelectGroup,
} from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import Draggable from 'react-draggable';

import { Modal, Alert, ModalDialog } from 'react-bootstrap';
import ShowMessages from './../../components/Exceptions';
import MaskWithValidation from './../../components/maskInput';
import MaskedInput from 'react-text-mask';
import CurrencyInput from './../../components/moneyInput';
import Loading from '../../components/Loading';
import TooltipItem from '../../components/tooltip';
import CEP from 'cep-promise';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import AsyncSelect from 'react-select/async';
import setStatistic from './../../services/apiStatistic';
import { checkPermission } from './../../components/security';
import { removeAcento } from '../../common/utils';

import api from '../../services/api';
import { IDCOMPANY, ReadDataStorage } from '../../services/auth';
import loadingButton from './../layouts/Buttons';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalEmployeer({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState(0);

  const [messageDocument, setMessageDocument] = useState('');
  const [messagePhone, setMessagePhone] = useState('');
  const [messageContact, setMessageContact] = useState('');

  const [dataSex, setDataSex] = useState([]);
  const [dataPeopleStatus, setDataPeopleStatus] = useState([]);
  const [dataDocumentType, setDataDocumentType] = useState([]);
  const [dataEmittingOrgan, setDataEmittingOrgan] = useState([]);
  const [dataCNHCategories, setDataCNHCategories] = useState([]);
  const [dataUFs, setDataUFs] = useState([]);
  const [dataContactType, setDataContactType] = useState([]);
  const [dataOccupation, setDataOccupation] = useState([]);
  const [dataFullOccupation, setDataFullOccupation] = useState([]);
  const [dataCounty, setDataCounty] = useState([]);
  const [dataPhoneType, setDataPhoneType] = useState([]);
  const [dataPhoneOperator, setDataPhoneOperator] = useState([]);
  const [dataSettings, setDataSettings] = useState([]);

  const [documentType, setDocumentType] = useState('');
  const [contactType, setContactType] = useState('');
  const [contactValue, setContactValue] = useState('');
  const [documentTypeValue, setDocumentTypeValue] = useState('');

  const [emittingOrgan, setEmittingOrgan] = useState('');
  const [categoryCNH, setCategoryCNH] = useState('');
  const [cnhExpired, setCnhExpired] = useState('');
  const [rgExpedition, setRGExpedition] = useState('');
  const [ufRG, setUFRG] = useState('');
  const [zone, setZone] = useState('');
  const [section, setSection] = useState('');
  const [county, setCounty] = useState('');

  const [name, setName] = useState('');
  const [mother, setMother] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [idsex, setIdSex] = useState(-1);
  const [idpeoplestatus, setIdpeoplestatus] = useState(-1);
  const [comments, setComments] = useState('');

  const [documents, setDocuments] = useState([]);

  const [cep, setCEP] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUF] = useState('');

  const [phoneType, setPhoneType] = useState('');
  const [phoneOperator, setPhoneOperator] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneComments, setPhoneComments] = useState('');
  const [phones, setPhones] = useState([]);

  const [contacts, setContacts] = useState([]);

  const [hiringdate, setHiringdate] = useState('');
  const [terminationdate, setTerminationdate] = useState('');
  const [idoccupation, setIdOccupation] = useState(-1);
  const [salary, setSalary] = useState(0);

  const [titleValue, setTitleValue] = useState('CPF');
  const [mask, setMask] = useState([
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '.',
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    '-',
    /[0-9]/,
    /[0-9]/,
  ]);
  const [removed, setRemoved] = useState(false);

  const checkData = (event, formData, errorInputs) => {
    if (idoccupation === '' || formData.hiringdate === '') {
      if (dataSettings.requiredContractDate && formData.hiringdate === '') {
        document.querySelector('#professional').style.color = '#dc3545';
        document.querySelector('#professional').parentElement.click();
      }

      if (idoccupation === '') {
        document.querySelector('#professional').style.color = '#dc3545';
        document.querySelector('#professional').parentElement.click();
      }
    } else document.querySelector('#professional').style.color = '#656565';

    if (contacts.length <= 0 && dataSettings.requiredEmail) {
      setMessageContact('Por favor, informe um email válido.');
      document.querySelector('#other-contacts').style.color = '#dc3545';
      document.querySelector('#other-contacts').parentElement.click();
    } else {
      if (
        !contacts.find((el) => el.idtype === 0) &&
        dataSettings.requiredEmail
      ) {
        setMessageContact('Por favor, informe um email válido.');
        document.querySelector('#other-contacts').style.color = '#dc3545';
        document.querySelector('#other-contacts').parentElement.click();
      } else {
        document.querySelector('#other-contacts').style.color = '#656565';
      }
    }

    if (
      formData.cep === '' ||
      formData.city === '' ||
      formData.neighborhood === '' ||
      formData.number === '' ||
      formData.street === '' ||
      formData.uf === '' ||
      phones.length <= 0
    ) {
      if (phones.length <= 0 && dataSettings.requiredPhone)
        setMessagePhone('Por favor, informe um número de telefone!');
      else setMessagePhone('');
      if (dataSettings.requiredAddress) {
        document.querySelector('#address-phones').style.color = '#dc3545';
        document.querySelector('#address-phones').parentElement.click();
      }
    } else document.querySelector('#address-phones').style.color = '#656565';

    if (documents.length <= 0 && dataSettings.requiredCPF) {
      setMessageDocument('Por favor, informe um CPF ou CNPJ válido.');
      document.querySelector('#documents').style.color = '#dc3545';
      document.querySelector('#documents').parentElement.click();
    } else {
      if (
        !documents.find((el) => el.idtype === 0 || el.idtype === 1) &&
        dataSettings.requiredCPF
      ) {
        setMessageDocument('Por favor, informe um CPF ou CNPJ válido.');
        document.querySelector('#documents').style.color = '#dc3545';
        document.querySelector('#documents').parentElement.click();
      } else {
        document.querySelector('#documents').style.color = '#656565';
      }
    }

    if (
      formData.mother === '' ||
      formData.birthdate === '' ||
      formData.idsex === '' ||
      (formData.idpeoplestatus = '') ||
      formData.name === ''
    ) {
      if (dataSettings.requiredBirthDate && formData.birthdate === '') {
        document.querySelector('#personal-data').style.color = '#dc3545';
        document.querySelector('#personal-data').parentElement.click();
      }

      if (dataSettings.requiredMother && formData.mother === '') {
        document.querySelector('#personal-data').style.color = '#dc3545';
        document.querySelector('#personal-data').parentElement.click();
      }

      if (
        formData.name === '' ||
        formData.idpeoplestatus === '' ||
        formData.idsex === ''
      ) {
        document.querySelector('#personal-data').style.color = '#dc3545';
        document.querySelector('#personal-data').parentElement.click();
      }
    } else document.querySelector('#personal-data').style.color = '#656565';
  };

  const setData = (obj, clear) => {
    setTabActive(0);

    setMessageDocument('');
    setMessagePhone('');
    setMessageContact('');

    setDocumentType('');
    setContactType('');
    setContactValue('');
    setDocumentTypeValue('');

    setEmittingOrgan('');
    setCategoryCNH('');
    setCnhExpired('');
    setRGExpedition('');
    setUFRG('');
    setZone('');
    setSection('');
    setCounty('');

    setName(clear ? '' : obj.name);
    setMother(clear ? '' : obj.mother);

    setBirthdate(clear ? '' : obj.birthdate ? obj.birthdate.substr(0, 10) : '');
    setIdSex(clear ? -1 : obj.idsex);
    setIdpeoplestatus(clear ? -1 : obj.idpeoplestatus);
    setComments(clear ? '' : obj.comments ? obj.comments : '');

    setDocuments(clear ? [] : obj.documents || []);

    setCEP(clear ? '' : obj.addresses.cep);
    setStreet(clear ? '' : obj.addresses.street);
    setNumber(clear ? '' : obj.addresses.number);
    setComplement(clear ? '' : obj.addresses.complement);
    setNeighborhood(clear ? '' : obj.addresses.neighborhood);
    setCity(clear ? '' : obj.addresses.city);
    setUF(clear ? '' : obj.addresses.uf);

    setPhoneType('');
    setPhoneOperator('');
    setPhone('');
    setPhoneComments('');
    setPhones(clear ? [] : obj.phones || []);

    setContacts(clear ? [] : obj.contacts || []);

    setHiringdate(
      clear ? '' : obj.hiringdate ? obj.hiringdate.substr(0, 10) : '',
    );
    setRemoved(false);
    if (obj.terminationdate !== null && obj.terminationdate !== undefined)
      setRemoved(true);

    setTerminationdate(
      clear
        ? ''
        : obj.terminationdate !== null
        ? obj.terminationdate.substr(0, 10)
        : '',
    );
    setIdOccupation(clear ? '' : obj.idoccupation);
    setSalary(
      clear
        ? 0
        : obj.salary.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
    );

    setTitleValue('CPF');
  };

  const getAddress = (value) => {
    let _cep = value.replace(/\D/g, '');
    if (_cep.length === 8) {
      CEP(_cep)
        .then((data) => {
          setStreet(data.street);
          setCEP(value);
          if (data.street === '') {
            document.querySelector('#street').removeAttribute('disabled');
          } else {
            document.querySelector('#street').setAttribute('disabled', true);
          }

          setNeighborhood(data.neighborhood);
          if (data.neighborhood === '') {
            document.querySelector('#neighborhood').removeAttribute('disabled');
          } else {
            document
              .querySelector('#neighborhood')
              .setAttribute('disabled', true);
          }
          setUF(data.state);
          setCity(data.city);
        })
        .catch();
    } else {
      setStreet('');
      setNeighborhood('');
      setUF('');
      setCity('');
      document.querySelector('#street').setAttribute('disabled', true);
      document.querySelector('#neighborhood').setAttribute('disabled', true);
    }
  };

  const addPhone = () => {
    setMessagePhone('');
    if (phoneType !== '' && phoneOperator !== '' && phone !== '') {
      if (!phones.find((el) => el.phone === phone)) {
        setPhones((phones) =>
          phones.concat({
            idphonetype: phoneType,
            phonetype: dataPhoneType.find((el) => el.value === phoneType)[
              'label'
            ],
            idphoneoperator: phoneOperator,
            phoneoperator: dataPhoneOperator.find(
              (el) => el.value === phoneOperator,
            )['label'],
            phone: phone,
            comments: phoneComments,
          }),
        );
        setPhoneComments('');
        setPhoneOperator('');
        setPhoneType('');
        setPhone('');
      } else setMessagePhone('Telefone informado já está cadastrado!');
    } else {
      if (phoneType === '') {
        setMessagePhone('Por favor, informe o tipo do telefone!');
        return;
      }
      if (phoneOperator === '') {
        setMessagePhone('Por favor, informe a operadora do telefone!');
        return;
      }
      if (phone === '') {
        setMessagePhone('Por favor, informe um número de telefone!');
        return;
      }
    }
  };

  const editPhone = (phone) => {
    let _data = phones.find((el) => el.phone === phone);

    setPhoneComments(_data.comments);
    setPhoneOperator(_data.idphoneoperator);
    setPhoneType(_data.idphonetype);
    setPhone(_data.phone);

    removePhone(phone);
  };

  const removePhone = (phone) => {
    const _remove = phones.filter((t, i) => {
      if (t.phone !== phone) return true;

      return false;
    });
    setPhones(_remove);
  };

  const editDocument = (type) => {
    let _data = documents.find((el) => el.idtype === type);
    setInfoDocumentType(type);
    setDocumentTypeValue(_data.value);

    if (type === 2) {
      let _date = _data.rgExpedition;
      _date =
        _date.substr(6, 4) +
        '-' +
        _date.substr(3, 2) +
        '-' +
        _date.substr(0, 2);

      setRGExpedition(_date);
      setEmittingOrgan(_data.emittingOrgan);
      setUFRG(_data.uf);
    }

    if (type === 3) {
      let _date = _data.cnhExpired;
      _date =
        _date.substr(6, 4) +
        '-' +
        _date.substr(3, 2) +
        '-' +
        _date.substr(0, 2);

      setCnhExpired(_date);
      setCategoryCNH(_data.categoryCNH);
    }

    if (type === 11) {
      setZone(_data.zone);
      setSection(_data.section);
      setCounty(_data.county);
    }

    removeDocument(type);
  };

  const addDocument = () => {
    if (documentType >= 0 && documentTypeValue !== '') {
      let _data = '';
      let _date = '';
      setMessageDocument('');

      if (documentType === 0) {
        if (!cpf.isValid(documentTypeValue)) {
          setMessageDocument('CPF informado é inválido!');
          return;
        }
      }

      if (documentType === 1) {
        if (!cnpj.isValid(documentTypeValue)) {
          setMessageDocument('CNPJ informado é inválido!');
          return;
        }
      }

      if (documentType === 2) {
        if (emittingOrgan === '' || ufRG === '' || rgExpedition === '') {
          if (emittingOrgan === '')
            setMessageDocument('Por favor, informe o orgão emissor!');

          if (ufRG === '')
            setMessageDocument('Por favor, informe a UF de emissão do RG!');

          if (rgExpedition === '')
            setMessageDocument('Por favor, informe a data de expedição do RG!');
          return;
        } else {
          var d = new Date(rgExpedition);
          let _dia = d.getDate() + 1;
          _dia = _dia.toString().length < 2 ? '0' + _dia : _dia;

          let _mes = d.getMonth() + 1;
          _mes = _mes.toString().length < 2 ? '0' + _mes : _mes;

          _date = _dia + '/' + _mes + '/' + d.getFullYear();

          _data =
            '<span style="font-weight:bold">OE</span>: ' +
            dataEmittingOrgan.find((el) => el.value === emittingOrgan)[
              'label'
            ] +
            ' | <span style="font-weight:bold">UF</span>: ' +
            dataUFs.find((el) => el.value === ufRG)['label'] +
            ' | <span style="font-weight:bold">Emissão</span>: ' +
            _date;
        }
      }

      if (documentType === 3) {
        if (categoryCNH === '' || cnhExpired === '') {
          if (categoryCNH === '')
            setMessageDocument('Por favor, informe a categoria da CNH!');

          if (cnhExpired === '')
            setMessageDocument('Por favor, informe o vencimento da CNH!');

          return;
        } else {
          var d1 = new Date(cnhExpired);
          let _dia = d1.getDate() + 1;
          _dia = _dia.toString().length < 2 ? '0' + _dia : _dia;

          let _mes = d1.getMonth() + 1;
          _mes = _mes.toString().length < 2 ? '0' + _mes : _mes;

          _date = _dia + '/' + _mes + '/' + d1.getFullYear();
          _data =
            '<span style="font-weight:bold">Categoria</span>: ' +
            dataCNHCategories.find((el) => el.value === categoryCNH)['label'] +
            ' | <span style="font-weight:bold">Vencimento</span>: ' +
            _date;
        }
      }

      if (documentType === 11) {
        if (zone === '' || section === '' || county === '') {
          if (zone === '')
            setMessageDocument('Por favor, informe a zona eleitoral!');

          if (section === '')
            setMessageDocument('Por favor, informe a seção eleitoral!');

          if (county === '')
            setMessageDocument('Por favor, informe o município eleitoral!');
          return;
        } else {
          _data =
            '<span style="font-weight:bold">Seção</span>: ' +
            section +
            ' | <span style="font-weight:bold">Zona</span>: ' +
            zone +
            ' | <span style="font-weight:bold">Município</span>: ' +
            county.label;
        }
      }

      if (!documents.find((el) => el.idtype === documentType)) {
        setDocuments((documents) =>
          documents.concat({
            idtype: documentType,
            type: titleValue,
            value: documentTypeValue,
            outher: _data,
            uf: ufRG,
            county: county === undefined ? '' : county,
            rgExpedition: _date,
            cnhExpired: _date,
            categoryCNH,
            emittingOrgan,
            zone,
            section,
          }),
        );

        setDocumentType('');
        setDocumentTypeValue('');
        setCategoryCNH('');
        setUFRG('');
        setCnhExpired('');
        setZone('');
        setSection('');
        setCounty('');
        setEmittingOrgan('');
      } else setMessageDocument('Documento já incluso!');
    } else setMessageDocument('Por favor, informe um tipo de documento!');
  };

  const removeDocument = (id) => {
    const _remove = documents.filter((t, i) => {
      if (t.idtype !== id) return true;

      return false;
    });
    setDocuments(_remove);
  };

  const addContact = () => {
    setMessageContact('');
    if (contactType !== '' && contactValue !== '') {
      if (
        !contacts.find(
          (el) => el.idtype === contactType && el.value === contactValue,
        )
      ) {
        setContacts((contacts) =>
          contacts.concat({
            idtype: contactType,
            type: dataContactType.find((el) => el.value === contactType)[
              'label'
            ],
            value: contactValue,
          }),
        );
        setContactType('');
        setContactValue('');
      } else setMessageContact('O contato informado já está cadastrado!');
    } else {
      if (contactType === '') {
        setMessageContact('Por favor, informe um tipo de contato.');
        return;
      }
      if (contactValue === '') {
        setMessageContact('Por favor, informe um contato.');
        return;
      }
    }
  };

  const removeContact = (id, value) => {
    const _remove = contacts.filter((t, i) => {
      if (t.idtype !== id && t.value !== value) return true;

      return false;
    });
    setContacts(_remove);
  };

  const editContact = (id, value) => {
    let _data = contacts.find((el) => el.idtype === id && el.value === value);
    setContactType(_data.idtype);
    setContactValue(_data.value);

    removeContact(id, value);
  };

  const loadOptions = (input, callback) => {
    setTimeout(() => {
      callback(
        dataCounty.filter(({ label }) =>
          removeAcento(label.toLowerCase()).includes(input.toLowerCase()),
        ),
      );
    }, 200);
  };

  const setInfoDocumentType = (value) => {
    switch (parseInt(value)) {
      case 0:
        setTitleValue('CPF');
        setMask([
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
        ]);
        break;
      case 1:
        setTitleValue('CNPJ');
        setMask([
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
        ]);
        break;
      case 2:
        setTitleValue('RG');
        setMask([]);
        break;
      case 3:
        setTitleValue('CNH');
        setMask([
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ]);
        break;
      case 4:
        setTitleValue('CTPS');
        setMask([]);
        break;
      case 5:
        setTitleValue('PIS');
        setMask([]);
        break;
      case 6:
        setTitleValue('Passaporte');
        setMask([]);
        break;
      case 7:
        setTitleValue('Certidão de Casamento');
        setMask([]);
        break;
      case 8:
        setTitleValue('Certidão de Nascimento');
        setMask([]);
        break;
      case 9:
        setTitleValue('Certidão de Óbito');
        setMask([]);
        break;
      case 10:
        setTitleValue('Reservista Militar');
        setMask([]);
        break;
      case 11:
        setTitleValue('Título de Eleitor');
        setMask([]);
        break;
      case 12:
        setTitleValue('Inscrição Estadual');
        setMask([]);
        break;
      case 13:
        setTitleValue('Inscrição Municipal');
        setMask([]);
        break;
      default:
        break;
    }
    setDocumentTypeValue('');
    setDocumentType(parseInt(value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadingButton(
      true,
      document.querySelector('#submit-employeer'),
      document.querySelector('#submit-employeer').children[0].classList,
      document.querySelector('#submit-employeer').lastChild.nodeValue,
    );

    document.querySelector('#personal-data').style.color = '#656565';
    document.querySelector('#documents').style.color = '#656565';
    document.querySelector('#other-contacts').style.color = '#656565';
    document.querySelector('#professional').style.color = '#656565';
    document.querySelector('#address-phones').style.color = '#656565';
    
    let _errors = 0;

    if (documents.length <= 0 && dataSettings.requiredCPF) {
      setMessageContact('Por favor, informe um CPF ou CNPJ válido.');
      document.querySelector('#documents').style.color = '#dc3545';
      document.querySelector('#documents').parentElement.click();
      _errors = +1;
    } else {
      if (
        !documents.find((el) => el.idtype === 0 || el.idtype === 1) &&
        dataSettings.requiredCPF
      ) {
        setMessageContact('Por favor, informe um CPF ou CNPJ válido.');
        document.querySelector('#documents').style.color = '#dc3545';
        document.querySelector('#documents').parentElement.click();
        _errors = +1;
      } else document.querySelector('#documents').style.color = '#656565';
    }

    if (phones.length <= 0 && dataSettings.requiredPhone) {
      setMessagePhone('Por favor, informe um número de telefone!');
      document.querySelector('#address-phones').style.color = '#dc3545';
      document.querySelector('#address-phones').parentElement.click();
      _errors = +1;
    } else setMessagePhone('');

    if (contacts.length <= 0 && dataSettings.requiredEmail) {
      setMessageContact('Por favor, informe um email válido.');
      document.querySelector('#other-contacts').style.color = '#dc3545';
      document.querySelector('#other-contacts').parentElement.click();
      _errors = +1;
    } else {
      if (
        !contacts.find((el) => el.idtype === 0) &&
        dataSettings.requiredEmail
      ) {
        setMessageContact('Por favor, informe um email válido.');
        document.querySelector('#other-contacts').style.color = '#dc3545';
        document.querySelector('#other-contacts').parentElement.click();
        _errors = +1;
      } else document.querySelector('#other-contacts').style.color = '#656565';
    }

    if (idoccupation === '' || hiringdate === '') {
      if (dataSettings.requiredContractDate && hiringdate === '') {
        document.querySelector('#professional').style.color = '#dc3545';
        document.querySelector('#professional').parentElement.click();
        _errors = +1;
      }

      if (idoccupation === '') {
        document.querySelector('#professional').style.color = '#dc3545';
        document.querySelector('#professional').parentElement.click();
        _errors = +1;
      }
    } else document.querySelector('#professional').style.color = '#656565';

    if (
      mother === '' ||
      birthdate === '' ||
      idsex === '' ||
      idpeoplestatus === '' ||
      name === ''
    ) {
      if (dataSettings.requiredBirthDate && birthdate === '') {
        document.querySelector('#personal-data').style.color = '#dc3545';
        document.querySelector('#personal-data').parentElement.click();
        _errors = +1;
      }

      if (dataSettings.requiredMother && mother === '') {
        document.querySelector('#personal-data').style.color = '#dc3545';
        document.querySelector('#personal-data').parentElement.click();
        _errors = +1;
      }

      if (name === '' || idsex === '' || idpeoplestatus === '') {
        document.querySelector('#personal-data').style.color = '#dc3545';
        document.querySelector('#personal-data').parentElement.click();
        _errors = +1;
      }
    } else document.querySelector('#personal-data').style.color = '#656565';

    if (_errors > 0) {
      loadingButton(false, document.querySelector('#submit-employeer'));
      return;
    }

    try {
      let type = 0;
      let idcompany = ReadDataStorage(IDCOMPANY);

      let _data = {
        name,
        mother,
        birthdate,
        idsex,
        idpeoplestatus,
        comments,
        documents,
        cep,
        street,
        number,
        complement,
        neighborhood,
        city,
        uf,
        phones,
        contacts,
        hiringdate,
        terminationdate,
        removed,
        idoccupation,
        salary,
        type,
        idcompany,
      };

      if (editState)
        await api
          .put('/employee/' + data.idpeople, _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              ShowMessages(response.data);
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-employeer'),
                );
            } else
              loadingButton(false, document.querySelector('#submit-employeer'));
          })
          .catch((response) => {
            loadingButton(false, document.querySelector('#submit-employeer'));
            ShowMessages(response);
          });
      else
        await api
          .post('/employee', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-employeer'),
                );
            } else
              loadingButton(false, document.querySelector('#submit-employeer'));
          })
          .catch((response) => {
            loadingButton(false, document.querySelector('#submit-employeer'));
            ShowMessages(response);
          });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-employeer'));
      ShowMessages(response);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      await api.get('/getEmployeeData').then((response) => {
        if (response.ok) {
          setDataSex(response.data.sex);
          setDataPeopleStatus(response.data.peopleStatus);
          setDataDocumentType(response.data.documentType);
          setDataEmittingOrgan(response.data.emittingOrgan);
          setDataCNHCategories(response.data.categoriesCnh);
          setDataUFs(response.data.uf);
          setDataContactType(response.data.contactType);
          setDataOccupation(response.data.occupation);
          setDataFullOccupation(response.data.fulloccupation);
          setDataCounty(response.data.county);
          setDataPhoneType(response.data.phonetype);
          setDataPhoneOperator(response.data.phoneoperator);
          setDataSettings(response.data.settings);
        }
      });

      setLoading(false);
    } catch (response) {
      ShowMessages(response);
    }
  };

  useEffect(() => {
    getData();
    setStatistic('modals\\modal-employeer');
  }, []);

  useEffect(() => {
    if (editState) setData(data, false);
    else setData({}, true);
  }, [data, editState, showModal]);

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogClassName="height500"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        className="modal-content"
        setFocusOnError={true}
        onErrorSubmit={checkData}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-user-friends"></i> Propriedades do Colaborador
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} addClass="heigth330px" />
          <div className={`${loading ? 'hidden' : ''}`}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={tabActive === 0 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(0);
                  }}
                >
                  <span id="personal-data">Dados Pessoais</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 1 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(1);
                  }}
                >
                  <span id="documents">Documentos</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 2 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(2);
                  }}
                >
                  <span id="address-phones">Endereços e Telefones</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 3 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(3);
                  }}
                >
                  <span id="other-contacts">Outros Contatos</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 4 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(4);
                  }}
                >
                  <span id="professional">Profissional</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabActive}>
              <TabPane tabId={0}>
                <div>
                  <label>Nome completo</label>
                  <div className="input-group">
                    <TextInput
                      name="name"
                      id="name"
                      type="text"
                      required
                      maxLength={100}
                      errorMessage={{ required: 'Por favor, informe um nome!' }}
                      value={name}
                      autoFocus={true}
                      className="form-control "
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div>
                  <label>Nome da mãe</label>
                  <div className="input-group">
                    <TextInput
                      name="mother"
                      id="mother"
                      type="text"
                      required={dataSettings?.requiredMother}
                      maxLength={100}
                      errorMessage={{ required: 'Por favor, informe um nome!' }}
                      value={mother}
                      className="form-control "
                      onChange={(e) => setMother(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 33.33 + '%' }}>
                    <label>Data de Nascimento</label>
                    <div className="input-group">
                      <TextInput
                        name="birthdate"
                        id="birthdate"
                        type="date"
                        required={dataSettings?.requiredBirthDate}
                        errorMessage={{
                          required:
                            'Por favor, informe uma data de nascimento!',
                        }}
                        value={birthdate}
                        className="form-control "
                        onChange={(e) => setBirthdate(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div style={{ width: 33.33 + '%', marginLeft: '15px' }}>
                    <label>Sexo</label>
                    <div className="input-group">
                      <SelectGroup
                        name="idsex"
                        id="idsex"
                        required
                        className="form-select"
                        errorMessage={{
                          required: 'Por favor, informe um sexo!',
                        }}
                        placeholder="Selecione"
                        value={idsex}
                        onChange={(e) => setIdSex(parseInt(e.target.value))}
                      >
                        <option value="">Selecione</option>
                        {dataSex.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                  <div style={{ width: 33.33 + '%', marginLeft: '15px' }}>
                    <label>Status</label>
                    <div className="input-group">
                      <SelectGroup
                        name="idpeoplestatus"
                        id="idpeoplestatus"
                        required
                        className="form-select"
                        disabled={!checkPermission(32, 4)}
                        errorMessage={{
                          required: 'Por favor, informe um status!',
                        }}
                        placeholder="Selecione"
                        value={idpeoplestatus}
                        onChange={(e) =>
                          setIdpeoplestatus(parseInt(e.target.value))
                        }
                      >
                        <option value="">Selecione</option>
                        {dataPeopleStatus.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </SelectGroup>
                    </div>
                  </div>
                </div>
                <div>
                  <label>Observações</label>
                  <textarea
                    maxLength={2400}
                    className="form-control"
                    name="comments"
                    id="comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
              </TabPane>
              <TabPane tabId={1}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '175px',
                      minWidth: '175px',
                      maxWidth: '175px',
                      marginBottom: '10px',
                    }}
                  >
                    <label>Tipo de Documento</label>
                    <div id="documentType">
                      <select
                        name="documenttype"
                        id="documenttype"
                        placeholder="Selecione"
                        value={documentType}
                        className="form-select"
                        onChange={(e) =>
                          setInfoDocumentType(parseInt(e.target.value))
                        }
                      >
                        <option value="-1">Selecione</option>
                        {dataDocumentType.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div
                    style={{ marginLeft: '15px' }}
                    className={`${documentType < 0 ? 'hidden' : ''}`}
                  >
                    <label>{titleValue}</label>
                    <div className="input-group">
                      {mask.length > 0 ? (
                        <MaskedInput
                          name="documenttypevalue"
                          id="documenttypevalue"
                          type="text"
                          maxLength={25}
                          value={documentTypeValue}
                          className="form-control"
                          onChange={(e) => setDocumentTypeValue(e.target.value)}
                          autoComplete="off"
                          mask={mask}
                        />
                      ) : (
                        <input
                          name="documenttypevalue"
                          id="documenttypevalue"
                          type="text"
                          maxLength={25}
                          value={documentTypeValue}
                          className="form-control"
                          onChange={(e) => setDocumentTypeValue(e.target.value)}
                          autoComplete="off"
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`${documentType < 0 ? 'hidden' : ''}`}
                    style={{ display: 'flex' }}
                  >
                    <div
                      style={{ marginLeft: '15px', maxWidth: '80px' }}
                      className={`${documentType === 11 ? '' : 'hidden'}`}
                    >
                      <label>Zona</label>
                      <div className="input-group">
                        <input
                          name="zone"
                          id="zone"
                          type="text"
                          maxLength={8}
                          className="form-control "
                          onChange={(e) => setZone(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div
                      style={{ marginLeft: '15px', maxWidth: '80px' }}
                      className={`${documentType === 11 ? '' : 'hidden'}`}
                    >
                      <label>Seção</label>
                      <div className="input-group">
                        <input
                          name="section"
                          id="section"
                          type="text"
                          maxLength={8}
                          className="form-control "
                          onChange={(e) => setSection(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div
                      style={{ marginLeft: '15px', minWidth: '180px' }}
                      className={`${documentType === 11 ? '' : 'hidden'}`}
                    >
                      <label>Município</label>
                      <AsyncSelect
                        options={dataCounty}
                        ignoreCase
                        ignoreAccents
                        value={county}
                        loadOptions={loadOptions}
                        onChange={(value) =>
                          setCounty(value === null ? 0 : value)
                        }
                        placeholder="Digite para pesquisar..."
                        noOptionsMessage={() => 'Não encontrado'}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '15px',
                        minWidth: '100px',
                        maxWidth: '100px',
                        Width: '100px',
                      }}
                      className={`${documentType === 3 ? '' : 'hidden'}`}
                    >
                      <label>Categoria</label>
                      <select
                        name="categoriescnh"
                        id="categoriescnh"
                        placeholder="Selecione"
                        className="form-select"
                        onChange={(e) =>
                          setCategoryCNH(parseInt(e.target.value))
                        }
                        value={categoryCNH}
                      >
                        <option value="">Selecione</option>
                        {dataCNHCategories.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      style={{ marginLeft: '15px', minWidth: '132px' }}
                      className={`${documentType === 3 ? '' : 'hidden'}`}
                    >
                      <label>Vencimento</label>
                      <input
                        onChange={(e) => setCnhExpired(e.target.value)}
                        type="date"
                        value={cnhExpired}
                        id="CNHExpired"
                        name="CNHExpired"
                        className="form-control"
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '15px',
                        minWidth: '100px',
                        maxWidth: '100px',
                        Width: '100px',
                      }}
                      className={`${documentType === 2 ? '' : 'hidden'}`}
                    >
                      <label>OE</label>
                      <select
                        name="emitting"
                        id="emitting"
                        className="form-select"
                        onChange={(e) =>
                          setEmittingOrgan(parseInt(e.target.value))
                        }
                        value={emittingOrgan}
                      >
                        <option value="">Selecione</option>
                        {dataEmittingOrgan.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      style={{
                        marginLeft: '15px',
                        minWidth: '80px',
                        maxWidth: '80px',
                        Width: '80px',
                      }}
                      className={`${documentType === 2 ? '' : 'hidden'}`}
                    >
                      <label>UF</label>
                      <select
                        name="ufRG"
                        id="ufRG"
                        className="form-select"
                        onChange={(e) => setUFRG(parseInt(e.target.value))}
                        value={ufRG}
                      >
                        <option value="">Selecione</option>
                        {dataUFs.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      style={{ marginLeft: '15px', minWidth: '132px' }}
                      className={`${documentType === 2 ? '' : 'hidden'}`}
                    >
                      <label>Expedição</label>
                      <input
                        onChange={(e) => setRGExpedition(e.target.value)}
                        type="date"
                        value={rgExpedition}
                        id="RGExpedition"
                        name="RGExpedition"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className={`${documentType < 0 ? 'hidden' : ''}`}
                  >
                    <TooltipItem
                      content="Click aqui para inserir um novo documento"
                      className="background-info"
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        style={{ marginLeft: '15px', marginTop: '8px' }}
                        onClick={(e) => addDocument()}
                      >
                        <em className="fa fa-arrow-down"></em>
                      </button>
                    </TooltipItem>
                  </div>
                </div>

                <div style={{ overflow: 'auto', height: '187px' }}>
                  <Alert
                    variant="danger"
                    className={`${messageDocument === '' ? 'hidden' : ''}`}
                  >
                    {messageDocument}
                  </Alert>
                  <table className="table table-striped w-100">
                    <thead className="thead-theme">
                      <tr className="head-th">
                        <td
                          style={{
                            width: '175px',
                            minWidth: '175px',
                            maxWidth: '175px',
                          }}
                          className="head-th"
                        >
                          Tipo
                        </td>
                        <td
                          style={{
                            width: '125px',
                            minWidth: '125px',
                            maxWidth: '125px',
                          }}
                          className="head-th"
                        >
                          Documento
                        </td>
                        <td className="head-th">Outras Informações</td>
                        <td style={{ textAlign: 'center', width: '100px' }}>
                          ...
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.map((item, key) => {
                        return (
                          <tr
                            className="head-th"
                            key={key}
                            onDoubleClick={(e) => editDocument(item.idtype)}
                          >
                            <td>{item.type}</td>
                            <td>{item.value}</td>
                            <td
                              dangerouslySetInnerHTML={{ __html: item.outher }}
                            ></td>

                            <td style={{ textAlign: 'center' }}>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={(e) => removeDocument(item.idtype)}
                              >
                                <em className="fa fa-trash"></em>
                              </button>
                              <button
                                type="button"
                                className="btn btn-warning  btn-sm"
                                style={{ marginLeft: '5px' }}
                                onClick={(e) => editDocument(item.idtype)}
                              >
                                <em className="fa fa-edit"></em>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className={`box-sem-dados bg-warning ${
                      documents.length > 0 ? 'hidden' : ''
                    }`}
                  >
                    Ainda não foi incluido nenhum documento.
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 17 + '%' }}>
                      <label>CEP</label>
                      <div className="input-group">
                        <MaskWithValidation
                          name="cep"
                          id="cep"
                          type="text"
                          required={dataSettings?.requiredAddress}
                          errorMessage={{
                            required: 'Por favor, informe um cep!',
                          }}
                          className="form-control "
                          value={cep}
                          onChange={(e) => getAddress(e.target.value)}
                          autoComplete="off"
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '-',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '15px', width: 64 + '%' }}>
                      <label>Endereço</label>
                      <div className="input-group">
                        <TextInput
                          name="street"
                          id="street"
                          type="text"
                          required={dataSettings?.requiredAddress}
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe uma rua!',
                          }}
                          value={street}
                          className="form-control "
                          onChange={(e) => setStreet(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '15px', width: 19 + '%' }}>
                      <label>Número</label>
                      <div className="input-group">
                        <TextInput
                          name="number"
                          id="number"
                          type="text"
                          required={dataSettings?.requiredAddress}
                          maxLength={15}
                          errorMessage={{
                            required: 'Por favor, informe um número!',
                          }}
                          value={number}
                          className="form-control "
                          onChange={(e) => setNumber(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 29 + '%' }}>
                      <label>Complemento</label>
                      <div className="input-group">
                        <TextInput
                          name="complement"
                          id="complement"
                          type="text"
                          value={complement}
                          maxLength={100}
                          style={{ height: '2.1875rem' }}
                          className="form-control "
                          onChange={(e) => setComplement(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 29 + '%', marginLeft: '15px' }}>
                      <label>Bairro</label>
                      <div className="input-group">
                        <TextInput
                          name="neighborhood"
                          id="neighborhood"
                          type="text"
                          required={dataSettings?.requiredAddress}
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe um bairro!',
                          }}
                          value={neighborhood}
                          style={{ height: '2.1875rem' }}
                          className="form-control "
                          onChange={(e) => setNeighborhood(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 29 + '%', marginLeft: '15px' }}>
                      <label>Cidade</label>
                      <div className="input-group">
                        <TextInput
                          name="city"
                          id="city"
                          type="text"
                          required={dataSettings?.requiredAddress}
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe uma cidade!',
                          }}
                          value={city}
                          className="form-control "
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 13 + '%', marginLeft: '15px' }}>
                      <label>UF</label>
                      <div className="input-group">
                        <TextInput
                          name="uf"
                          disabled
                          id="uf"
                          type="text"
                          required={dataSettings?.requiredAddress}
                          maxLength={2}
                          errorMessage={{
                            required: 'Por favor, informe uma estado!',
                          }}
                          value={uf}
                          className="form-control "
                          onChange={(e) => setUF(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="divider"></div>

                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: 50 + '%' }}>
                        <label>Tipo de Telefone</label>
                        <div className="dv-typecontact">
                          <select
                            name="phonetype"
                            id="phonetype"
                            placeholder="Selecione"
                            value={phoneType}
                            className="form-select"
                            onChange={(e) =>
                              setPhoneType(parseInt(e.target.value))
                            }
                          >
                            <option value="">Selecione</option>
                            {dataPhoneType.map((data, id) => {
                              return (
                                <option key={id} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div style={{ width: 50 + '%', marginLeft: '15px' }}>
                        <label>Tipo de Operadora</label>
                        <div className="dv-typecontact">
                          <select
                            name="contacttype"
                            id="contacttype"
                            placeholder="Selecione"
                            value={phoneOperator}
                            className="form-select"
                            onChange={(e) =>
                              setPhoneOperator(parseInt(e.target.value))
                            }
                          >
                            <option value="">Selecione</option>
                            {dataPhoneOperator.map((data, id) => {
                              return (
                                <option key={id} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '120px' }}>
                        <label>Telefone</label>
                        <div className="input-group">
                          <MaskedInput
                            name="phone"
                            id="phone"
                            type="text"
                            value={phone}
                            className="form-control "
                            onChange={(e) => setPhone(e.target.value)}
                            mask={
                              ![1, 2, 99].includes(parseInt(phoneType))
                                ? [
                                    '(',
                                    /[0-9]/,
                                    /[0-9]/,
                                    ')',
                                    ' ',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    '-',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                  ]
                                : [
                                    '(',
                                    /[0-9]/,
                                    /[0-9]/,
                                    ')',
                                    ' ',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    '-',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                  ]
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div style={{ width: '130px', marginLeft: '15px' }}>
                        <label>Observação</label>
                        <div className="input-group">
                          <input
                            name="phonecomments"
                            id="phonecomments"
                            type="text"
                            value={phoneComments}
                            maxLength={15}
                            className="form-control "
                            onChange={(e) => setPhoneComments(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <TooltipItem
                          content="Click aqui para inserir um novo telefone"
                          className="background-info"
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-success"
                            style={{ marginLeft: '15px', marginTop: '19px' }}
                            onClick={(e) => addPhone()}
                          >
                            <em className="fa fa-arrow-right"></em>
                          </button>
                        </TooltipItem>
                      </div>
                    </div>
                    <Alert
                      variant="danger"
                      style={{ marginTop: '5px', marginBottom: '0px' }}
                      className={`${messagePhone === '' ? 'hidden' : ''}`}
                    >
                      {messagePhone}
                    </Alert>
                  </div>
                  <div className="width75" style={{ marginLeft: '15px' }}>
                    <div style={{ overflow: 'auto', height: '150px' }}>
                      <table className="table table-striped w-100">
                        <thead className="thead-theme">
                          <tr className="head-th">
                            <td
                              className="head-th"
                              style={{
                                width: '85px',
                                minWidth: '85px',
                                maxWidth: '85px',
                              }}
                            >
                              Tipo
                            </td>
                            <td
                              className="head-th"
                              style={{
                                width: '70px',
                                minWidth: '70px',
                                maxWidth: '70px',
                              }}
                            >
                              Operadora
                            </td>
                            <td
                              className="head-th"
                              style={{
                                width: '80px',
                                minWidth: '80px',
                                maxWidth: '75px',
                              }}
                            >
                              Telefone
                            </td>
                            <td style={{ textAlign: 'center', width: '60px' }}>
                              ...
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {phones.map((item, key) => {
                            return (
                              <tr
                                className="head-th"
                                key={key}
                                onDoubleClick={(e) => editPhone(item.phone)}
                              >
                                <td title={item.comments}>{item.phonetype}</td>
                                <td title={item.comments}>
                                  {item.phoneoperator}
                                </td>
                                <td title={item.comments}>{item.phone}</td>
                                <td style={{ textAlign: 'center' }}>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={(e) => removePhone(item.phone)}
                                  >
                                    <em className="fa fa-trash"></em>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-warning  btn-sm"
                                    onClick={(e) => editPhone(item.phone)}
                                    style={{ marginLeft: '5px' }}
                                  >
                                    <em className="fa fa-edit"></em>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div
                        className={`box-sem-dados bg-warning ${
                          phones.length > 0 ? 'hidden' : ''
                        }`}
                        style={{ padding: '15px' }}
                      >
                        Ainda não foi incluido nenhum telefone.
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={3}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '175px',
                      minWidth: '175px',
                      maxWidth: '175px',
                      marginBottom: '10px',
                    }}
                  >
                    <label>Tipo de Contato</label>
                    <div className="dv-typecontact">
                      <select
                        name="contacttype"
                        id="contacttype"
                        placeholder="Selecione"
                        className="form-select"
                        value={contactType}
                        onChange={(e) =>
                          setContactType(parseInt(e.target.value))
                        }
                      >
                        <option value="">Selecione</option>
                        {dataContactType.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div style={{ marginLeft: '15px', width: '275px' }}>
                    <label>Contato</label>
                    <div className="input-group">
                      <input
                        name="contact"
                        id="contact"
                        type="text"
                        value={contactValue}
                        maxLength={100}
                        className="form-control "
                        onChange={(e) => setContactValue(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <TooltipItem
                      content="Click aqui para inserir um novo contato"
                      className="background-info"
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        style={{ marginLeft: '15px', marginTop: '8px' }}
                        onClick={(e) => addContact()}
                      >
                        <em className="fa fa-arrow-down"></em>
                      </button>
                    </TooltipItem>
                  </div>
                </div>

                <div style={{ overflow: 'auto', height: '187px' }}>
                  <Alert
                    variant="danger"
                    className={`${messageContact === '' ? 'hidden' : ''}`}
                  >
                    {messageContact}
                  </Alert>
                  <table className="table table-striped w-100">
                    <thead className="thead-theme">
                      <tr className="head-th">
                        <td
                          className="head-th"
                          style={{
                            width: '175px',
                            minWidth: '175px',
                            maxWidth: '175px',
                          }}
                        >
                          Tipo de Contato
                        </td>
                        <td className="head-th">Contato</td>
                        <td style={{ textAlign: 'center', width: '100px' }}>
                          ...
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((item, key) => {
                        return (
                          <tr
                            className="head-th"
                            key={key}
                            onDoubleClick={(e) =>
                              editContact(item.idtype, item.value)
                            }
                          >
                            <td>{item.type}</td>
                            <td>{item.value}</td>

                            <td style={{ textAlign: 'center' }}>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={(e) =>
                                  removeContact(item.idtype, item.value)
                                }
                              >
                                <em className="fa fa-trash"></em>
                              </button>
                              <button
                                type="button"
                                className="btn btn-warning  btn-sm"
                                onClick={(e) =>
                                  editContact(item.idtype, item.value)
                                }
                                style={{ marginLeft: '5px' }}
                              >
                                <em className="fa fa-edit"></em>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className={`box-sem-dados bg-warning ${
                      contacts.length > 0 ? 'hidden' : ''
                    }`}
                  >
                    Ainda não foi incluido nenhum contato.
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={4}>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 22 + '%' }}>
                      <label>Contratação</label>
                      <div className="input-group">
                        <TextInput
                          name="hiringDate"
                          id="hiringDate"
                          type="date"
                          required={dataSettings?.requiredContractDate}
                          errorMessage={{
                            required: 'Por favor, informe uma data!',
                          }}
                          value={hiringdate}
                          className="form-control "
                          onChange={(e) => setHiringdate(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 22 + '%', marginLeft: '15px' }}>
                      <div
                        className="position-relative form-check form-check-inline"
                        style={{ marginBottom: '-5px' }}
                      >
                        <div className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            value={removed}
                            checked={removed}
                            onChange={(e) => setRemoved(!removed)}
                          />
                          <span>Desligado?</span>
                        </div>
                      </div>
                      <div className="input-group">
                        <TextInput
                          name="terminationdate"
                          id="terminationdate"
                          type="date"
                          disabled={!removed}
                          errorMessage={{
                            required: 'Por favor, informe uma data!',
                          }}
                          value={terminationdate}
                          className="form-control "
                          onChange={(e) => setTerminationdate(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 36 + '%', marginLeft: '15px' }}>
                      <div id="dv-occupation">
                        <label>Cargo</label>
                        <div>
                          <SelectGroup
                            name="idoccupation"
                            id="idoccupation"
                            required
                            errorMessage={{
                              required: 'Por favor, informe um cargo!',
                            }}
                            placeholder="Selecione"
                            value={idoccupation}
                            className="form-select"
                            onChange={(e) =>
                              setIdOccupation(parseInt(e.target.value))
                            }
                          >
                            <option value="">Selecione</option>
                            {dataOccupation.map((data, id) => {
                              return (
                                <option key={id} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </SelectGroup>
                        </div>
                        <div className="invalid-feedback">
                          Por favor, informe um cargo
                        </div>
                      </div>
                    </div>
                    <div style={{ width: 20 + '%', marginLeft: '15px' }}>
                      <label>Salário</label>
                      <div className="input-group">
                        <CurrencyInput
                          name="salary"
                          id="salary"
                          type="text"
                          required
                          errorMessage={{
                            required: 'Por favor, informe um valor',
                          }}
                          value={salary}
                          className="form-control text-right"
                          onChange={setSalary}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-employeer"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
