import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import {
  SelectGroup,
  TextInput,
  ValidationForm,
} from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

const NUMBER_OF_PLAYERS = [
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];

export default function ModalGameType({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);

  const [gameType, setGameType] = useState('');
  const [blue, setBlue] = useState('');

  const setData = (obj, clear) => {
    setGameType(clear ? '' : obj.gametype);
    setBlue(clear ? '' : obj.blue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadingButton(
      true,
      document.querySelector('#submit-game-type'),
      document.querySelector('#submit-game-type').children[0].classList,
      document.querySelector('#submit-game-type').lastChild.nodeValue,
    );

    try {
      let _data = {
        gametype: gameType,
        blue: blue,
      };

      if (editState)
        await api
          .put('/game-type/' + data.idgametype, _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-game-type'),
                );
            } else
              loadingButton(false, document.querySelector('#submit-game-type'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-game-type'));
            ShowMessages(error);
          });
      else
        await api
          .post('/game-type', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-game-type'),
                );
            } else
              loadingButton(false, document.querySelector('#submit-game-type'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-game-type'));
            ShowMessages(error);
          });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-game-type'));
      ShowMessages(response);
    }
  };

  useEffect(() => {
    if (editState) {
      setLoading(true);
      setData(data, false);
      setLoading(false);
    } else setData({}, true);
  }, [data, editState, showModal]);

  useEffect(() => {
    setStatistic('modals\\game-type');
  }, []);

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-project-diagram"></i> Propriedades do Tipo de
            Jogo
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} />
          <div className={`${loading ? 'hidden' : ''}`}>
            <div>
              <label>Descrição do Jogo</label>
              <TextInput
                name="registration"
                id="registration"
                required
                errorMessage={{ required: 'Infome o jogo!' }}
                className="form-control "
                value={gameType}
                maxLength={50}
                autoComplete="off"
                onChange={(e) => setGameType(e.target.value)}
              />
            </div>
            <div>
              <label>Blue | Multiplicador</label>
              <TextInput
                name="blue"
                id="blue"
                required
                errorMessage={{ required: 'Infome o valor!' }}
                className="form-control w-50"
                value={blue}
                maxLength={10}
                autoComplete="off"
                onChange={(e) => setBlue(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-game-type"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
