import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../services/api';
import {
  CONTRACT,
  EXPIRES_IN,
  IDCOMPANY,
  IDUSER,
  isAuthenticated,
  ReadDataStorage,
  REGISTRATION,
  TOKEN_KEY,
  USERNAME,
  USER_DATA,
  WriteDataStorage,
} from '../../services/auth';
import defaultLogo from './../../assets/logo.png';

import ShowMessages from '../../components/Exceptions';

import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';

import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { GetCookieMessage, SetCookieMessage } from './../../components/Cookies';

import loadingButton from './../layouts/Buttons';

import setStatistic from './../../services/apiStatistic';
import { ImageContext } from '../../_contexts/imagecontext';

export default function Login(props) {
  const [update, setUpdate] = useState(false);
  const [registration, setRegistration] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const matchPassword = (value) => {
    return value && value === password;
  };

  const { logo, loading } = useContext(ImageContext);

  useEffect(() => {
    if (GetCookieMessage('LoginMsg', '') !== '') {
      NotificationManager.error(
        GetCookieMessage('LoginMsg', ''),
        'Erro!',
        4000,
      );
      SetCookieMessage('LoginMsg', '');
    }

    let _passOnFocus = false;
    if (
      ReadDataStorage(REGISTRATION) !== '' &&
      ReadDataStorage(REGISTRATION) !== null
    ) {
      setRegistration(ReadDataStorage(REGISTRATION));
      setRemember(true);
      _passOnFocus = true;
    }

    if (isAuthenticated()) window.location.href = '/';
    else {
      // Use `setTimeout` to ensure the DOM is updated before trying to focus
      setTimeout(() => {
        const focusElement = _passOnFocus
          ? document.querySelector('#password')
          : document.querySelector('#registration');
        if (focusElement) focusElement.focus();
      }, 0);
    }
  }, []);

  useEffect(() => {
    setStatistic('login');
  }, []);

  const handleSubmit = async (event) => {
    if (update)
      loadingButton(
        true,
        document.querySelector('#update-password'),
        document.querySelector('#update-password').children[0].classList,
        document.querySelector('#update-password').lastChild.nodeValue,
      );
    else
      loadingButton(
        true,
        document.querySelector('#submit'),
        document.querySelector('#submit').children[0].classList,
        document.querySelector('#submit').lastChild.nodeValue,
      );

    event.preventDefault();
    try {
      await api
        .post('/authenticate', {
          registration,
          password,
          update,
        })
        .then((response) => {
          if (response.ok) {
            if (response.data.update) {
              setUpdate(true);
              setPassword('');
              setTimeout(() => {
                const passwordInput = document.querySelector('#password');
                if (passwordInput) passwordInput.focus();
              }, 0);
            } else if (response.data.redirect) {
              ShowMessages(response.data.message);
              setUpdate(false);
              setPassword('');
              window.location.href = '/login';
            } else {
              setUpdate(false);
              const { token, user, idcompany } = response.data;

              WriteDataStorage(TOKEN_KEY, JSON.stringify(token));
              WriteDataStorage(USER_DATA, JSON.stringify(user));
              WriteDataStorage(IDCOMPANY, idcompany);
              WriteDataStorage(USERNAME, response.data.user.name);
              WriteDataStorage(IDUSER, response.data.user.iduser);
              WriteDataStorage(CONTRACT, response.data.hash);
              WriteDataStorage(EXPIRES_IN, response.data.expires_in);

              if (remember === true)
                WriteDataStorage(REGISTRATION, registration);
              else WriteDataStorage(REGISTRATION, '');
              window.location.href = '/';
            }
          } else {
            if (update)
              loadingButton(
                false,
                document.querySelector('#update-password'),
                'fa fa-key',
                'Alterar Senha',
              );
            else
              loadingButton(
                false,
                document.querySelector('#submit'),
                'fa fa-sign-in-alt',
                'Acessar',
              );
          }
        });
    } catch (response) {
      localStorage.clear();
      if (update)
        loadingButton(
          false,
          document.querySelector('#update-password'),
          'fa fa-key',
          'Alterar Senha',
        );
      else
        loadingButton(
          false,
          document.querySelector('#submit'),
          'fa fa-sign-in-alt',
          'Acessar',
        );

      setRegistration('');
      setPassword('');
      // NotificationManager.error("Usuário ou senha Inválido(s)", 'Erro!', 2000);
      //console.log(response)
    }
  };

  return (
    <>
      <div className="background"></div>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <NotificationContainer />
        {!loading && (
          <div className="content">
            <div className="block-center wd-xl">
              <div className="card card-flat">
                <div className="card-header text-center bg-blue">
                  <Link to="/login">
                    <img
                      className="block-center rounded imgWidth"
                      src={logo || defaultLogo}
                      alt={process.env.REACT_APP_NAME}
                    />
                  </Link>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '5px',
                    fontSize: '12px',
                    color: '#cacaca',
                  }}
                >
                  Versão: {process.env.REACT_APP_VERSION}
                </div>
                <div className="card-body">
                  <ValidationForm
                    onSubmit={handleSubmit}
                    setFocusOnError={true}
                  >
                    {update === false ? (
                      <div>
                        <div className="input-group mb-3">
                          <TextInput
                            name="registration"
                            id="registration"
                            type="text"
                            placeholder="Matrícula"
                            required
                            maxLength={60}
                            errorMessage={{
                              required:
                                'Por favor, informe uma matricula válida!',
                            }}
                            value={registration}
                            className="form-control "
                            onChange={(e) =>
                              setRegistration(e.target.value.toUpperCase())
                            }
                            autoComplete="off"
                          />
                        </div>
                        <div className="input-group mb-2">
                          <TextInput
                            name="password"
                            id="password"
                            type="password"
                            placeholder="Senha"
                            required
                            maxLength={20}
                            errorMessage={{
                              required: 'Por favor, informe uma senha válida!',
                            }}
                            value={password}
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                        <div className="input-group mb-4">
                          <div className="checkbox c-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                checked={remember}
                                className="form-check-input"
                                value={remember}
                                onChange={(e) => setRemember(!remember)}
                              />
                              <span className="fa fa-check"></span>Lembrar minha
                              matrícula
                            </label>
                          </div>
                        </div>

                        <button
                          className="btn btn-block btn-theme mt-3"
                          type="submit"
                          id="submit"
                          style={{ width: '100%' }}
                        >
                          <em className="fa fa-sign-in-alt"></em> Acessar
                        </button>

                        <div className="clearfix" style={{ marginTop: '15px' }}>
                          <div className="float-right">
                            <Link to="/forgot" className="text-muted">
                              Esqueceu sua senha?
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="input-group mb-2">
                          <TextInput
                            name="password"
                            id="password"
                            type="password"
                            placeholder="Informe uma nova senha"
                            required
                            errorMessage={{
                              required: 'Por favor, informe uma senha válida!',
                              pattern:
                                'A senha deve conter pelo menos 6 dígitos.',
                            }}
                            value={password}
                            pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                        <div className="input-group mb-3">
                          <TextInput
                            name="confirmPassword"
                            id="confirmPassword"
                            type="password"
                            placeholder="Confirme a nova senha"
                            required
                            errorMessage={{
                              required: 'Por favor, confirme a senha!',
                              pattern:
                                'A senha devem conter pelo menos 6 dígitos.',
                              validator:
                                'As senhas não coincidem. Por favor, tente novamente.',
                            }}
                            value={confirmPassword}
                            pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                            className="form-control"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            autoComplete="off"
                            validate={matchPassword}
                          />
                        </div>

                        <button
                          className="btn btn-block btn-theme mt-3"
                          type="submit"
                          id="update-password"
                          style={{ width: '100%' }}
                        >
                          <em className="fa fa-key"></em> Alterar Senha
                        </button>
                      </div>
                    )}
                  </ValidationForm>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
