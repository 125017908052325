import React, { useState, useEffect } from 'react';

import {
  ValidationForm,
  TextInput,
  SelectGroup,
} from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import { Modal, Alert, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import TooltipItem from '../../components/tooltip';
import Draggable from 'react-draggable';
import Loading from '../../components/Loading';
import MaskWithValidation from './../../components/maskInput';
import MaskedInput from 'react-text-mask';
import { cnpj } from 'cpf-cnpj-validator';

import { removeAcento } from '../../common/utils';

import AsyncSelect from 'react-select/async';
import CEP from 'cep-promise';
import api from '../../services/api';
import setStatistic from './../../services/apiStatistic';

import SEM_IMAGEM from './../../assets/sem-imagem.png';
import loadingButton from './../layouts/Buttons';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalCompany({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);
  const [tabActive, setTabActive] = useState(0);

  const [messageDocument, setMessageDocument] = useState('');
  const [messagePhone, setMessagePhone] = useState('');
  const [messageContact, setMessageContact] = useState('');
  const [messageCNAE, setMessageCNAE] = useState('');

  const [dataCompanyDocumentType, setDataCompanyDocumentType] = useState([]);
  const [dataCNAE, setDataCNAE] = useState([]);
  const [dataContactType, setDataContactType] = useState([]);
  const [dataPhoneType, setDataPhoneType] = useState([]);
  const [dataPhoneOperator, setDataPhoneOperator] = useState([]);
  const [dataTaxRegime, setDataTaxRegime] = useState([]);
  const [dataLegalNature, setDataLegalNature] = useState([]);

  const [bhSundayStart, setBhSundayStart] = useState('');
  const [bhSundayEnd, setBhSundayEnd] = useState('');
  const [bhMondayStart, setBhMondayStart] = useState('');
  const [bhMondayEnd, setBhMondayEnd] = useState('');
  const [bhTuesdayStart, setBhTuesdayStart] = useState('');
  const [bhTuesdayEnd, setBhTuesdayEnd] = useState('');
  const [bhWednesdayStart, setBhWednesdayStart] = useState('');
  const [bhWednesdayEnd, setBhWednesdayEnd] = useState('');
  const [bhThursdayStart, setBhThursdayStart] = useState('');
  const [bhThursdayEnd, setBhThursdayEnd] = useState('');
  const [bhFridayStart, setBhFridayStart] = useState('');
  const [bhFridayEnd, setBhFridayEnd] = useState('');
  const [bhSaturdayStart, setBhSaturdayStart] = useState('');
  const [bhSaturdayEnd, setBhSaturdayEnd] = useState('');

  const [company, setCompany] = useState('');
  const [foundationdate, setFoundationdate] = useState('');
  const [fantasy, setFantasy] = useState('');
  const [website, setWebsite] = useState('');
  const [logo, setLogo] = useState(null);
  const [comments, setComments] = useState('');

  const [cep, setCEP] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUF] = useState('');

  const [documents, setDocuments] = useState([]);

  const [phoneType, setPhoneType] = useState('');
  const [phoneOperator, setPhoneOperator] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneComments, setPhoneComments] = useState('');
  const [phones, setPhones] = useState([]);

  const [documentType, setDocumentType] = useState('');
  const [contactType, setContactType] = useState('');
  const [contactValue, setContactValue] = useState('');
  const [documentTypeValue, setDocumentTypeValue] = useState('');

  const [cnae, setCNAE] = useState('');
  const [cnaePrincipal, setCNAEPrincipal] = useState(false);
  const [cnaes, setCNAEs] = useState([]);
  const [contacts, setContacts] = useState([]);

  const [taxRegime, setTaxRegime] = useState(-1);
  const [legalnature, setLegalNature] = useState([]);

  const checkData = (event, formData, errorInputs) => {
    if (formData.taxregime === '') {
      document.querySelector('#fiscal').style.color = '#dc3545';
      document.querySelector('#fiscal').parentElement.click();
    } else document.querySelector('#fiscal').style.color = '#656565';

    if (
      formData.cep === '' ||
      formData.city === '' ||
      formData.neighborhood === '' ||
      formData.number === '' ||
      formData.street === '' ||
      formData.uf === '' ||
      phones.length <= 0
    ) {
      if (phones.length <= 0)
        setMessagePhone('Por favor, informe um número de telefone!');
      else setMessagePhone('');
      document.querySelector('#address-phones').style.color = '#dc3545';
      document.querySelector('#address-phones').parentElement.click();
    } else document.querySelector('#address-phones').style.color = '#656565';

    if (cnaes.length <= 0) {
      setMessageCNAE('Por favor, informe um CNAE principal.');
      document.querySelector('#cnaes').style.color = '#dc3545';
      document.querySelector('#cnaes').parentElement.click();
    } else {
      if (!cnaes.find((el) => el.principal)) {
        setMessageCNAE('Por favor, informe um CNAE principal.');
        document.querySelector('#cnaes').style.color = '#dc3545';
        document.querySelector('#cnaes').parentElement.click();
      } else {
        document.querySelector('#cnaes').style.color = '#656565';
      }
    }

    if (documents.length <= 0) {
      setMessageDocument('Por favor, informe um CNPJ válido.');
      document.querySelector('#documents').style.color = '#dc3545';
      document.querySelector('#documents').parentElement.click();
    } else {
      if (!documents.find((el) => el.idtype === 0)) {
        setMessageDocument('Por favor, informe um CNPJ válido.');
        document.querySelector('#documents').style.color = '#dc3545';
        document.querySelector('#documents').parentElement.click();
      } else {
        document.querySelector('#documents').style.color = '#656565';
      }
    }

    if (
      formData.company === '' ||
      formData.fantasy === '' ||
      formData.foundationdate === ''
    ) {
      document.querySelector('#company-data').style.color = '#dc3545';
      document.querySelector('#company-data').parentElement.click();
    } else document.querySelector('#company-data').style.color = '#656565';
  };

  const addCNAE = () => {
    if (cnae.value > 0 && cnae !== '') {
      setMessageCNAE('');

      if (!cnaes.find((el) => el.idtype === cnae.value)) {
        setCNAEs((cnaes) =>
          cnaes.concat({
            idtype: cnae.value,
            cnae: cnae.label,
            principal: cnaePrincipal,
            object: cnae,
          }),
        );

        setCNAE(-1);
        setCNAEPrincipal(false);
      } else setMessageCNAE('CNAE já incluído!');
    } else setMessageCNAE('Por favor, informe um CNAE!');
  };

  const removeCNAE = (type) => {
    const _remove = cnaes.filter((t, i) => {
      if (t.idtype !== type) return true;

      return false;
    });
    setCNAEs(_remove);
  };

  const loadOptionsNaturalNotice = (input, callback) => {
    setTimeout(() => {
      callback(
        dataLegalNature.filter(({ label }) =>
          removeAcento(label.toLowerCase()).includes(input.toLowerCase()),
        ),
      );
    }, 200);
  };

  const loadOptions = (input, callback) => {
    setTimeout(() => {
      callback(
        dataCNAE.filter(({ label }) =>
          removeAcento(label.toLowerCase()).includes(input.toLowerCase()),
        ),
      );
    }, 200);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getAddress = (value) => {
    let _cep = value.replace(/\D/g, '');
    if (_cep.length === 8) {
      CEP(_cep)
        .then((data) => {
          setStreet(data.street);
          setCEP(value);
          if (data.street === '') {
            document.querySelector('#street').removeAttribute('disabled');
          } else {
            document.querySelector('#street').setAttribute('disabled', true);
          }

          setNeighborhood(data.neighborhood);
          if (data.neighborhood === '') {
            document.querySelector('#neighborhood').removeAttribute('disabled');
          } else {
            document
              .querySelector('#neighborhood')
              .setAttribute('disabled', true);
          }
          setUF(data.state);
          setCity(data.city);
        })
        .catch();
    } else {
      setStreet('');
      setNeighborhood('');
      setUF('');
      setCity('');
      document.querySelector('#street').setAttribute('disabled', true);
      document.querySelector('#neighborhood').setAttribute('disabled', true);
    }
  };

  const addPhone = () => {
    setMessagePhone('');
    if (phoneType !== '' && phoneOperator !== '' && phone !== '') {
      if (!phones.find((el) => el.phone === phone)) {
        setPhones((phones) =>
          phones.concat({
            idphonetype: phoneType,
            phonetype: dataPhoneType.find((el) => el.value === phoneType)[
              'label'
            ],
            idphoneoperator: phoneOperator,
            phoneoperator: dataPhoneOperator.find(
              (el) => el.value === phoneOperator,
            )['label'],
            phone: phone,
            comments: phoneComments,
          }),
        );
        setPhoneComments('');
        setPhoneOperator('');
        setPhoneType('');
        setPhone('');
      } else setMessagePhone('Telefone informado já está cadastrado!');
    } else {
      if (phoneType === '') {
        setMessagePhone('Por favor, informe o tipo do telefone!');
        return;
      }
      if (phoneOperator === '') {
        setMessagePhone('Por favor, informe a operadora do telefone!');
        return;
      }
      if (phone === '') {
        setMessagePhone('Por favor, informe um número de telefone!');
        return;
      }
    }
  };

  const editPhone = (phone) => {
    let _data = phones.find((el) => el.phone === phone);

    setPhoneComments(_data.comments);
    setPhoneOperator(_data.idphoneoperator);
    setPhoneType(_data.idphonetype);
    setPhone(_data.phone);

    removePhone(phone);
  };

  const removePhone = (phone) => {
    const _remove = phones.filter((t, i) => {
      if (t.phone !== phone) return true;

      return false;
    });
    setPhones(_remove);
  };

  const editDocument = (type) => {
    let _data = documents.find((el) => el.idtype === type);
    setDocumentTypeValue(_data.value);
    setDocumentType(type);
    removeDocument(type);
  };

  const addDocument = () => {
    if (documentType >= 0 && documentTypeValue !== '') {
      let _data = '';
      setMessageDocument('');

      if (documentType === 0) {
        if (!cnpj.isValid(documentTypeValue)) {
          setMessageDocument('CNPJ informado é inválido!');
          return;
        }
      }

      if (!documents.find((el) => el.idtype === documentType)) {
        setDocuments((documents) =>
          documents.concat({
            idtype: parseInt(documentType),
            type: dataCompanyDocumentType.find(
              (el) => parseInt(el.value) === parseInt(documentType),
            ).label,
            value: documentTypeValue,
            outher: _data,
          }),
        );

        setDocumentType('');
        setDocumentTypeValue('');
      } else setMessageDocument('Documento já incluído!');
    } else setMessageDocument('Por favor, informe um tipo de documento!');
  };

  const removeDocument = (id) => {
    const _remove = documents.filter((t, i) => {
      if (t.idtype !== id) return true;

      return false;
    });
    setDocuments(_remove);
  };

  const addContact = () => {
    setMessageContact('');
    if (contactType !== '' && contactValue !== '') {
      if (
        !contacts.find(
          (el) => el.idtype === contactType && el.value === contactValue,
        )
      ) {
        setContacts((contacts) =>
          contacts.concat({
            idtype: contactType,
            type: dataContactType.find((el) => el.value === contactType)[
              'label'
            ],
            value: contactValue,
          }),
        );
        setContactType('');
        setContactValue('');
      } else setMessageContact('O contato informado já está cadastrado!');
    } else {
      if (contactType === '') {
        setMessageContact('Por favor, informe um tipo de contato.');
        return;
      }
      if (contactValue === '') {
        setMessageContact('Por favor, informe um contato.');
        return;
      }
    }
  };

  const removeContact = (id, value) => {
    const _remove = contacts.filter((t, i) => {
      if (t.idtype !== id && t.value !== value) return true;

      return false;
    });
    setContacts(_remove);
  };

  const editContact = (id, value) => {
    let _data = contacts.find((el) => el.idtype === id && el.value === value);
    setContactType(_data.idtype);
    setContactValue(_data.value);

    removeContact(id, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    loadingButton(
      true,
      document.querySelector('#submit-company'),
      document.querySelector('#submit-company').children[0].classList,
      document.querySelector('#submit-company').lastChild.nodeValue,
    );

    document.querySelector('#fiscal').style.color = '#656565';
    document.querySelector('#address-phones').style.color = '#656565';
    document.querySelector('#cnaes').style.color = '#656565';
    document.querySelector('#documents').style.color = '#656565';
    document.querySelector('#company-data').style.color = '#656565';

    let _errors = 0;

    if (phones.length <= 0) {
      setMessagePhone('Por favor, informe um número de telefone!');
      document.querySelector('#address-phones').style.color = '#dc3545';
      document.querySelector('#address-phones').parentElement.click();
      _errors = +1;
    } else document.querySelector('#address-phones').style.color = '#656565';

    if (cnaes.length <= 0) {
      setMessageCNAE('Por favor, informe um CNAE principal.');
      document.querySelector('#cnaes').style.color = '#dc3545';
      document.querySelector('#cnaes').parentElement.click();
      _errors = +1;
    } else {
      if (!cnaes.find((el) => el.principal)) {
        setMessageCNAE('Por favor, informe um CNAE principal.');
        document.querySelector('#cnaes').style.color = '#dc3545';
        document.querySelector('#cnaes').parentElement.click();
        _errors = +1;
      } else {
        document.querySelector('#cnaes').style.color = '#656565';
      }
    }

    if (documents.length <= 0) {
      setMessageDocument('Por favor, informe um CNPJ válido.');
      document.querySelector('#documents').style.color = '#dc3545';
      document.querySelector('#documents').parentElement.click();
      _errors = +1;
    } else {
      if (!documents.find((el) => el.idtype === 0)) {
        setMessageDocument('Por favor, informe um CNPJ válido.');
        document.querySelector('#documents').style.color = '#dc3545';
        document.querySelector('#documents').parentElement.click();
        _errors = +1;
      } else {
        document.querySelector('#documents').style.color = '#656565';
      }
    }

    if (_errors > 0) {
      loadingButton(false, document.querySelector('#submit-company'));
      return;
    }

    try {
      let _data = {
        company,
        foundationdate,
        fantasy,
        website,
        logo,
        comments,
        cep,
        street,
        number,
        complement,
        neighborhood,
        city,
        uf,
        legalnature,
        documents,
        phones,
        cnaes,
        taxRegime,
        contacts,
        contacts,
        bhSundayStart,
        bhSundayEnd,
        bhMondayStart,
        bhMondayEnd,
        bhTuesdayStart,
        bhTuesdayEnd,
        bhWednesdayStart,
        bhWednesdayEnd,
        bhThursdayStart,
        bhThursdayEnd,
        bhFridayStart,
        bhFridayEnd,
        bhSaturdayStart,
        bhSaturdayEnd,
      };

      if (editState)
        await api
          .put('/company/' + data.idcompany, _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(false, document.querySelector('#submit-company'));
            } else
              loadingButton(false, document.querySelector('#submit-company'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-company'));
            ShowMessages(error);
          });
      else
        await api
          .post('/company', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(false, document.querySelector('#submit-company'));
            } else
              loadingButton(false, document.querySelector('#submit-company'));
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-company'));
            ShowMessages(error);
          });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-company'));
      ShowMessages(response);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      await api.get('/getCompanyData').then((response) => {
        if (response.ok) {
          setDataCompanyDocumentType(response.data.companyDocumentType);
          setDataContactType(response.data.contactType);
          setDataPhoneType(response.data.phoneType);
          setDataPhoneOperator(response.data.phoneOperator);
          setDataCNAE(response.data.cnae);
          setDataTaxRegime(response.data.taxRegime);
          setDataLegalNature(response.data.legalNature);
        }
      });

      setLoading(false);
    } catch (response) {
      ShowMessages(response);
    }
  };

  const setData = (obj, clear) => {
    setTabActive(0);

    setMessageDocument('');
    setMessagePhone('');
    setMessageContact('');
    setMessageCNAE('');

    setPhoneType('');
    setPhoneOperator('');
    setPhone('');
    setPhoneComments('');

    setDocumentType('');
    setContactType('');
    setContactValue('');
    setDocumentTypeValue('');
    setCNAE('');
    setCNAEPrincipal(false);

    setLogo(clear ? null : obj.logobase64);
    setCompany(clear ? '' : obj.company);
    setFoundationdate(
      clear ? '' : obj.foundationdate ? obj.foundationdate.substr(0, 10) : '',
    );
    setFantasy(clear ? '' : obj.fantasy);
    setWebsite(clear ? '' : obj.website);
    setComments(clear ? '' : obj.comments);

    setCEP(clear ? '' : obj.addresses.cep);
    setStreet(clear ? '' : obj.addresses.street);
    setNumber(clear ? '' : obj.addresses.number);
    setComplement(clear ? '' : obj.addresses.complement);
    setNeighborhood(clear ? '' : obj.addresses.neighborhood);
    setCity(clear ? '' : obj.addresses.city);
    setUF(clear ? '' : obj.addresses.uf);

    setDocuments(clear ? [] : obj.documents || []);

    setPhones(clear ? [] : obj.phones || []);
    setCNAEs(clear ? [] : obj.cnaes || []);
    setContacts(clear ? [] : obj.contacts || []);

    setTaxRegime(clear ? -1 : obj.idtaxregime);
    setLegalNature(clear ? [] : obj.legalnature || []);

    setBhSundayStart(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Sunday.start,
    );
    setBhSundayEnd(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Sunday.end,
    );
    setBhMondayStart(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Monday.start,
    );
    setBhMondayEnd(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Monday.end,
    );
    setBhTuesdayStart(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Tuesday.start,
    );
    setBhTuesdayEnd(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Tuesday.end,
    );
    setBhWednesdayStart(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Wednesday.start,
    );
    setBhWednesdayEnd(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Wednesday.end,
    );
    setBhThursdayStart(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Thursday.start,
    );
    setBhThursdayEnd(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Thursday.end,
    );
    setBhFridayStart(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Friday.start,
    );
    setBhFridayEnd(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Friday.end,
    );
    setBhSaturdayStart(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Saturday.start,
    );
    setBhSaturdayEnd(
      clear
        ? ''
        : obj.businesshours === null || obj.businesshours === undefined
        ? ''
        : obj.businesshours.Saturday.end,
    );
  };

  useEffect(() => {
    if (editState) setData(data, false);
    else setData({}, true);
  }, [data, editState, showModal]);

  useEffect(() => {
    getData();
    setStatistic('modals\\modal-company');
  }, []);

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogClassName="height600"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
        onErrorSubmit={checkData}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-building"></i> Propriedades da Empresa
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} addClass="heigth390px" />
          <div className={`${loading ? 'hidden' : ''}`}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={tabActive === 0 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(0);
                  }}
                >
                  <span id="company-data">Dados da Empresa</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 1 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(1);
                  }}
                >
                  <span id="documents">Documentos</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 2 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(2);
                  }}
                >
                  <span id="cnaes">CNAE's</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 3 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(3);
                  }}
                >
                  <span id="address-phones">Endereços e Telefones</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 4 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(4);
                  }}
                >
                  <span id="fiscal">Fiscal</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 5 ? 'active' : ''}
                  onClick={() => {
                    setTabActive(5);
                  }}
                >
                  <span id="other-contacts">Outros Contatos</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabActive}>
              <TabPane tabId={0}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '175px' }}>
                    <div
                      alt="Click para incluir ou alterar uma logomarca"
                      style={{
                        cursor: 'pointer',
                        backgroundColor: '#F2F2F2',
                        width: '125px',
                        height: '125px',
                        border: '1px solid #ced4da',
                        borderRadius: '5px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={(e) => document.querySelector('#logo').click()}
                    >
                      <input
                        type="file"
                        id="logo"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={onImageChange}
                      />
                      <img
                        alt=""
                        src={logo === null ? SEM_IMAGEM : logo}
                        style={{ maxWidth: '122px', maxHeight: '122px' }}
                      />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ width: '125px' }}
                        onClick={(e) => setLogo(null)}
                      >
                        <i className="fa fa-trash" /> Remover
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div>
                      <label>Razão Social</label>
                      <div className="input-group">
                        <TextInput
                          name="company"
                          id="company"
                          type="text"
                          required
                          autoFocus={true}
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe a razão social!',
                          }}
                          value={company}
                          className="form-control "
                          onChange={(e) => setCompany(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div>
                      <label>Nome Fantasia</label>
                      <div className="input-group">
                        <TextInput
                          name="fantasy"
                          id="fantasy"
                          type="text"
                          required
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe um nome fantasia!',
                          }}
                          value={fantasy}
                          className="form-control "
                          onChange={(e) => setFantasy(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: 30 + '%' }}>
                        <label>Data de Fundação</label>
                        <div className="input-group">
                          <TextInput
                            name="foundationdate"
                            id="foundationdate"
                            type="date"
                            required
                            errorMessage={{
                              required:
                                'Por favor, informe uma data de fundação!',
                            }}
                            value={foundationdate}
                            className="form-control "
                            onChange={(e) => setFoundationdate(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div style={{ width: 70 + '%', marginLeft: '15px' }}>
                        <label>Site</label>
                        <div className="input-group">
                          <TextInput
                            name="website"
                            id="website"
                            type="text"
                            value={website}
                            maxLength={100}
                            autoComplete="off"
                            className="form-control "
                            onChange={(e) => setWebsite(e.target.value)}
                          ></TextInput>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: '5px' }}>
                  <div>
                    <label>Horário de Funcionamento</label>
                  </div>
                  <div className="default-carder" style={{ marginTop: '5px' }}>
                    <div className="box-settings-date">
                      <span>Segunda-Feira</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhMondayStart}
                        onChange={(e) => setBhMondayStart(e.target.value)}
                      />
                      <span>às</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhMondayEnd}
                        onChange={(e) => setBhMondayEnd(e.target.value)}
                      />
                    </div>
                    <div className="box-settings-date">
                      <span>Terça-Feira</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhTuesdayStart}
                        onChange={(e) => setBhTuesdayStart(e.target.value)}
                      />
                      <span>às</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhTuesdayEnd}
                        onChange={(e) => setBhTuesdayEnd(e.target.value)}
                      />
                    </div>
                    <div className="box-settings-date">
                      <span>Quarta-Feira</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhWednesdayStart}
                        onChange={(e) => setBhWednesdayStart(e.target.value)}
                      />
                      <span>às</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhWednesdayEnd}
                        onChange={(e) => setBhWednesdayEnd(e.target.value)}
                      />
                    </div>
                    <div className="box-settings-date">
                      <span>Quinta-Feira</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhThursdayStart}
                        onChange={(e) => setBhThursdayStart(e.target.value)}
                      />
                      <span>às</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhThursdayEnd}
                        onChange={(e) => setBhThursdayEnd(e.target.value)}
                      />
                    </div>
                    <div className="box-settings-date">
                      <span>Sexta-Feira</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhFridayStart}
                        onChange={(e) => setBhFridayStart(e.target.value)}
                      />
                      <span>às</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhFridayEnd}
                        onChange={(e) => setBhFridayEnd(e.target.value)}
                      />
                    </div>
                    <div className="box-settings-date">
                      <span>Sábado</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhSaturdayStart}
                        onChange={(e) => setBhSaturdayStart(e.target.value)}
                      />
                      <span>às</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhSaturdayEnd}
                        onChange={(e) => setBhSaturdayEnd(e.target.value)}
                      />
                    </div>
                    <div className="box-settings-date">
                      <span>Domingo</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhSundayStart}
                        onChange={(e) => setBhSundayStart(e.target.value)}
                      />
                      <span>às</span>
                      <input
                        type="time"
                        className="form-control"
                        value={bhSundayEnd}
                        onChange={(e) => setBhSundayEnd(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <label>Observações</label>
                <textarea
                  maxLength={2400}
                  className="form-control"
                  name="comments"
                  id="comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                ></textarea>
              </TabPane>
              <TabPane tabId={1}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '175px',
                      minWidth: '175px',
                      maxWidth: '175px',
                      marginBottom: '10px',
                    }}
                  >
                    <label>Tipo de Documento</label>
                    <div id="documentType">
                      <select
                        name="documenttype"
                        id="documenttype"
                        placeholder="Selecione"
                        value={documentType}
                        className="form-select"
                        onChange={(e) => setDocumentType(e.target.value)}
                      >
                        <option value="-1">Selecione</option>
                        {dataCompanyDocumentType.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div
                    style={{ marginLeft: '15px' }}
                    className={`${documentType < 0 ? 'hidden' : ''}`}
                  >
                    <label>Número</label>
                    <div className="input-group">
                      {documentType === '0' ? (
                        <MaskedInput
                          name="documenttypevalue"
                          id="documenttypevalue"
                          type="text"
                          maxLength={25}
                          value={documentTypeValue}
                          className="form-control"
                          onChange={(e) => setDocumentTypeValue(e.target.value)}
                          autoComplete="off"
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '/',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '-',
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                        />
                      ) : (
                        <input
                          name="documenttypevalue"
                          id="documenttypevalue"
                          type="text"
                          maxLength={25}
                          value={documentTypeValue}
                          className="form-control"
                          onChange={(e) => setDocumentTypeValue(e.target.value)}
                          autoComplete="off"
                        />
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className={`${documentType < 0 ? 'hidden' : ''}`}
                  >
                    <TooltipItem
                      content="Click aqui para inserir um novo documento"
                      className="background-info"
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        style={{ marginLeft: '15px', marginTop: '8px' }}
                        onClick={(e) => addDocument()}
                      >
                        <em className="fa fa-arrow-down"></em>
                      </button>
                    </TooltipItem>
                  </div>
                </div>

                <div style={{ overflow: 'auto', height: '187px' }}>
                  <Alert
                    variant="danger"
                    className={`${messageDocument === '' ? 'hidden' : ''}`}
                  >
                    {messageDocument}
                  </Alert>
                  <table className="table table-striped w-100">
                    <thead className="thead-theme">
                      <tr className="head-th">
                        <td
                          style={{
                            width: '225px',
                            minWidth: '175px',
                            maxWidth: '175px',
                            background: ' #f7f8f9',
                          }}
                          className="head-th"
                        >
                          Tipo
                        </td>
                        <td
                          style={{ width: 'auto', background: ' #f7f8f9' }}
                          className="head-th"
                        >
                          Documento
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            width: '100px',
                            background: ' #f7f8f9',
                          }}
                        >
                          ...
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.map((item, key) => {
                        return (
                          <tr
                            className="head-th"
                            key={key}
                            onDoubleClick={(e) => editDocument(item.idtype)}
                          >
                            <td>{item.type}</td>
                            <td>{item.value}</td>

                            <td style={{ textAlign: 'center' }}>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={(e) => removeDocument(item.idtype)}
                              >
                                <em className="fa fa-trash"></em>
                              </button>
                              <button
                                type="button"
                                className="btn btn-warning  btn-sm"
                                style={{ marginLeft: '5px' }}
                                onClick={(e) => editDocument(item.idtype)}
                              >
                                <em className="fa fa-edit"></em>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className={`box-sem-dados bg-warning ${
                      documents.length > 0 ? 'hidden' : ''
                    }`}
                  >
                    Ainda não foi incluido nenhum documento.
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '550px', marginBottom: '10px' }}>
                    <label>CNAE</label>
                    <AsyncSelect
                      options={dataCNAE}
                      ignoreCase
                      ignoreAccents
                      value={cnae}
                      loadOptions={loadOptions}
                      onChange={(value) => setCNAE(value === null ? 0 : value)}
                      placeholder="Digite para pesquisar..."
                      noOptionsMessage={() => 'Não encontrado'}
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      checked={cnaePrincipal}
                      onChange={(e) => setCNAEPrincipal(!cnaePrincipal)}
                      className="form-check-input"
                      style={{ marginLeft: '15px' }}
                    />
                    <span style={{ marginLeft: '32px', marginTop: '4px' }}>
                      CNAE principal?
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    className={`${
                      cnae.value <= 0 || cnae === '' ? 'hidden' : ''
                    }`}
                  >
                    <TooltipItem
                      content="Click aqui para inserir um novo CNAE"
                      className="background-info"
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        style={{ marginLeft: '15px', marginTop: '8px' }}
                        onClick={(e) => addCNAE()}
                      >
                        <em className="fa fa-arrow-down"></em>
                      </button>
                    </TooltipItem>
                  </div>
                </div>
                <div style={{ overflow: 'auto', height: '187px' }}>
                  <Alert
                    variant="danger"
                    className={`${messageCNAE === '' ? 'hidden' : ''}`}
                  >
                    {messageCNAE}
                  </Alert>
                  <table className="table table-striped w-100">
                    <thead className="thead-theme">
                      <tr className="head-th">
                        <td
                          style={{
                            width: '500px',
                            minWidth: '500px',
                            maxWidth: '500px',
                            background: '#f6f7f8',
                          }}
                          className="head-th"
                        >
                          CNAE
                        </td>
                        <td
                          style={{
                            width: '125px',
                            minWidth: '125px',
                            maxWidth: '125px',
                            background: '#f6f7f8',
                          }}
                          className="head-th"
                        >
                          Principal?
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            width: '100px',
                            background: '#f6f7f8',
                          }}
                        >
                          ...
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {cnaes.map((item, key) => {
                        return (
                          <tr className="head-th" key={key}>
                            <td>{item.cnae}</td>
                            <td style={{ textAlign: 'center' }}>
                              {item.principal ? 'Sim' : 'Não'}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={(e) => removeCNAE(item.idtype)}
                              >
                                <em className="fa fa-trash"></em>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className={`box-sem-dados bg-warning ${
                      cnaes.length > 0 ? 'hidden' : ''
                    }`}
                  >
                    Ainda não foi incluido nenhum CNAE.
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={3}>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 17 + '%' }}>
                      <label>CEP</label>
                      <div className="input-group">
                        <MaskWithValidation
                          name="cep"
                          id="cep"
                          type="text"
                          required
                          errorMessage={{
                            required: 'Por favor, informe um cep!',
                          }}
                          className="form-control "
                          value={cep}
                          onChange={(e) => getAddress(e.target.value)}
                          autoComplete="off"
                          mask={[
                            /[0-9]/,
                            /[0-9]/,
                            '.',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                            '-',
                            /[0-9]/,
                            /[0-9]/,
                            /[0-9]/,
                          ]}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '15px', width: 64 + '%' }}>
                      <label>Endereço</label>
                      <div className="input-group">
                        <TextInput
                          name="street"
                          id="street"
                          type="text"
                          required
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe uma rua!',
                          }}
                          value={street}
                          className="form-control "
                          onChange={(e) => setStreet(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: '15px', width: 19 + '%' }}>
                      <label>Número</label>
                      <div className="input-group">
                        <TextInput
                          name="number"
                          id="number"
                          type="text"
                          required
                          maxLength={25}
                          errorMessage={{
                            required: 'Por favor, informe um número!',
                          }}
                          value={number}
                          className="form-control "
                          onChange={(e) => setNumber(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 29 + '%' }}>
                      <label>Complemento</label>
                      <div className="input-group">
                        <TextInput
                          name="complement"
                          id="complement"
                          type="text"
                          value={complement}
                          maxLength={100}
                          style={{ height: '2.1875rem' }}
                          className="form-control "
                          onChange={(e) => setComplement(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 29 + '%', marginLeft: '15px' }}>
                      <label>Bairro</label>
                      <div className="input-group">
                        <TextInput
                          name="neighborhood"
                          id="neighborhood"
                          type="text"
                          required
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe um bairro!',
                          }}
                          value={neighborhood}
                          style={{ height: '2.1875rem' }}
                          className="form-control "
                          onChange={(e) => setNeighborhood(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 29 + '%', marginLeft: '15px' }}>
                      <label>Cidade</label>
                      <div className="input-group">
                        <TextInput
                          name="city"
                          id="city"
                          type="text"
                          required
                          disabled
                          maxLength={100}
                          errorMessage={{
                            required: 'Por favor, informe uma cidade!',
                          }}
                          value={city}
                          className="form-control "
                          onChange={(e) => setCity(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div style={{ width: 13 + '%', marginLeft: '15px' }}>
                      <label>UF</label>
                      <div className="input-group">
                        <TextInput
                          name="uf"
                          disabled
                          id="uf"
                          type="text"
                          required
                          maxLength={2}
                          errorMessage={{
                            required: 'Por favor, informe uma estado!',
                          }}
                          value={uf}
                          className="form-control "
                          onChange={(e) => setUF(e.target.value)}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="divider"></div>

                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: 50 + '%' }}>
                        <label>Tipo de Telefone</label>
                        <div className=" dv-typecontact">
                          <select
                            name="phonetype"
                            id="phonetype"
                            placeholder="Selecione"
                            value={phoneType}
                            className="form-select"
                            onChange={(e) =>
                              setPhoneType(parseInt(e.target.value))
                            }
                          >
                            <option value="">Selecione</option>
                            {dataPhoneType.map((data, id) => {
                              return (
                                <option key={id} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div style={{ width: 50 + '%', marginLeft: '15px' }}>
                        <label>Tipo de Operadora</label>
                        <div className="dv-typecontact">
                          <select
                            name="contacttype"
                            id="contacttype"
                            placeholder="Selecione"
                            value={phoneOperator}
                            className="form-select"
                            onChange={(e) =>
                              setPhoneOperator(parseInt(e.target.value))
                            }
                          >
                            <option value="">Selecione</option>
                            {dataPhoneOperator.map((data, id) => {
                              return (
                                <option key={id} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '120px' }}>
                        <label>Telefone</label>
                        <div className="input-group">
                          <MaskedInput
                            name="phone"
                            id="phone"
                            type="text"
                            value={phone}
                            className="form-control "
                            onChange={(e) => setPhone(e.target.value)}
                            mask={
                              ![1, 2, 99].includes(parseInt(phoneType))
                                ? [
                                    '(',
                                    /[0-9]/,
                                    /[0-9]/,
                                    ')',
                                    ' ',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    '-',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                  ]
                                : [
                                    '(',
                                    /[0-9]/,
                                    /[0-9]/,
                                    ')',
                                    ' ',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    '-',
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                    /[0-9]/,
                                  ]
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div style={{ width: '130px', marginLeft: '15px' }}>
                        <label>Observação</label>
                        <div className="input-group">
                          <input
                            name="phonecomments"
                            id="phonecomments"
                            type="text"
                            value={phoneComments}
                            maxLength={15}
                            className="form-control "
                            onChange={(e) => setPhoneComments(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <TooltipItem
                          content="Click aqui para inserir um novo telefone"
                          className="background-info"
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-success"
                            style={{ marginLeft: '15px', marginTop: '19px' }}
                            onClick={(e) => addPhone()}
                          >
                            <em className="fa fa-arrow-right"></em>
                          </button>
                        </TooltipItem>
                      </div>
                    </div>
                    <Alert
                      variant="danger"
                      style={{ marginTop: '5px', marginBottom: '0px' }}
                      className={`${messagePhone === '' ? 'hidden' : ''}`}
                    >
                      {messagePhone}
                    </Alert>
                  </div>
                  <div className="width75" style={{ marginLeft: '15px' }}>
                    <div style={{ overflow: 'auto', height: '150px' }}>
                      <table className="table table-striped w-100">
                        <thead className="thead-theme">
                          <tr className="head-th">
                            <td
                              className="head-th"
                              style={{
                                width: '85px',
                                minWidth: '85px',
                                maxWidth: '85px',
                                background: '#f6f7f8',
                              }}
                            >
                              Tipo
                            </td>
                            <td
                              className="head-th"
                              style={{
                                width: '70px',
                                minWidth: '70px',
                                maxWidth: '70px',
                                background: '#f6f7f8',
                              }}
                            >
                              Operadora
                            </td>
                            <td
                              className="head-th"
                              style={{
                                width: '80px',
                                minWidth: '80px',
                                maxWidth: '75px',
                                background: '#f6f7f8',
                              }}
                            >
                              Telefone
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                width: '60px',
                                background: '#f6f7f8',
                              }}
                            >
                              ...
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {phones.map((item, key) => {
                            return (
                              <tr
                                className="head-th"
                                key={key}
                                onDoubleClick={(e) => editPhone(item.phone)}
                              >
                                <td title={item.comments}>{item.phonetype}</td>
                                <td title={item.comments}>
                                  {item.phoneoperator}
                                </td>
                                <td title={item.comments}>{item.phone}</td>
                                <td style={{ textAlign: 'center' }}>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={(e) => removePhone(item.phone)}
                                  >
                                    <em className="fa fa-trash"></em>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-warning  btn-sm"
                                    onClick={(e) => editPhone(item.phone)}
                                    style={{ marginLeft: '5px' }}
                                  >
                                    <em className="fa fa-edit"></em>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div
                        className={`box-sem-dados bg-warning ${
                          phones.length > 0 ? 'hidden' : ''
                        }`}
                        style={{ padding: '15px' }}
                      >
                        Ainda não foi incluido nenhum telefone.
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={4}>
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 33 + '%' }}>
                      <label>Regime Tributário</label>
                      <div>
                        <SelectGroup
                          name="taxregime"
                          id="taxregime"
                          placeholder="Selecione"
                          required
                          errorMessage={{
                            required:
                              'Por favor, informe um regime tributário!',
                          }}
                          className="form-select"
                          value={taxRegime}
                          onChange={(e) =>
                            setTaxRegime(parseInt(e.target.value))
                          }
                        >
                          <option value="">Selecione</option>
                          {dataTaxRegime.map((data, id) => {
                            return (
                              <option key={id} value={data.value}>
                                {data.label}
                              </option>
                            );
                          })}
                        </SelectGroup>
                      </div>
                    </div>
                    <div style={{ width: '67%', marginLeft: '15px' }}>
                      <label>Natureza Jurídica</label>
                      <AsyncSelect
                        options={dataLegalNature}
                        ignoreCase
                        ignoreAccents
                        value={legalnature}
                        loadOptions={loadOptionsNaturalNotice}
                        onChange={(value) =>
                          setLegalNature(value === null ? 0 : value)
                        }
                        placeholder="Digite para pesquisar..."
                        noOptionsMessage={() => 'Não encontrado'}
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={5}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '175px',
                      minWidth: '175px',
                      maxWidth: '175px',
                      marginBottom: '10px',
                    }}
                  >
                    <label>Tipo de Contato</label>
                    <div className="dv-typecontact">
                      <select
                        name="contacttype"
                        id="contacttype"
                        placeholder="Selecione"
                        className="form-select"
                        value={contactType}
                        onChange={(e) =>
                          setContactType(parseInt(e.target.value))
                        }
                      >
                        <option value="">Selecione</option>
                        {dataContactType.map((data, id) => {
                          return (
                            <option key={id} value={data.value}>
                              {data.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div style={{ marginLeft: '15px', width: '275px' }}>
                    <label>Contato</label>
                    <div className="input-group">
                      <input
                        name="contact"
                        id="contact"
                        type="text"
                        value={contactValue}
                        maxLength={100}
                        className="form-control "
                        onChange={(e) => setContactValue(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <TooltipItem
                      content="Click aqui para inserir um novo contato"
                      className="background-info"
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        style={{ marginLeft: '15px', marginTop: '8px' }}
                        onClick={(e) => addContact()}
                      >
                        <em className="fa fa-arrow-down"></em>
                      </button>
                    </TooltipItem>
                  </div>
                </div>

                <div style={{ overflow: 'auto', height: '187px' }}>
                  <Alert
                    variant="danger"
                    className={`${messageContact === '' ? 'hidden' : ''}`}
                  >
                    {messageContact}
                  </Alert>
                  <table className="table table-striped w-100">
                    <thead className="thead-theme">
                      <tr className="head-th">
                        <td
                          className="head-th"
                          style={{
                            width: '175px',
                            minWidth: '175px',
                            maxWidth: '175px',
                            background: ' #f7f8f9',
                          }}
                        >
                          Tipo de Contato
                        </td>
                        <td
                          style={{
                            background: ' #f7f8f9',
                          }}
                        >
                          Contato
                        </td>
                        <td
                          style={{
                            textAlign: 'center',
                            width: '100px',
                            background: ' #f7f8f9',
                          }}
                        >
                          ...
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((item, key) => {
                        return (
                          <tr
                            className="head-th"
                            key={key}
                            onDoubleClick={(e) =>
                              editContact(item.idtype, item.value)
                            }
                          >
                            <td>{item.type}</td>
                            <td>{item.value}</td>

                            <td style={{ textAlign: 'center' }}>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={(e) =>
                                  removeContact(item.idtype, item.value)
                                }
                              >
                                <em className="fa fa-trash"></em>
                              </button>
                              <button
                                type="button"
                                className="btn btn-warning  btn-sm"
                                onClick={(e) =>
                                  editContact(item.idtype, item.value)
                                }
                                style={{ marginLeft: '5px' }}
                              >
                                <em className="fa fa-edit"></em>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div
                    className={`box-sem-dados bg-warning ${
                      contacts.length > 0 ? 'hidden' : ''
                    }`}
                  >
                    Ainda não foi incluido nenhum contato.
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-company"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
