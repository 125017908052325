import { configureStore } from '@reduxjs/toolkit';
import boardReducer from './slices/posBoardPlayerSlice.js';
import updateRequestReducer from './slices/updateRequest.js';
import userReducer from './slices/userAuth.js';

export const store = configureStore({
  reducer: {
    posBoard: boardReducer,
    userType: userReducer,
    updateRequest: updateRequestReducer
  },
})