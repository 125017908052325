import React, {useState, useEffect} from 'react'

import { ValidationForm, SelectGroup, TextInput } from 'react-bootstrap4-form-validation';
import { Button} from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import ShowMessages from '../../components/Exceptions'

import { removeAcento } from '../../common/utils';

import api from '../../services/api'
import setStatistic from '../../services/apiStatistic'
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ChangeUser({showModal, handleCloseModal, data, idUser, handleReload}) {
    const [loading, setLoading]                    = useState(false)
    const [dataCompany, setDataCompany]            = useState([])
    const [registration, setRegistration]          = useState()
    const [email, setEmail]                        = useState()
    const [idcompanydefault, setIDCompanyDefault]  = useState(null)

    const handleSubmit = async(e) => {
        e.preventDefault()
        loadingButton(true,document.querySelector('#change-usergroup'),document.querySelector('#change-usergroup').children[0].classList,document.querySelector('#change-usergroup').lastChild.nodeValue)
        try {
            await api.put('/user/' + idUser, { registration, idcompanydefault, email })
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                            if (response.data.status === 200) {
                                handleReload(true)
                                handleCloseModal()  
                            } else 
                                loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-users','Alterar') 
                         } else 
                            loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-users','Alterar') 
                     }).catch(response => {
                        loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-users','Alterar') 
                        ShowMessages(response)       
                    })
        } catch (response) {
            loadingButton(false,document.querySelector('#change-usergroup'),'fa fa-users','Alterar') 
            ShowMessages(response)          
        }
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/getcompany',{ iduser : idUser})
            .then(response => {
                if (response.ok) {
                    setDataCompany(response.data)  
                    setEmail(data[0].email || "")
                    setRegistration(data[0].registration || "")
                    setIDCompanyDefault(data[0].idcompanydefault | "")
                    setLoading(false)
                }
        }).catch(response => {
            ShowMessages(response)
        })
    } 

    useEffect(() => {
        if (showModal) {
            setStatistic('modals\\modal-changeUser')
            getData()  
        }  
    } ,[showModal, data])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog} dialogClassName="width300">
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user"></i> Alteração de Usuário
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth100px"/>
                    <div className={` ${(loading ? "hidden" : "")}`} style={{ display: "Flex", flexDirection:"column" }}>
                        <div>
                            <label>Empresa Padrão</label>
                            <div className="input-group">
                                <SelectGroup 
                                    name="usergroup" 
                                    id="usergroup" 
                                    placeholder="Selecione" 
                                    required
                                    errorMessage={{ required: "Por favor, informe um grupo de usuários!" }}
                                    className="form-select"
                                    value={idcompanydefault} 
                                    onChange={e => setIDCompanyDefault(parseInt(e.target.value))}>
                                        <option value="">Selecione</option>
                                        {dataCompany.map((data, id) => {
                                        return (<option key={id} value={data.value}>{data.label}</option>)
                                    })} 
                                </SelectGroup> 
                            </div>
                        </div>
                        <div style={{width:"100%"}}>
                            <label>Matrícula</label>
                            <TextInput 
                                name="registration" 
                                id="registration" 
                                required
                                errorMessage={{ required: "Por favor, informe uma matrícula!" }}
                                className="form-control "
                                value={registration} 
                                maxLength={25}
                                autoComplete="off"
                                onChange={e => setRegistration(removeAcento(e.target.value).toUpperCase())}/>
                        </div> 
                        <div style={{width:"100%"}}>
                            <label>E-mail</label>
                            <TextInput 
                                name="email" 
                                id="email" 
                                required={dataCompany.filter(_item => _item.value === idcompanydefault)[0]?.settings?.requiredEmail}
                                errorMessage={{ required: "Por favor, informe um e-mail!" }}
                                className="form-control "
                                value={email} 
                                maxLength={150}
                                autoComplete="off"
                                onChange={e => setEmail(e.target.value)}/>
                        </div> 
                </div>    
            </Modal.Body>
            <Modal.Footer>  
                <Button type="submit" id="change-usergroup" className="btn-theme">
                    <em className="fa fa-users"></em> Alterar
                </Button>          
                <Button className="btn-ligth-gray" onClick={e => handleCloseModal()}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </Modal.Footer>
            </ValidationForm>
        </Modal>
    )
}