import { memo } from "react";
import { useDrag, useDrop, useDragLayer } from "react-dnd";
import { useDispatch } from "react-redux";
import api from "../../services/api";

import { handleReloadBoardInfo } from "../../store/slices/updateRequest";
import ShowMessages from "../Exceptions";
import Person from './player';

const DragndropContainer = memo(function DragndropContainer(props)  {
    const {playerData, idGame} = props;

    const dispatch = useDispatch()
    const positionHasPlayer =  playerData.idUser !== -1;
  
    const [, drag] = useDrag({
        item: { 
            playerData: playerData,
            idGame: idGame,
        },
        type: positionHasPlayer ? "PLAYER" : "NOT-PLAYER",
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
            getType: monitor.getItemType(),
            getItem: monitor.getItem(),
        }),
      },[playerData]);
      
      const [, drop] = useDrop({
        accept: 'PLAYER',
        drop: (item) => {           
            handleUpdateUserPos(item);
        }
      },[playerData]);

      const handleUpdateUserPos = async (item) => {

        const { idUser, positionId } = item.playerData;

            if(positionId === props.positionBoard) {
                return
            }

            try {

                let _dataUser = {}

                if(props.playerData.idUser !== -1 && idUser !== props.playerData.idUser) {
                    _dataUser.actualPlayer = {
                        idgamesplayers: idUser,
                        boardPosition: props.positionBoard,
                    }

                    _dataUser.oldPlayer = {
                        idgamesplayers: props.playerData.idUser,
                        boardPosition: positionId,
                    }

                    _dataUser.idgame =  item.idGame
                }else {
                    _dataUser = {
                        actualPlayer: {
                            idgamesplayers: idUser,
                            boardPosition: props.positionBoard,
                        },
                        idgame: item.idGame   
                    }
                }
     
                await api.post('/updateBoardPosition', _dataUser)
                        .then(response => {
                            ShowMessages(response)
                            if (response.ok) {   
                                if (response.data.status === 200) {
                                     dispatch(handleReloadBoardInfo())
                                }
                            }

                })
                .catch( error => {
                    ShowMessages(error) 
                })
            } catch (response) {
                ShowMessages(response)
            }
      }

    return (
        <div className="d-flex" ref={drop} >
            <div ref={drag} className="m-1">
                <Person {...props} />
            </div>

        </div>
    )

})

export default DragndropContainer;