import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import Select from 'react-select';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
// import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import { useSelector } from 'react-redux';

import api from '../../services/api';
// import setStatistic from '../../services/apiStatistic'
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalChangeDealer({showModal, handleCloseModal, handlePositionPlayer, handleReload, idgame}) {
    // const [loading, setLoading]     = useState(false);
    // const [dealer]
    const [dealer, setDealer] = useState({
        id: -1,
        name: ''
    });
    const [dealers, setDealers] = useState([]);
    const posBoard = useSelector((state) => state.posBoard.posBoardPlayer);

    useEffect(() => {
        const  getDealers = async () => {
            await api.get('/getDealers')
            .then(response => {                

               if (response.ok) {   
                   if (response.status === 200) {
                        const arrDealers = []

                        response.data.forEach((item) => {
                            let obj = {
                                value: item.idpeople,
                                label: item.name,
                            }
        
                            arrDealers.push(obj)
                        })
                        setDealers(arrDealers);

                   } else 
                       loadingButton(false,document.querySelector('#submit-play-game'),'fa fa-play','Iniciar')  
                       ShowMessages(response)      
               } else
                   loadingButton(false,document.querySelector('#submit-play-game'),'fa fa-play','Iniciar')
                   ShowMessages(response)

           })
           .catch( error => {
               loadingButton(false,document.querySelector('#submit-play-game'),'fa fa-play','Iniciar')
               ShowMessages(error) 
           })
        }

        getDealers()
    },[])

    const handleSubmit = async(event) => {
        handleCloseModal();  
        event.preventDefault();

        loadingButton(true,document.querySelector('#submit-play-game'),document.querySelector('#submit-play-game').children[0].classList,document.querySelector('#submit-play-game').lastChild.nodeValue)

        try {
            let _data = {
                        idsender : dealer.id, 
                        idgame : idgame,
                    }
                                 
                    await api.post('/transferBoard', _data)
                             .then(response => {
                                 
                                ShowMessages(response)
                                if (response.ok) {   
                                    if (response.data.status === 200) {
                               
                                        handleReload(null);
                                        handleCloseModal();   
                                    } else 
                                        loadingButton(false,document.querySelector('#submit-play-game'),'fa fa-play','Iniciar')        
                                } else
                                    loadingButton(false,document.querySelector('#submit-play-game'),'fa fa-play','Iniciar')

                            })
                            .catch( error => {
                                loadingButton(false,document.querySelector('#submit-play-game'),'fa fa-play','Iniciar')
                                ShowMessages(error) 
                            })
                    } catch (response) {
                        loadingButton(false,document.querySelector('#submit-play-game'),'fa fa-play','Iniciar')
                        ShowMessages(response)
                    }
                    
             }


    return (
        <Modal 
            size='md' 
            show={showModal} 
            onHide={handleCloseModal} 
            animation={true} 
            backdrop="static" 
            dialogClassName="height350 width300" 
            dialogAs={DraggableModalDialog}
            >
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-project-diagram"></i> Iniciar Jogo
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {/* <Loading loading={loading}/> */}
                    <div>
                       <div>
                           <label>Digite o nome para pesquisar</label>
                           <Select
                                options= {dealers}
                                value={dealer.name}
                                onChange={e => setDealer({...dealer, id: e.value, name: e.label})}
                                defaultOptions
                               /> 
                       </div>
                       {/* {player?.value !== undefined &&
                        <div style={{border:"1px solid #cacaca", marginTop:"15px", padding:"5px", borderRadius:"5px"}}>
                           {/* <div style={{marginTop:"-15px", background:"#fff",width:"fit-content"}}>
                               <span style={{fontWeight:"bold"}}>Dados do Jogador</span>
                           </div>
                           <div>
                               <div>
                                   <span style={{fontWeight:"bold"}}>Cartão:</span> 123456789
                               </div>
                               <div>
                                   <span style={{fontWeight:"bold"}}>Nome:</span> {player.name}
                               </div>
                               <div>
                                   <span style={{fontWeight:"bold"}}>CPF:</span> 005.353.000-88
                               </div>
                           </div> 
                        </div>   
                        
                       } */}
                   </div>    
                </Modal.Body>
                <Modal.Footer>  
                    {/* <Button type="submit" id="submit-play-game" className={`btn-theme ${loading ? "hidden" : ""}`}> */}
                    <Button type="submit" id="submit-play-game" className={`btn-theme`}>
                        <em className="fa fa-play"></em> Iniciar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}