/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import FOTO from '../../assets/user.png';
import { Modal, ModalDialog } from 'react-bootstrap';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import { format, addHours } from 'date-fns';
import api from '../../services/api';
// import setStatistic from '../../services/apiStatistic'
import loadingButton from '../layouts/Buttons';
import User from '../../assets/user.png';
import Swal from 'sweetalert2';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

function ModalHistorieTable({
  idGame,
  showModal,
  handleCloseModal,
  handleReload,
}) {
  const userType = useSelector((state) => state.userType.userType);
  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const getTable = async () => {
      setLoading(true);

      api
        .post('/getBoardPlayers', { idgame: idGame })
        .then((response) => {
          if (response.ok) {
            setPlayers(response.data);
            setLoading(false);
          } else {
            ShowMessages(response);
          }
        })
        .catch((error) => {
          ShowMessages(error);
        });
    };

    getTable();
  }, [idGame, showModal]);

  const handleSubmit = async (event, idPlayer) => {
    event.preventDefault();
    Swal.fire({
      title: 'Informe a data e hora que o usuário finalizou a jogo!',
      icon: 'warning',
      html: '<input type="datetime-local" id="datetime" class="swal2-input">',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Encerrar',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        const datetime = document.getElementById('datetime').value;
        if (!datetime) {
          Swal.showValidationMessage('Por favor, informe a data e hora');
        }

        if (new Date(datetime) > new Date()) {
          Swal.showValidationMessage(
            'A data informada não pode ser uma data futura!',
          );
        }

        return datetime;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const datetime = result.value;
        let _data = {
          idgame: idGame,
          id: idPlayer,
          endgame: datetime,
        };

        await api
          .put('/setPlayer/' + idPlayer, _data)
          .then((response) => {
            ShowMessages(response);

            if (response.ok) {
              if (response.data.status === 200) {
                handleReload(null);
                handleCloseModal();
              }
            }
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">
          <i className="fa fa-history"></i> Histórico do Jogo
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Loading loading={loading} addClass="heigth200px" />
        <div
          className={`row ${loading ? 'hidden' : ''}`}
          style={{
            height: players.length === 0 && !loading ? '80px' : '300px',
            overflowY: 'auto',
            padding: '0px 15px',
          }}
        >
          {players.length === 0 && !loading ? (
            <div
              className="text-center w-100"
              style={{
                padding: '35px',
                borderRadius: '5px',
                height: '100%',
                fontSize: '18px',
                color: '#c69500',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#ffc107',
              }}
            >
              Não existe histórico a ser exibido
            </div>
          ) : (
            players.map((_player) => {
              let cpf = _player.player.cpf;
              cpf =
                cpf.substring(0, 3) +
                '.' +
                cpf.substring(3, 6) +
                '.' +
                cpf.substring(6, 9) +
                '-' +
                cpf.substring(9, 11);

              return (
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    maxHeight: '120px',
                    marginTop: '10px',
                  }}
                  className="box-shadow"
                  key={_player.idgamesplayers}
                >
                  <div
                    style={{
                      padding: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{ width: '50px', borderRadius: '50%' }}
                      src={_player.url || User}
                      alt="Foto"
                      onError={(e) => {
                        e.target.src = FOTO;
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: '5px',
                      width: '180px',
                      fontSize: '12px',
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Nome:</span>{' '}
                      {_player.player.player}
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>CPF:</span> {cpf}
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>NickName:</span>{' '}
                      {_player.player.nickname}
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Posição:</span>{' '}
                      {_player.boardposition}
                    </div>
                  </div>
                  <div style={{ marginLeft: '15px' }}>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Inicio:</span>{' '}
                      {format(
                        addHours(
                          new Date(_player.startgame),
                          process.env.REACT_APP_DIFERENCE_TIME,
                        ),
                        'dd/MM/yyyy HH:mm:ss',
                      )}
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>Fim:</span>{' '}
                      {_player.endgame === null
                        ? ''
                        : format(
                            addHours(
                              new Date(_player.endgame),
                              process.env.REACT_APP_DIFERENCE_TIME,
                            ),
                            'dd/MM/yyyy HH:mm:ss',
                          )}
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      {_player.endgame === null ? (
                        <div
                          className="bg-success"
                          style={{
                            borderRadius: '5px',
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {' '}
                          Jogando
                        </div>
                      ) : (
                        <div
                          className="bg-primary"
                          style={{
                            borderRadius: '5px',
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {' '}
                          Encerrado
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: '15px',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <div>
                      {_player.endgame === null &&
                      (userType === 'dealer' || userType === 'admin') ? (
                        <button
                          type="button"
                          onClick={(e) =>
                            handleSubmit(e, _player.idgamesplayers)
                          }
                          className="btn btn-sm btn-warning"
                        >
                          <i className="fa fa-person-walking-arrow-right"></i>
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex w-100" style={{ justifyContent: 'flex-end' }}>
          <div>
            <Button
              className="btn-ligth-gray"
              onClick={(e) => handleCloseModal()}
            >
              <em className="fa fa-door-closed"></em> Fechar
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(ModalHistorieTable);
