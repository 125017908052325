import React, { useRef, useState, useEffect } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';

const ModalScanner = (props) => {
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const videoRef = useRef(null);
  const codeReader = useRef(new BrowserMultiFormatReader()).current;

  useEffect(() => {
    const startScanner = async () => {
      try {
        const video = videoRef.current;

        // Verifica suporte para mediaDevices e getUserMedia
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          setError('Câmera não suportada');
          return;
        }

        // Solicita o stream de vídeo
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        });

        // Configura o vídeo com o stream
        video.srcObject = stream;
        video.setAttribute('playsinline', true); // Requerido para reproduzir vídeo inline no iOS

        // Espera o vídeo carregar e começar a reproduzir
        video.onloadedmetadata = () => {
          video
            .play()
            .then(() => {
              console.log('Vídeo reproduzindo');
            })
            .catch((playError) => {
              setError('Erro ao reproduzir o vídeo');
              console.error(playError);
            });
        };

        // Decodifica o vídeo
        codeReader.decodeFromVideoDevice(null, video, (result, error) => {
          if (result) {
            console.log('Código lido:', result.text);
            setResult(result.text);
            if (props?.onGetResult) props.onGetResult(result.text);
          }
          if (error && !(error instanceof NotFoundException)) {
            console.error('Erro na decodificação:', error);
            setError('Erro ao decodificar o código de barras');
          }
        });
      } catch (err) {
        console.error('Erro ao acessar a câmera:', err);
        setError('Erro ao acessar a câmera');
      }
    };

    startScanner();

    return () => {
      // Limpeza ao desmontar o componente
      codeReader.reset();
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [codeReader]);

  return (
    <div>
      <video
        ref={videoRef}
        width="300px"
        height="250px"
        style={{ border: '1px solid black', objectFit: 'cover' }}
      />
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default ModalScanner;
