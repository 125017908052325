import React, { useEffect, useState } from 'react';
import Filter from '../../components/Filter';
import Grid from '../../components/Grid';
import Loading from '../../components/Loading';
import api from '../../services/api'
import ContentWrapper from '../layouts/contentWrapper';
import ShowMessages from '../../components/Exceptions';
import ModalDonuzTournament from '../modals/modal-donuz-tournament';
import ModalURLBlock from '../modals/modal-URLBlock';
import { checkPermission } from '../../components/security';
import setStatistic from '../../services/apiStatistic';
import { format, startOfWeek  }        from 'date-fns'
import Swal from 'sweetalert2';

export default function DonuzTournament() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [showModalURLBlock, setShowModalURLBlock]        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [filters, setFilters]                            = useState([{ field      : 'datetournament',
                                                                         key        : 'between',  
                                                                         value      : format(startOfWeek(new Date()),'yyyy-MM-dd'),
                                                                         value2     : format(new Date(),'yyyy-MM-dd'),
                                                                         ignoretime : true    
                                                                       }])

    const [startDate, setStartDate]                        = useState(format(startOfWeek(new Date()),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(new Date(),'yyyy-MM-dd'))
    const [fieldSearch,setFieldSearch]                     = useState('datetournament')
    const [data, setData]                                  = useState([])
    const [dataStatusDonuz, setDataStatusDonuz]            = useState([])
    const [statusDonuz, setStatusDonuz]                    = useState(-1)
    const [tournament, setTournament]                      = useState('')
    
    const getData = async() => {
        await api.get('/donuz-status/0')
                 .then(response => {
                    setDataStatusDonuz(response.data)
                    setGridParams({
                        idpokerwebdata : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                       value : '00000'},
                                          primaryKey : true},
                        tournaments    : {text       : "Torneio"},
                        datetournament : {text       : "Data do Torneio"},
                        participants   : {text       : "Participantes"},
                        url            : {text       : "URL"},
                        statusdonuz    : {text       : "Status",
                                          item       : {className : "text-center",
                                                        switch    : {data  : response.data,
                                                                     key   : 'idstatusdonuz'}}},
                        errormessage   : {text       : "Motivo"},
                        Options      : {text        : "Opções",
                                        className   : "text-center",
                                        order       : false,
                                        filter      : false,
                                        item        : [{type  : "Custom",
                                                        props : {tag               : "button",
                                                                 title             : "Visualizar Jogadores",
                                                                 className         : 'btn btn-sm btn-success',
                                                                 icon              : "fa fa-search",
                                                                 indexcallback     : 0}},
                                                       {type  : "Custom",
                                                        props : {tag               : "button",
                                                                 title             : "Forçar Importação",
                                                                 visualConditional : {import : {operator : '===',
                                                                                                values : [false]}},
                                                                 className         : 'btn btn-sm btn-warning',
                                                                 icon              : "fa fa-file-import",
                                                                 indexcallback     : 1}},
                                                       {type  : "Custom",
                                                        props : {tag               : "button",
                                                                 title             : "Desbloquear URL",
                                                                 visualConditional : {idstatusdonuz : {operator : '===',
                                                                                                       values : 2}},
                                                                 className         : 'btn btn-sm btn-secondary',
                                                                 icon              : "fa fa-check",
                                                                 indexcallback     : 2}},
                                                       {type  : "Custom",
                                                        props : {tag               : "button",
                                                                 title             : "Bloquear URL",
                                                                 visualConditional : {idstatusdonuz : {operator : '===',
                                                                                                       values : 0}},
                                                                 className         : 'btn btn-sm btn-danger',
                                                                 icon              : "fa fa-ban",
                                                                 indexcallback     : 3}}]}
                    })              
                 })
                 .catch(_error => {
                    ShowMessages(_error)
                 })
    }

    useEffect(() => {
        getData()
        handleDateFilter()
        setStatistic('maintenance\\donuz-tournament')
    },[])
    
    const ShowPlayers = async(id) => {

        await api.get('/donuz-tournament/' + id)
                 .then(response => {
                    let _data = []
                    response.data.data.map(_array => {
                        _array.map(_player => {
                            if (_player[1] !== 'Mesa')
                                _data.push(_player)
                        })
                    })
                    console.log(response.data)
                    _data.unshift(response.data.data[0][0])
                    setData(_data)
                    setShowModal(true)
                 })
                 .catch( error => {
                    console.log(error)
                    ShowMessages(error)
                 })
    }

    const handleUnblock = (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente desbloquear a URL?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.put('/URL-Block/' + id)
                         .then((response) => {  
                            ShowMessages(response)
                            setReload()
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }
            
          })    
    }

    const handleBlock = (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente Bloquear a URL?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.put('/URL-Block/' + id)
                         .then((response) => {  
                            ShowMessages(response)
                            setReload()
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }
            
          })    
    }

    const forceImport = () => {

    }

    const handleURLBlock           = () => {
        setShowModalURLBlock(true)
    }

    const handleFilter             = () => {setIsOpenFilter(!isOpenFilter)}
    const handleCloseModal         = () => {setShowModal(false)}
    const handleCloseModalURLBlock = () => {setShowModalURLBlock(false)}
    const handleDateFilter         = () => {
        let _filters = [{ field      : fieldSearch,
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true    
                        }]

        if (statusDonuz !== -1)
            _filters.push({ field      : 'idstatusdonuz',
                            key        : '=',  
                            value      : statusDonuz,
                            value2     : null   
              })      

        if (tournament !== '')
            _filters.push({ field      : 'url',
                            key        : '%%',  
                            value      : tournament,
                            value2     : null   
              })   

        setFilters(_filters)
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalDonuzTournament showModal={showModal} handleCloseModal = {handleCloseModal}  data={data} handleReload={setReload}/>
            <ModalURLBlock showModal={showModalURLBlock} handleCloseModal = {handleCloseModalURLBlock}  data={data} handleReload={setReload}/>

            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div  className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            <button className="btn btn-warning" title="Bloquear URL" style={{marginLeft:"3px"}} onClick={e => handleURLBlock(true)}><i className="fa fa-ban"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,4)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                        <div style={{display:"flex", marginLeft:"25px", borderLeft:"1px dashed #cacaca"}}>
                            <div style={{marginLeft:"15px"}}>
                                <label>Data Inicial</label>
                                <input type="date" className="form-control" value={startDate} onChange={e => setStartDate(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px"}}>
                                <label>Data Final</label>
                                <input type="date" className="form-control" value={endDate} onChange={e => setEndDate(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px"}}>
                                <label>Status</label>
                                <select className='form-select' value={statusDonuz} onChange={e => setStatusDonuz(e.target.value)}>
                                    <option value={-1}>Todos</option>
                                    {dataStatusDonuz.map(_item => {
                                        return (<option value={_item.idstatusdonuz}>{_item.statusdonuz}</option>  )
                                    })}
                                </select>
                            </div>
                            <div style={{marginLeft:"15px", width:"90px"}}>
                                <label>Nº Torneio</label>
                                <input type="number" className="form-control" value={tournament} onChange={e => setTournament(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px", display:"flex", alignItems:"center", marginTop:"12px"}}>
                                <button type="button" className="btn btn-success" onClick={e => handleDateFilter(e)}><i class="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                        <div style={{display:"flex", marginLeft:"25px", borderLeft:"1px dashed #cacaca"}}>

                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/donuz-tournament' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      callbackButtons     = {[ShowPlayers,
                                             forceImport,
                                             handleUnblock,
                                             handleBlock]}
                      filename            = "Relatório de Torneios Importados"
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}