/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';


import { Modal, ModalDialog } from 'react-bootstrap';
import { SelectGroup, TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';

import api from '../../services/api';
// import setStatistic from '../../services/apiStatistic'
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

 function ModalConfigTable({
    idGame,
    gameName,
    idGameName,
    boardName,
    idTableSize,
    maxPlayers,
    showModal, 
    playersQuantity,
    handleCloseModal, 
    handleNewId
    }) {

    const dispatch = useDispatch();
    const userType = useSelector(state => state.userType.userType)
    const [loading, setLoading]         = useState(false)
  
    const [gameType, setGameType]       = useState([]);
    const [tableSize, setTableSize]     = useState([]);

    const [dataBoard, setDataBoard] = useState({
        idgame: 0,
        idgametype: 0,
        idtablesize: 0,
    })


    useEffect(() => {
        const getTable = async() => {
            setLoading(true);
            await api.get('/getDataTable')
                .then(response => {
                    if (response.ok) {
                        const arrGameType = [];
                        const arrPlayers = [];
    
                        response.data.tableSize.map((item, index) => {
                            
                            let objToSelect = {value: item.idtablesize, label: item.tablesize } 
    
                            return arrPlayers.push(objToSelect)
                        })
    
                        response.data.gameType.map((item, index) => {
                            let objToSelect = {value: item.idgametype, label: item.gametype} 
                            return arrGameType.push(objToSelect)
                        })
    
                        setTableSize(arrPlayers);
                        setGameType(arrGameType);
                        setLoading(false)
                    }else{
                        ShowMessages(response)
                    }
                       
                }
                )
                .catch( error => {
                    loadingButton(false,document.querySelector('#submit-play-game'))
                    ShowMessages(error) 
                }).finally(
                    // setLoading(false)
    
                )
        }

        getTable();
        // getTournaments();
    },[])

    useEffect(() => {
        const setDataBoardInfo = () => {
            setDataBoard({
                idgame: idGame,
                idgametype: idGameName,
                idtablesize: idTableSize
            })
        }
  
        setDataBoardInfo();
    },[idGameName, idTableSize, idGame])
    
    const handleSubmit = async(event) => {
        event.preventDefault()

        if (parseInt(dataBoard.idgametype) === 0) {
            ShowMessages({status : 500, message : "Informe um tipo de jogo"})
            return
        }

        if (parseInt(dataBoard.idtablesize) === 0) {
            ShowMessages({status : 500, message : "Informe um tipo de table size"})
            return
        }

        loadingButton(true,
            document.querySelector('#submit-play-game'),
            document.querySelector('#submit-play-game').children[0].classList,
            document.querySelector('#submit-play-game').lastChild.nodeValue)

        try {
            let _data = dataBoard;

            if ((userType === 'dealer') || (userType === 'admin')) {
                await api.post('/updateGameSettings', _data).then(response => {
                    ShowMessages(response)
                    if (response.ok) {   
                        if (response.data.status === 200) {
                            handleCloseModal();
                            dispatch(handleNewId(response.data.idgame || null))  
                        } else 
                            loadingButton(false,document.querySelector('#submit-play-game'))        
                    } else
                        loadingButton(false,document.querySelector('#submit-play-game'))
                })
                .catch( error => {
                    loadingButton(false,document.querySelector('#submit-play-game'))
                    ShowMessages(error) 
                })
            }  
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-play-game'))
            ShowMessages(response)
        }
    }

    return (
        <Modal size='sm' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0" >
                        <i className="fa fa-project-diagram"></i> Configurações da Mesa
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth200px"/>
                    <div className={` row ${loading ? "hidden" : ""}`}>  

                    <div className='d-flex'>
                        <div className='border rounded p-3 mb-3 flex-item-center' style={{width:"100%"}}>
                            <p className="mb-0 h6"><i className='fa fa-heart'></i> <span className="fw-bold mb-0" style={{marginLeft:"5px"}}>Jogo:</span> <span style={{fontWeight:"normal"}}>{gameName}</span></p>
                            <p className="mb-0 h6"><i className='fa fa-hand'></i> <span className="fw-bold mb-0" style={{marginLeft:"5px"}}>Mesa:</span> <span style={{fontWeight:"normal"}}>{boardName}</span></p>
                            <p className="mb-0 h6"><i className='fa fa-people-line'></i> <span className="fw-bold mb-0">Jogadores:</span> <span style={{fontWeight:"normal"}}>{maxPlayers}</span></p>
                        </div>
                    </div>
                        
                        <div style={{borderTop:"1px dashed #cacaca"}}>
                            <div style={{marginTop:"-12px"}}>
                                <p className=' m-0' style={{background:"#fff", width:"fit-content", fontWeight:"bold"}}>Alterar dados da mesa</p>
                            </div>
                            {gameType.length !== 0 && 
                                <div>
                                    <label>Tipo de Jogo</label>
                                    <div >
                                        <SelectGroup 
                                            name="gameType" 
                                            id="gameType" 
                                            placeholder="Selecione" 
                                            errorMessage={{ required: "Infome o tipo do jogo!" }}
                                            value={dataBoard.idgametype}
                                            className="form-select" 
                                            onChange={e => {
                                                    setDataBoard({...dataBoard, 
                                                        idgametype: Number(e.target.value),
                                                    });
                                                    }}>
                                            <option  value={0}>Selecione</option>
                                            {gameType.map((item, id) => {
                                                return (<option key={id} value={item.value} >{item.label}</option>)
                                                            })} 
                                        </SelectGroup>   
                                    </div>
                                </div>
                            }
                        <div>
                            <label>Table Size</label>
                            <div >
                                <SelectGroup 
                                    name="maxPlayers" 
                                    id="maxPlayers" 
                                    placeholder="Selecione" 
                                    errorMessage={{ required: "Infome a quantidade de jogadores!" }}
                                    value={dataBoard.idtablesize}
                                    className="form-select" 
                                    onChange={e => {
                                       
                                        setDataBoard({...dataBoard, 
                                            idtablesize: Number(e.target.value)})

                                    
                                    }}>
                                    <option  value={0}>Selecione</option>
                                    {tableSize?.map((item, id) => {
                                        return (<option key={id} value={item.value}>{item.label}</option>)
                                                    })} 
                                </SelectGroup>   
                            </div>
                        </div>    
                        </div>
                        {userType === "dealer" && 
                        <div className='col-md-12 authorization-form' >
                                <span>Autorização</span>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Usuário</label>
                                        <TextInput 
                                            name="nameUser" 
                                            id="nameUser" 
                                            required={"dealer"? true: false}
                                            errorMessage={{ required: "Infome o usuário!" }}
                                            className="form-control "
                                            value={dataBoard.registration} 
                                            maxLength={20}
                                            autoComplete="off"
                                            onChange={e => setDataBoard({...dataBoard, registration: e.target.value.toUpperCase()})}/>
                                    </div>                   
                                    <div className="col-md-6">
                                        <label>Senha</label>
                                        <TextInput 
                                            name="passwordUser" 
                                            id="passwordUser" 
                                            type="password"
                                            required={"dealer"? true: false}
                                            errorMessage={{ required: "Infome o jogo!" }}
                                            className="form-control "
                                            value={dataBoard.password} 
                                            maxLength={20}
                                            autoComplete="off"
                                            onChange={e => setDataBoard({...dataBoard, password: e.target.value})}/>
                                    </div>                   
                                </div>
                        </div>  
                        }                                      
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <div className='d-flex w-100' style={{justifyContent:"flex-end"}}>
                        <div >
                            <Button type="submit" id="submit-play-game" className={`btn-theme ${loading ? "hidden" : ""}`}>
                                <em className="fa fa-save"></em> Alterar
                            </Button>
                            <Button  className="btn-ligth-gray" onClick={handleCloseModal}>
                                <em className="fa fa-door-closed"></em> Fechar
                            </Button>
                        </div> 
                    </div>

                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}

export default memo(ModalConfigTable)