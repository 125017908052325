import React, { useState, useEffect } from 'react';
import ContentWrapper from '../layouts/contentWrapper';
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalBlind from '../modals/modal-blind';
import Grid from '../../components/Grid';
import api from '../../services/api';
import ShowMessages from '../../components/Exceptions';
import setStatistic from '../../services/apiStatistic';
import { checkPermission } from '../../components/security';
import Swal from 'sweetalert2';

export default function Blinds() {
  const [loading, setLoading] = useState(false);
  const [editState, setEditState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setdata] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [print, setPrint] = useState(false);
  const [excel, setExcel] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [filters, setFilters] = useState([]);

  const [editPermission, setEditPermisson] = useState(false);

  const getBlinds = async () => {
    let _delCheck = !checkPermission(4, 7);
    let _editCheck = !checkPermission(8, 7);

    setEditPermisson(_editCheck);
    setGridParams({
      idblind: {
        text: '#ID',
        mask: { name: 'leftSlice', value: '00000' },
        dataType    : "number",
        primaryKey: true,
      },
      blind: { text: 'Descrição',searchDefault : true },
      Options: {
        text: 'Opções',
        className: 'text-center',
        order: false,
        filter: false,
        item: [
          {
            type: 'Custom',
            props: {
              tag: 'button',
              title: 'Editar Blind',
              className: 'btn btn-sm btn-primary',
              icon: 'fa fa-edit',
              disabled: _editCheck,
              indexcallback: 0,
            },
          },
          {
            type: 'Custom',
            props: {
              tag: 'button',
              title: 'Exclusão de Blind',
              className: 'btn btn-sm btn-danger',
              icon: 'fa fa-trash',
              disabled: _delCheck,
              indexcallback: 1,
            },
          },
        ],
      },
    });
  };

  useEffect(() => {
    getBlinds();
    setStatistic('maintenance\\blind');
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const RemoveBlind = async (id) => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente excluir o blind?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api
          .delete('/blinds/' + id)
          .then((response) => {
            ShowMessages(response.data);
            setReload(true);
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };

  const EditBlind = async (id) => {
    await api
      .get('/blinds/' + id)
      .then((response) => {
        setdata(response.data);
        setEditState(true);
        setShowModal(true);
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  return (
    <ContentWrapper>
      <Loading loading={loading} />
      <ModalBlind
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        editState={editState}
        data={data}
        handleReload={setReload}
      />

      <div className={`container-lg container-100 ${loading ? 'hidden' : ''}`}>
        <div className="content-heading">
          <div className="box-maintance-buttons">
            <div>
              <button
                className="btn btn-success"
                disabled={!checkPermission(2, 7)}
                title="Insere um novo registro"
                onClick={(e) => {
                  setEditState(false);
                  setShowModal(true);
                }}
              >
                <i className="fa fa-plus"></i>
              </button>
              <button
                className="btn btn-primary"
                title="Atualizar Dados"
                style={{ marginLeft: '3px' }}
                onClick={(e) => setReload(true)}
              >
                <i className="fa fa-redo"></i>
              </button>
              {showButtons ? (
                <>
                  <button
                    className="btn btn-dark"
                    title="Impressão"
                    style={{ marginLeft: '3px' }}
                    onClick={(e) => setPrint(true)}
                  >
                    <i className="fa fa-print"></i>
                  </button>
                  <button
                    className="btn btn-info"
                    disabled={!checkPermission(128, 7)}
                    title="Exportar para excel"
                    style={{ marginLeft: '3px' }}
                    onClick={(e) => setExcel(true)}
                  >
                    <i className="fa fa-file-excel"> </i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    title="Filtros Avançados"
                    style={{ marginLeft: '3px' }}
                    onClick={(e) => handleFilter()}
                  >
                    <i className="fa fa-search-plus"></i>
                  </button>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`content-box-cart ${loading ? 'hidden' : ''}`}>
        <Filter
          isOpenFilter={isOpenFilter}
          filters={gridParams}
          callback={setFilters}
          callBackOpenFilter={handleFilter}
        />
        <Grid
          url="/blinds"
          headers={gridParams}
          loading={setLoading}
          reload={reload}
          print={print}
          excel={excel}
          filters={filters}
          filename="Blinds"
          callbackShowButtons={setShowButtons}
          callbackExcel={setExcel}
          callbackPrint={setPrint}
          callbackReload={setReload}
          callbackCellClick={editPermission ? undefined : EditBlind}
          callbackButtons={[EditBlind, RemoveBlind]}
        />
      </div>
    </ContentWrapper>
  );
}
