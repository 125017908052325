import React, { createContext, useState, useEffect } from 'react';
import api from '../services/api';

export const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchLogo = async () => {
    try {
      setLoading(true);
      const hostname = window.location.host;
      const response = await api.get('/getLogoFromURL');

      if (Array.isArray(response.data) && response.data.length > 0) {
        const currentCompany = response.data.find(
          (company) => company.settings.url === hostname,
        );

        if (currentCompany) {
          setLogo(currentCompany.logobase64);
        } else {
          console.log('No matching company found for the current URL');
          setLogo(null);
        }
      } else {
        console.error('Data is not an array or is empty.');
        setLogo(null);
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
      setLogo(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogo();
  }, []);

  return (
    <ImageContext.Provider value={{ logo, loading, refetchLogo: fetchLogo }}>
      {children}
    </ImageContext.Provider>
  );
};
