
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModalAddPlayer: false,
    showModalDelPlayer: false,
    showModalDealer: false,
    posBoardPlayer: -1,
    idPlayer: -1,

}

export const boardSlice = createSlice({
  name: 'posBoard',
  initialState,
  reducers: {
    showPlayerModal: (state) => {
      state.showModal = true;
    },
    closePlayerModal: (state) => {
      state.showModalAddPlayer = false;
    },
    showDealerModal: (state) => {
      state.showModalDealer = true;
    },
    closeDealerModal: (state) => {
      state.showModalDealer = false;
    },
    handleSelectPosBoardPlayer: (state, action) => {

        state.showModalAddPlayer = true;
        state.posBoardPlayer = action.payload.positionId;
    },
    closeDelPlayerModal: (state) => {
      state.showModalDelPlayer = false;
    },
    handleSelectPosBoardDeletePlayer: (state, action) => {
        state.showModalDelPlayer = true;
        state.posBoardPlayer = action.payload.id;
        state.idPlayer = action.payload.idUser;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
                showPlayerModal, 
                closePlayerModal, 
                showDealerModal,
                closeDealerModal,
                handleSelectPosBoardPlayer, 
                closeDelPlayerModal, 
                handleSelectPosBoardDeletePlayer,
              } = boardSlice.actions

export default boardSlice.reducer