import React from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
// import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import { useSelector } from 'react-redux';
import api from '../../services/api';
// import setStatistic from '../../services/apiStatistic'
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalDelPlayer({showModal, handleCloseModal, handlePositionPlayer, handleReload, idGame}) {

    const posBoard = useSelector((state) => state.posBoard.posBoardPlayer);
    const idPlayer = useSelector((state) => state.posBoard.idPlayer);

    const handleSubmit = async(event) => {
        handleCloseModal();  
        event.preventDefault();
        handlePositionPlayer(posBoard,'del');
        loadingButton(true,document.querySelector('#submit-del-player'),document.querySelector('#submit-del-player').children[0].classList,document.querySelector('#submit-del-player').lastChild.nodeValue)

        try {
            let _data = {
                idgame: idGame,
                id: idPlayer,
             }
                await api.put('/setPlayer/' + idPlayer, _data)
                      .then(response => {
                          ShowMessages(response)
 
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleReload(null)    
                                 handleCloseModal()  
                             } else 
                                 loadingButton(false,document.querySelector('#submit-del-player'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-del-player'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-del-player'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-del-player'))
            ShowMessages(response)
        }
    }

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-project-diagram"></i> Encerrar Jogo
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {/* <Loading loading={loading}/> */}
                    <div>
                        Deseja Encerrar Jogo deste Jogador?
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    {/* <Button type="submit" id="submit-del-player" className={`btn-theme ${loading ? "hidden" : ""}`}> */}
                    <Button type="submit" id="submit-del-player" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Encerrar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}