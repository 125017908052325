import React, { useEffect, useState } from 'react';

import { Col, Container, Modal, ModalDialog, Row } from 'react-bootstrap';
import {
  SelectGroup,
  ValidationForm,
  TextInput,
} from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';

import ShowMessages from '../../components/Exceptions';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';
import CurrencyInput from './../../components/moneyInput';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function CompanySettigns({
  showModal,
  handleCloseModal,
  data,
  handleReload,
}) {
  const [factortournament, setFactorTournament] = useState(0);
  const [tableSize, setTableSize] = useState([]);
  const [idTableSize, setIdTableSize] = useState('');
  const [userPokerWeb, setUserPokerWeb] = useState('');
  const [passwordPokerWeb, setPasswordPokerWeb] = useState('');
  const [requiredMother, setRequiredMother] = useState(false);
  const [requiredBirthDate, setRequiredBirthDate] = useState(false);
  const [requiredCPF, setRequiredCPF] = useState(false);
  const [requiredAddress, setRequiredAddress] = useState(false);
  const [requiredPhone, setRequiredPhone] = useState(false);
  const [requiredEmail, setRequiredEmail] = useState(false);
  const [requiredContractDate, setRequiredContractDate] = useState(false);
  const [cashbackFormula, setCashbackFormula] = useState('');
  const [url, setUrl] = useState('');

  const getTable = async () => {
    await api
      .get('/getDataTable')
      .then((response) => {
        if (response.ok) {
          const arrTabeSize = [];

          response.data.tableSize.map((item, index) => {
            let objToSelect = {
              value: item.idtablesize,
              label: item.tablesize,
            };

            return arrTabeSize.push(objToSelect);
          });
          setTableSize(arrTabeSize);
        } else {
          ShowMessages(response);
        }
      })
      .catch((error) => {
        loadingButton(false, document.querySelector('#submit-play-game'));
        ShowMessages(error);
      })
      .finally
      // setLoading(false)
      ();
  };
  React.useEffect(() => {
    getTable();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    loadingButton(
      true,
      document.querySelector('#companySettings'),
      document.querySelector('#companySettings').children[0].classList,
      document.querySelector('#companySettings').lastChild.nodeValue,
    );
    try {
      await api
        .post('/company/settings', {
          factortournament,
          tablesize: idTableSize,
          idcompany: data.idcompany,
          passwordPokerWeb,
          requiredMother,
          requiredBirthDate,
          requiredCPF,
          requiredAddress,
          requiredPhone,
          requiredEmail,
          userPokerWeb,
          cashbackFormula,
          url,
        })
        .then((response) => {
          ShowMessages(response);
          if (response.ok) {
            if (response.data.status === 200) {
              handleCloseModal();
              handleReload();
            } else
              loadingButton(false, document.querySelector('#companySettings'));
          } else
            loadingButton(false, document.querySelector('#companySettings'));
        })
        .catch((response) => {
          loadingButton(false, document.querySelector('#companySettings'));
          ShowMessages(response);
        });
    } catch (response) {
      loadingButton(false, document.querySelector('#companySettings'));
      ShowMessages(response);
    }
  };

  const setData = () => {
    setFactorTournament(
      data.settings === null ? 0 : data.settings.factortournament || 0,
    );
    setIdTableSize(data.settings === null ? 0 : data.settings?.tablesize);
    setUserPokerWeb(
      data.settings === null ? '' : data.settings.userPokerWeb || '',
    );
    setPasswordPokerWeb(
      data.settings === null ? '' : data.settings.passwordPokerWeb || '',
    );
    setRequiredMother(
      data.settings === null ? false : data.settings.requiredMother,
    );
    setRequiredBirthDate(
      data.settings === null ? false : data.settings.requiredBirthDate,
    );
    setRequiredCPF(data.settings === null ? false : data.settings.requiredCPF);
    setRequiredAddress(
      data.settings === null ? false : data.settings.requiredAddress,
    );
    setRequiredPhone(
      data.settings === null ? false : data.settings.requiredPhone,
    );
    setRequiredEmail(
      data.settings === null ? false : data.settings.requiredEmail,
    );
    setRequiredContractDate(
      data.settings === null ? false : data.settings.requiredContractDate,
    );
    setCashbackFormula(data.settings?.cashbackFormula || '');
    setUrl(data.settings?.url || '');
  };

  useEffect(() => {
    if (showModal) {
      setData();
      setStatistic('modals\\modal-company-settings');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-cogs"></i> Configurações da Empresa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={6} lg={6}>
                <label>Fator (Torneio)</label>
                <CurrencyInput
                  name="factortournament"
                  id="factortournament"
                  type="text"
                  required
                  errorMessage={{
                    required: 'Por favor, informe um fator de pontuação',
                  }}
                  value={factortournament}
                  className="form-control text-right"
                  onChange={setFactorTournament}
                  autoComplete="off"
                />
              </Col>
              <Col md={6} lg={6}>
                <label>Table Size</label>
                <div>
                  <SelectGroup
                    name="tableSize"
                    id="tableSize"
                    placeholder="Selecione"
                    value={idTableSize}
                    className="form-select"
                    onChange={(e) => setIdTableSize(Number(e.target.value))}
                  >
                    <option value="">Selecione</option>
                    {tableSize?.map((item, id) => {
                      return (
                        <option key={id} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </SelectGroup>
                </div>
              </Col>

              <Col md={6} lg={6}>
                <label>Usuário PokerWeb</label>
                <TextInput
                  name="userPokerWeb"
                  id="userPokerWeb"
                  type="text"
                  required
                  errorMessage={{
                    required: 'Por favor, informe o usuário do pokerweb',
                  }}
                  value={userPokerWeb}
                  className="form-control"
                  onChange={(e) => setUserPokerWeb(e.target.value)}
                  autoComplete="off"
                />
              </Col>
              <Col md={6} lg={6}>
                <label>Senha PokerWeb</label>
                <div>
                  <TextInput
                    type="password"
                    name="passwordPokerWeb"
                    id="passwordPokerWeb"
                    placeholder="Selecione"
                    required
                    errorMessage={{
                      required: 'Por favor, informe a senha do pokerweb',
                    }}
                    value={passwordPokerWeb}
                    className="form-control"
                    onChange={(e) => setPasswordPokerWeb(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6} lg={6}>
                <label>Fórmula de Cálculo de Cashback</label>
                <TextInput
                  name="cashbackFormula"
                  id="cashbackFormula"
                  type="text"
                  value={cashbackFormula}
                  onChange={(e) => setCashbackFormula(e.target.value)}
                  className="form-control"
                />
              </Col>
              <Col md={6} lg={6}>
                <label>Url</label>
                <TextInput
                  name="url"
                  id="url"
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  className="form-control"
                />
              </Col>
              <div
                style={{
                  marginTop: '20px',
                  borderRadius: '5px',
                  padding: '10px',
                  border: '1px solid #cacaca',
                }}
              >
                <div style={{ marginTop: '-20px' }}>
                  <span style={{ background: '#fff', width: 'fit-content' }}>
                    Cadastro de Colaboradores
                  </span>
                </div>

                <div style={{ display: 'flex' }}>
                  <div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={requiredMother}
                          value={requiredMother}
                          onClick={(e) => setRequiredMother(!requiredMother)}
                        />
                        <span></span>
                      </label>
                      <span style={{ marginLeft: '10px' }}>
                        Obrigatório nome da mãe?
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={requiredBirthDate}
                          value={requiredBirthDate}
                          onClick={(e) =>
                            setRequiredBirthDate(!requiredBirthDate)
                          }
                        />
                        <span></span>
                      </label>
                      <span style={{ marginLeft: '10px' }}>
                        Obrigatório data de nascimento?
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={requiredCPF}
                          value={requiredCPF}
                          onClick={(e) => setRequiredCPF(!requiredCPF)}
                        />
                        <span></span>
                      </label>
                      <span style={{ marginLeft: '10px' }}>
                        Obrigatório CPF?
                      </span>
                    </div>
                  </div>
                  <div style={{ marginLeft: '25px' }}>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={requiredAddress}
                          value={requiredAddress}
                          onClick={(e) => setRequiredAddress(!requiredAddress)}
                        />
                        <span></span>
                      </label>
                      <span style={{ marginLeft: '10px' }}>
                        Obrigatório Endereço?
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={requiredPhone}
                          value={requiredPhone}
                          onClick={(e) => setRequiredPhone(!requiredPhone)}
                        />
                        <span></span>
                      </label>
                      <span style={{ marginLeft: '10px' }}>
                        Obrigatório Telefone?
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={requiredEmail}
                          value={requiredEmail}
                          onClick={(e) => setRequiredEmail(!requiredEmail)}
                        />
                        <span></span>
                      </label>
                      <span style={{ marginLeft: '10px' }}>
                        Obrigatório Email?
                      </span>
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={requiredContractDate}
                          value={requiredContractDate}
                          onClick={(e) =>
                            setRequiredContractDate(!requiredContractDate)
                          }
                        />
                        <span></span>
                      </label>
                      <span style={{ marginLeft: '10px' }}>
                        Obrigatório Data da Contratação?
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" id="companySettings" className="btn-theme">
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button
            className="btn-ligth-gray"
            onClick={(e) => handleCloseModal(false)}
          >
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
