import React, { useEffect, useState } from 'react';
import { Modal, ModalDialog } from 'react-bootstrap';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';
import { Show } from '../../components/show';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default function ModalTable({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [maxparticipants, setMaxParticipants] = useState('');
  const [preview, setPreview] = useState(null);
  const [boardImage, setBoardImage] = useState(null);
  const [alterImage, setAlterImage] = useState(false);

  const setData = (obj, clear) => {
    setDescription(clear ? '' : obj.board);
    setMaxParticipants(clear ? '' : (obj.maxparticipants || '').toString());
    setBoardImage(clear ? '' : obj.boardimage);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadingButton(
      true,
      document.querySelector('#submit-table'),
      document.querySelector('#submit-table').children[0].classList,
      document.querySelector('#submit-table').lastChild.nodeValue,
    );

    try {
      const _data = {
        board: description,
        maxparticipants: parseInt(maxparticipants, 10),
        boardimage: preview ? await toBase64(preview) : boardImage,
      };

      const apiCall = editState
        ? api.put(`/tables/${data.idboard}`, _data)
        : api.post('/tables', _data);

      apiCall
        .then((response) => {
          ShowMessages(response);
          if (response.ok && response.data.status === 200) {
            handleCloseModal();
            handleReload(true);
          } else {
            loadingButton(false, document.querySelector('#submit-table'));
          }
        })
        .catch((error) => {
          loadingButton(false, document.querySelector('#submit-table'));
          ShowMessages(error);
        });
    } catch (error) {
      console.error('Error converting image to base64', error);
      loadingButton(false, document.querySelector('#submit-table'));
    }
  };

  useEffect(() => {
    if (editState) {
      setLoading(true);
      setData(data, false);
      setLoading(false);
    } else {
      setData({}, true);
    }
  }, [data, editState, showModal]);

  useEffect(() => {
    setStatistic('modals\\tables');
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setPreview(acceptedFiles[0]);
      }
    },
  });

  const handleRemoveImage = (e) => {
    e.stopPropagation();
    setPreview(null);
  };

  const onClose = () => {
    setAlterImage(false);
    setPreview(null);
    handleCloseModal();
  };

  const imagePreviewUrl = preview ? URL.createObjectURL(preview) : null;

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={onClose}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-project-diagram"></i> Propriedades da Mesa
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} />
          {!loading && (
            <>
              <div>
                <label>Descrição</label>
                <TextInput
                  name="descriptionTable"
                  id="descriptionTable"
                  required
                  errorMessage={{ required: 'Informe o jogo!' }}
                  className="form-control"
                  value={description}
                  maxLength={50}
                  autoComplete="off"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div>
                <label>Número Máximo de Participantes</label>
                <TextInput
                  name="participantNumber"
                  id="participantNumber"
                  type="number"
                  min={2}
                  max={12}
                  required
                  errorMessage={{
                    required: 'Escolha o número de participantes',
                    min: 'Mínimo 2',
                    max: 'Máximo 12',
                  }}
                  className="form-control w-75"
                  value={maxparticipants}
                  autoComplete="off"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setMaxParticipants(value);
                    }
                  }}
                />
              </div>

              <Show when={alterImage || !boardImage}>
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    border: '2px dashed #007bff',
                    padding: '20px',
                    textAlign: 'center',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginTop: '20px',
                  }}
                >
                  <input {...getInputProps()} style={{ display: 'none' }} />
                  {imagePreviewUrl ? (
                    <>
                      <img
                        src={imagePreviewUrl}
                        alt="Preview"
                        style={{
                          maxWidth: '100%',
                          borderRadius: '4px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          marginBottom: '20px',
                        }}
                      />
                      <Button
                        color="danger"
                        onClick={handleRemoveImage}
                        style={{ marginTop: '10px' }}
                      >
                        <em className="fa fa-trash"></em> Excluir
                      </Button>
                    </>
                  ) : (
                    <p style={{ margin: '0', color: '#6c757d' }}>
                      Arraste e solte uma imagem aqui, ou clique para selecionar
                      uma imagem
                    </p>
                  )}
                </div>
              </Show>
              <Show when={boardImage && !alterImage}>
                <p>Imagem da Mesa</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={boardImage} alt="imagem" />
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => setAlterImage(true)}
                    color="success"
                    style={{ marginTop: '15px' }}
                  >
                    <i className="fa fa-image" /> Alterar Imagem
                  </Button>
                </div>
              </Show>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="submit"
            id="submit-table"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-light-gray" onClick={onClose}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
