/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
    ReadDataStorage,
    REGISTRATION,
    WriteDataStorage,
} from './../../services/auth';

import { Modal, ModalDialog } from 'react-bootstrap';
import {
    SelectGroup,
    TextInput,
    ValidationForm,
} from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import { handleReload } from '../../store/slices/updateRequest';

import api from '../../services/api';
// import setStatistic from '../../services/apiStatistic'
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

export default function ModalPlayGame({
    showModal,
    handleCloseModal,
    handleReloadBoard,
    handleFullScreenStartPage,
}) {
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.userType.userType);
    const [loading, setLoading] = useState(false);

    const [gameType, setGameType] = useState([]);
    const [tableSize, setTableSize] = useState([]);
    const [buyIn, setBuyIn] = useState([]);
    const [table, setTable] = useState([]);

    const [allBoards, setAllBoards] = useState([]);
    const [dataBoard, setDataBoard] = useState({
        idboard: '',
        idgametype: '',
        idtablesize: '',
        idbuyin: '',
        maxplayers: '',
        registration: '',
        password: '',
    });

    const resetData = () => {
        setDataBoard({
            idboard: '',
            idgametype: '',
            idtablesize: '',
            idbuyin: '',
            maxplayers: '',
            registration: '',
            password: '',
        });
    };

    const Logout = async (isConfirm, swal) => {
        Swal.fire({
            title: 'Deseja realmente sair do sistema?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api
                    .post('/logout')
                    .then(() => {
                        let _registration = ReadDataStorage(REGISTRATION);
                        localStorage.clear();
                        WriteDataStorage(REGISTRATION, _registration);
                        window.location.href = '/login';
                    })
                    .catch((error) => {
                        ShowMessages(error);
                    });
            }
        });
    };

    useEffect(() => {
        const getTable = async () => {
            setLoading(true);
            try {
                const response = await api.get('/getDataTable');

                if (response.ok) {
                    const arrGameType = response.data.gameType.map((item) => ({
                        value: item.idgametype,
                        label: item.gametype,
                    }));

                    const arrTableSize = response.data.tableSize.map((item) => ({
                        value: item.idtablesize,
                        label: item.tablesize,
                        maxPlayers: item.players,
                    }));

                    const arrBuyIn = response.data.buyIn.map((item) => ({
                        value: item.idbuyin,
                        label: item.buyin,
                    }));

                    const arrTable = response.data.boards.map((item) => ({
                        value: item.idboard,
                        label: item.board,
                        maxPlayers: item.maxparticipants
                    }));

                    setAllBoards(response.data.boards);
                    setTableSize(arrTableSize);
                    setGameType(arrGameType);
                    setBuyIn(arrBuyIn);
                    setTable(arrTable);
                    setDataBoard({
                        ...dataBoard,
                        maxplayers:
                            arrTableSize.find(
                                (item) => Number(response.data.defaultTableSize) === item.value,
                            )?.maxPlayers || 0,
                        idtablesize: Number(response.data.defaultTableSize),
                    });
                } else {
                    ShowMessages(response);
                }
            } catch (error) {
                const submitButton = document.querySelector('#submit-play-game');
                if (submitButton) {
                    const children = submitButton.children[0];
                    loadingButton(
                        false,
                        submitButton,
                        children ? children.classList : null,
                        submitButton.lastChild ? submitButton.lastChild.nodeValue : '',
                    );
                } else {
                    ShowMessages('Submit button not found.');
                }
                ShowMessages(error);
            } finally {
                setLoading(false);
            }
        };

        getTable();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        loadingButton(
            true,
            document.querySelector('#submit-play-game'),
            'fa fa-play',
            'Iniciar',
        );

        try {
            let _data = dataBoard;

            if (userType === 'dealer') {
                await api
                    .post('/board', _data)
                    .then((response) => {
                        ShowMessages(response);
                        if (response.ok) {
                            if (response.data.status === 200) {
                                resetData();
                                handleReloadBoard();
                                dispatch(handleReload());
                                handleCloseModal();
                                window.addTab(
                                    null,
                                    response.data.board.toUpperCase(),
                                    'fa fa-play',
                                    '/play-game',
                                    {
                                        idGame: response.data.idboard,
                                    },
                                    0,
                                    true
                                );
                            } else
                                loadingButton(
                                    false,
                                    document.querySelector('#submit-play-game'),
                                    'fa fa-play',
                                    'Iniciar',
                                );
                        } else
                            loadingButton(
                                false,
                                document.querySelector('#submit-play-game'),
                                'fa fa-play',
                                'Iniciar',
                            );
                    })
                    .catch((error) => {
                        loadingButton(
                            false,
                            document.querySelector('#submit-play-game'),
                            'fa fa-play',
                            'Iniciar',
                        );
                        ShowMessages(error);
                    });
            } else {
                await api
                    .post('/board', _data)
                    .then((response) => {
                        ShowMessages(response);
                        if (response.ok) {
                            if (response.data.status === 200) {
                                resetData();
                                dispatch(handleReload());
                                handleCloseModal();
                                handleFullScreenStartPage();
                                window.addTab(
                                    null,
                                    response.data.board.toUpperCase(),
                                    'fa fa-play',
                                    '/play-game',
                                    {
                                        idGame: response.data.idboard,
                                    },
                                    0,
                                    true
                                );
                            } else
                                loadingButton(
                                    false,
                                    document.querySelector('#submit-play-game'),
                                    'fa fa-play',
                                    'Iniciar',
                                );
                        } else
                            loadingButton(
                                false,
                                document.querySelector('#submit-play-game'),
                                'fa fa-play',
                                'Iniciar',
                            );
                    })
                    .catch((error) => {
                        loadingButton(
                            false,
                            document.querySelector('#submit-play-game'),
                            'fa fa-play',
                            'Iniciar',
                        );
                        ShowMessages(error);
                    });
            }
        } catch (response) {
            loadingButton(
                false,
                document.querySelector('#submit-play-game'),
                'fa fa-play',
                'Iniciar',
            );
            ShowMessages(response);
        }
    };

    const toggleCollapsed = () => {
        document.querySelector('body').classList.add('aside-collapsed');
    };

    useEffect(() => {
        toggleCollapsed();
    }, []);

    return (
        <Modal
            size="md"
            show={showModal}
            onHide={handleCloseModal}
            animation={true}
            backdrop="static"
            dialogAs={DraggableModalDialog}
        >
            <ValidationForm
                onSubmit={handleSubmit}
                setFocusOnError={true}
                className="modal-content"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0">
                        <i className="fa fa-project-diagram"></i> Iniciar Jogo
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Loading loading={loading} addClass="heigth200px" />
                    <div className={` row ${loading ? 'hidden' : ''}`}>
                        <div className="col-md-6">
                            <label>Mesa</label>

                            <div>
                                <SelectGroup
                                    name="board"
                                    id="board"
                                    placeholder="Selecione"
                                    required
                                    errorMessage={{ required: 'Infome a mesa!' }}
                                    value={dataBoard.idboard}
                                    className="form-select"
                                    onChange={(e) =>
                                        setDataBoard({
                                            ...dataBoard,
                                            idboard: Number(e.target.value),
                                            idtablesize : 0
                                        })
                                    }
                                >
                                    <option value="">Selecione</option>
                                    {table?.map((item, id) => {
                                        return (
                                            <option key={id} value={item.value}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </SelectGroup>
                            </div>
                        </div>

                        {gameType.length !== 0 && (
                            <div className="col-md-6">
                                <label>Tipo de Jogo</label>
                                <div>
                                    <SelectGroup
                                        name="gameType"
                                        id="gameType"
                                        placeholder="Selecione"
                                        errorMessage={{ required: 'Infome o tipo do jogo!' }}
                                        required
                                        value={dataBoard.idgametype}
                                        className="form-select"
                                        onChange={(e) => {
                                            setDataBoard({
                                                ...dataBoard,
                                                idgametype: Number(e.target.value),
                                            });
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        {gameType.map((item, id) => {
                                            return (
                                                <option key={id} value={item.value}>
                                                    {item.label}
                                                </option>
                                            );
                                        })}
                                    </SelectGroup>
                                </div>
                            </div>
                        )}
                      
                        {dataBoard.idboard > 0 && (
                            <div className="col-md-6">
                                <label>Table Size</label>
                                <div>
                                    <SelectGroup
                                        name="tableSize"
                                        id="tableSize"
                                        placeholder="Selecione"
                                        required
                                        errorMessage={{ required: 'Infome o table size!' }}
                                        value={dataBoard.idtablesize}
                                        className="form-select"
                                        onChange={(e) => {
                                            let getMaxPlayer = tableSize.filter((item) => {
                                                return Number(e.target.value) === item.value;
                                            });
                                            setDataBoard({
                                                ...dataBoard,
                                                idtablesize: Number(e.target.value),
                                                maxplayers: getMaxPlayer[0].maxPlayers,
                                            });
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        {tableSize?.map((item, id) => {
                                            const currentBoard = allBoards.find(
                                                (board) => board.idboard === dataBoard.idboard,
                                            );
                                            if (currentBoard?.maxparticipants >= item?.maxPlayers) {
                                                return (
                                                    <option key={id} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                );
                                            }
                                            return null;
                                        })}
                                    </SelectGroup>
                                    {(tableSize || []).filter(_item => _item.value <= table.filter(_item => _item.value === dataBoard.idboard)[0].value).length <= 0 && (
                                        <div> 
                                            <small className='text-danger'>Não existe table size para a mesa informada</small>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="col-md-6">
                            <label>Buy In</label>
                            <div>
                                <SelectGroup
                                    name="buyIn"
                                    id="buyIn"
                                    placeholder="Selecione"
                                    errorMessage={{ required: 'Infome o buy in!' }}
                                    required
                                    value={dataBoard.idbuyin}
                                    className="form-select"
                                    onChange={(e) =>
                                        setDataBoard({
                                            ...dataBoard,
                                            idbuyin: Number(e.target.value),
                                        })
                                    }
                                >
                                    <option value="">Selecione</option>
                                    {buyIn?.map((item, id) => {
                                        return (
                                            <option key={id} value={item.value}>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </SelectGroup>
                            </div>
                        </div>
                        {userType === 'dealer' && (
                            <div className="col-md-12 authorization-form">
                                <span>Autorização</span>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Usuário</label>
                                        <TextInput
                                            name="nameUser"
                                            id="nameUser"
                                            required={'dealer' ? true : false}
                                            errorMessage={{ required: 'Infome o usuário!' }}
                                            className="form-control "
                                            value={dataBoard.registration}
                                            maxLength={20}
                                            autoComplete="off"
                                            onChange={(e) =>
                                                setDataBoard({
                                                    ...dataBoard,
                                                    registration: e.target.value.toUpperCase(),
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Senha</label>
                                        <TextInput
                                            name="passwordUser"
                                            id="passwordUser"
                                            type="password"
                                            required={'dealer' ? true : false}
                                            errorMessage={{ required: 'Infome o jogo!' }}
                                            className="form-control "
                                            value={dataBoard.password}
                                            maxLength={20}
                                            autoComplete="off"
                                            onChange={(e) =>
                                                setDataBoard({ ...dataBoard, password: e.target.value })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: `${userType === 'dealer' ? 'space-between' : 'end'
                                }`,
                            width: '100%',
                        }}
                    >
                        {userType === 'dealer' && (
                            <div>
                                <Button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={(e) => Logout()}
                                >
                                    <em className="fa fa-door-closed"></em> Sair do Sistema
                                </Button>
                            </div>
                        )}
                        <div>
                            <Button
                                type="submit"
                                id="submit-play-game"
                                className={`btn-theme ${loading ? 'hidden' : ''}`}
                            >
                                <em className="fa fa-play"></em> Iniciar
                            </Button>
                            <Button
                                style={{ marginLeft: '5px' }}
                                className="btn-ligth-gray"
                                onClick={handleCloseModal}
                            >
                                <em className="fa fa-door-closed"></em> Fechar
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
    );
}
