import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import Error404 from './pages/errors/404';
import BasePage from "./pages/layouts/BasePage";
import Forgot from './pages/login/forgot';
import UpdatePassword from './pages/login/updatePassword';
import Login from './pages/login/login';

import { isAuthenticated } from './services/auth';

const PrivateRoute = ({  children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" />;
};

const BaseRoutes = () => {
    return (
        <TransitionGroup>
            <BrowserRouter>
                <Routes >
                    <Route exact path="/" element={<PrivateRoute> 
                                                       <BasePage/>
                                                   </PrivateRoute>} /> 
                    <Route exact path="/login" element={<Login/>} />
                    <Route exact path="/forgot/:hash" element={<UpdatePassword/>} />
                    <Route exact path="/forgot" element={<Forgot/>} />
                    <Route path="/*" element={<Error404/>} />
                </Routes>
            </BrowserRouter>
        </TransitionGroup>
    )
}

export default BaseRoutes;