import React, { useState, useEffect } from 'react';
import api from '../../services/api'
import ShowMessages from './../../components/Exceptions';

const BettingMirror = () => {
  
    const [mirrorData, setMirrorData] = useState([])
    const [benefitCategory, setBenefitCategory] = useState([])
    const [idbenefit, setIdBenefit] = useState(0)

    const handleData = async() => {
        await api.post('/getMirrorData',{benefit : idbenefit})
        .then(response => {
            console.log(response.data)
           setMirrorData(response.data || [])
        })
        .catch( error => {
           ShowMessages(error)
        })
    }

    useEffect(() => {
        if (idbenefit > 0)
            handleData()
    },[idbenefit])

    useEffect(() => {
        const getData = async() => {
            await api.get('/getBenefitCategory')
            .then(response => {
                setBenefitCategory(response.data || [])
            })
            .catch( error => {
               ShowMessages(error)
            }) 
        }
        getData()
    },[])

    return (
        <div style={{padding:"10px", display:"flex",gap:"5px", flexDirection:"column"}}>
            <div>
                <label>Tipo de Cartão</label>
                <select
                    value={idbenefit}
                    className='form-select'
                    onChange={e => setIdBenefit(e.target.value)}
                    style={{ width: '200px' }}>
                    <option value="0" selected>Selecione</option>
                    {benefitCategory.map(_item => {
                        return (<option value={_item.value}>{_item.label}</option>)
                    })}
                </select>
            </div>
            <div style={{width:"100%"}}>
                {mirrorData.map((_item, _id) => {
                    return (
                        <div style={{display:"flex",borderBottom:`${_id === mirrorData.length -1? '1px solid #cacaca' : ''}`}}>
                            {_item.map((_data,_cel) => {
                                return (
                                    <div style={{display:"flex",padding:"3px", borderTop:"1px solid #cacaca", width:"150px", borderLeft:"1px solid #cacaca", background:`${_id === 0 ? '#cacaca' : _id === 1 ? '#f7f8f9' : _cel === 0 ? '#f7f8f9' : 'transparent'}`,borderRight:`${_cel === _item.length -1? '1px solid #cacaca' : ''}`}}>
                                        <span style={{fontWeight:`${_id === 0 || _cel === 0 ? 'bold' : 'normal'}`,textAlign:"center", width:"100%"}}>{typeof _data === 'number' ? _data.toFixed(2) : _data}</span>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
           
        </div>
    );
};

export default BettingMirror;
