import React, { Component } from 'react';
import { ImageContext } from '../../_contexts/imagecontext';
import logoDefault from './../../assets/logo-404.png';

export default class Error404 extends Component {
  render() {
    return (
      <ImageContext.Consumer>
        {({ logo }) => (
          <section>
            <div className="box">
              <img src={logo || logoDefault} alt={process.env.REACT_APP_NAME} />
              <span>
                <strong>404</strong>
              </span>
              <span>Página não encontrada</span>
            </div>
          </section>
        )}
      </ImageContext.Consumer>
    );
  }
}
