import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import api from '../../services/api';
import { handleReload } from '../../store/slices/updateRequest';

// import setStatistic from '../../services/apiStatistic'
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalCloseBoard({showModal, handleReloadReq, handleCloseModal, idGame, idTab}) {
    const dispatch = useDispatch();
    const userType = useSelector(state => state.userType.userType)
    const [userAuthData, setUserAuthData] = useState({
        name: '',
        password: ''
    });

    const handleSubmit = async(event) => { 
        event.preventDefault();
        loadingButton(true,document.querySelector('#submit-close-board'),document.querySelector('#submit-close-board').children[0].classList,document.querySelector('#submit-close-board').lastChild.nodeValue)
    
        let _data = {
            registration: userAuthData.name,
            password: userAuthData.password,
        };
    
        try {
            if(userType === 'dealer'){
                await api.put('/board/' + idGame, _data)
                .then(response => {
                    if (response.ok) {
                        ShowMessages(response)
                        if (response.data.status === 200) {  
                            handleReloadReq()
                            dispatch(handleReload(null))
                            handleCloseModal()  
                            window.removeTab(`tab_${idTab}`)
                        } else 
                            loadingButton(false,document.querySelector('#submit-close-board'))        
                    } else
                        loadingButton(false,document.querySelector('#submit-close-board'))
                })
            }else{
                await api.put('/board/' + idGame)
                .then(response => {
                    if (response.ok) {
                        ShowMessages(response)
                        if (response.data.status === 200) {
                            dispatch(handleReload(null))
                            handleCloseModal()  
                            window.removeTab(`tab_${idTab}`)
                        } else 
                            loadingButton(false,document.querySelector('#submit-close-board'))        
                    } else
                        loadingButton(false,document.querySelector('#submit-close-board'))
                })
            }
    
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-close-board'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setUserAuthData({
            name: '',
            password: ''
        })
    },[showModal])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-project-diagram"></i> Encerrar Jogo
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                   { userType === 'dealer' ? 
                        <div className={`row`} style={{border: "1px solid #cacaca", padding: "5px", borderRadius: '5px', margin: '0px'}}>     
                                <span style={{marginTop:"-15px", width:"fit-content", background:"#fff"}}>Autorização</span>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Usuário</label>
                                        <TextInput 
                                            name="nameUser" 
                                            id="nameUser" 
                                            required = {userType === 'dealer' ? true : false} 
                                            errorMessage={{ required: "Informe o usuário!" }}
                                            className="form-control "
                                            value={userAuthData.name} 
                                            maxLength={20}
                                            autoComplete="off"
                                            onChange={e => setUserAuthData({...userAuthData, name: e.target.value.toUpperCase()})}/>
                                    </div>                   
                                    <div className="col-md-6">
                                        <label>Senha</label>
                                        <TextInput 
                                            name="passwordUser" 
                                            id="passwordUser" 
                                            type="password"
                                            required = {userType === 'dealer' ? true : false} 
                                            errorMessage={{ required: "Infome o jogo!" }}
                                            className="form-control "
                                            value={userAuthData.password} 
                                            maxLength={20}
                                            autoComplete="off"
                                            onChange={e => setUserAuthData({...userAuthData, password: e.target.value})}/>
                                    </div>                   
                                </div>
                            </div> : 
                            <h3>Deseja Encerrar a mesa?</h3>
                    }                                       
                </Modal.Body>
                <Modal.Footer>  
                    {/* <Button type="submit" id="submit-close-board" className={`btn-theme ${loading ? "hidden" : ""}`}> */}
                    <Button type="submit" id="submit-close-board" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Encerrar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}