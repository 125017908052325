import { differenceInSeconds, addHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dealer from '../../assets/dealer.png';
import FOTO from '../../assets/user.png';
import TooltipItem from '../../components/tooltip';
import { handleSelectPosBoardDeletePlayer, handleSelectPosBoardPlayer, showDealerModal } from '../../store/slices/posBoardPlayerSlice';


export default function Player({playerData, positionStyle, isDealer = false, isBottomRow = false}) {

    const dispatch = useDispatch();
    const [seconds, setSeconds] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [hours, setHours] = useState('00');

    useEffect(() => {
        if(playerData.time !== ""){
            let showTime = setInterval(()=> {
                let timeInSeconds = differenceInSeconds(new Date(), addHours(new Date(playerData.time),process.env.REACT_APP_DIFERENCE_TIME ));

                let seconds = Math.floor(timeInSeconds) % 60 < 10 ? `0${Math.floor(timeInSeconds) % 60}` :  Math.floor(timeInSeconds) % 60;
                let minutes = Math.floor(timeInSeconds / 60) % 60 < 10 ? `0${ Math.floor(timeInSeconds / 60) % 60}` :  Math.floor(timeInSeconds / 60) % 60;
                let hours = (Math.floor(timeInSeconds / 3600)) < 10 ? `0${ Math.floor(timeInSeconds / 3600)}` :  Math.floor(timeInSeconds / 3600);

                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);

            }, [1000])
            
            return () => {
                clearInterval(showTime);
            }
        }
    },[playerData.time, playerData.positionId])
    
    return(
        <div className={positionStyle} >
            {!playerData.isOn && playerData.name !== 'Dealer' &&
                <div 
                    className="avatar-user text-center" 
                    onClick={() => 
                         dispatch(handleSelectPosBoardPlayer(playerData))
                    }>
                        
                    <TooltipItem  content={'Adicionar Usuário'} className="background-info">                   
                        <div className="no-user">
                        </div>
                    </TooltipItem>
                </div>
            }
            
            {playerData.isOn &&
                <div className="avatar-user text-center" onClick={() => 
                   dispatch(handleSelectPosBoardDeletePlayer(playerData))}>
                    {isBottomRow && 
                    <div className="user-data">
                        <div>
                            {playerData.name.split('-')[1].toString().split(' ')[1]}    
                        </div>
                        <div>
                            {hours}:{minutes}:{seconds}
                        </div>
                    </div>}
                    <div className="">
                        <img 
                            className={`user-avatar rounded-circle`}
                            src={playerData.url} 
                            onError={(e)=>{e.target.src=FOTO}}
                            alt="Foto" 
                            width="100%" 
                            height="100%"></img>
                    </div>
                    {!isBottomRow && 
                    <div className="user-data">
                        <div>
                            {playerData.name.split('-')[1].toString().split(' ')[1]}    
                        </div>
                        <div>
                            {hours}:{minutes}:{seconds}
                        </div>
                    </div>}
                </div>
            }

            {isDealer && playerData.name === 'Dealer' &&
                <div className="avatar-user text-center" onClick={() => dispatch(showDealerModal())} >
                <div className="">
                    <img 
                        className={`dealer-avatar rounded-circle`}
                        src={Dealer}
                        alt="Foto" 
                        width="100%" 
                        height="100%"></img>
                </div>
                <div className="dealer-data">
                    <div>
                        {playerData.name}   
                    </div>
                </div>
            </div>
            }

        </div>
    )
}