import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalTableSize({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState('');
  const [players, setPlayers] = useState(2);

  const setData = (obj, clear) => {
    setDescription(clear ? '' : obj.tablesize);
    setPlayers(clear ? '' : obj.players);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadingButton(
      true,
      document.querySelector('#submit-table-size'),
      document.querySelector('#submit-table-size').children[0].classList,
      document.querySelector('#submit-table-size').lastChild.nodeValue,
    );

    if (players < 2) {
      ShowMessages({
        status: 500,
        message: 'O total de players não pode ser menor que 2',
      });
      return;
    }

    if (players > 12) {
      ShowMessages({
        status: 500,
        message: 'O total de players não pode ser maior que 12',
      });
      return;
    }

    try {
      let _data = {
        tablesize: description,
        players,
      };

      if (editState)
        await api
          .put('/table-size/' + data.idtablesize, _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-table-size'),
                );
            } else
              loadingButton(
                false,
                document.querySelector('#submit-table-size'),
              );
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-table-size'));
            ShowMessages(error);
          });
      else
        await api
          .post('/table-size', _data)
          .then((response) => {
            ShowMessages(response);
            if (response.ok) {
              if (response.data.status === 200) {
                handleCloseModal();
                handleReload(true);
              } else
                loadingButton(
                  false,
                  document.querySelector('#submit-table-size'),
                );
            } else
              loadingButton(
                false,
                document.querySelector('#submit-table-size'),
              );
          })
          .catch((error) => {
            loadingButton(false, document.querySelector('#submit-table-size'));
            ShowMessages(error);
          });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-table-size'));
      ShowMessages(response);
    }
  };

  useEffect(() => {
    if (editState) {
      setLoading(true);
      setData(data, false);
      setLoading(false);
    } else setData({}, true);
  }, [data, editState, showModal]);

  useEffect(() => {
    setStatistic('modals\\table-size');
  }, []);

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-project-diagram"></i> Propriedades Table Size
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} />
          <div className={`${loading ? 'hidden' : ''}`}>
            <div>
              <label>Descrição</label>
              <TextInput
                name="descriptionTableSize"
                id="descriptionTableSize"
                required
                errorMessage={{ required: 'Infome o jogo!' }}
                className="form-control "
                value={description}
                maxLength={50}
                autoComplete="off"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <label>Total Máximo de Jogadores</label>
              <TextInput
                name="players"
                id="players"
                required
                errorMessage={{ required: 'Infome a quantidade de players!' }}
                className="form-control w-75"
                value={players}
                maxLength={2}
                type="number"
                autoComplete="off"
                onChange={(e) => setPlayers(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-table-size"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
