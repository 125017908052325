import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import api from '../../services/api';
import ContentWrapper from '../layouts/contentWrapper';
import ShowMessages from '../../components/Exceptions';
import setStatistic from '../../services/apiStatistic';
import { format, addHours } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import ModalScanner from '../modals/modal-scanner';
import { Button, Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

export default function DonuzHistoric() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [players, setPlayers] = useState([]);
  const [extract, setExtract] = useState([]);

  const [totalRake, setTotalRake] = useState(0);
  const [totalTournament, setTotalTournament] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [showScanner, setShowScanner] = useState(false);
  const [scanResult, setScanResult] = useState('');

  const handleOpenScanner = () => setShowScanner(true);
  const handleCloseScanner = () => setShowScanner(false);

  const handleScanResult = (result) => {
    setScanResult(result);
    setSearch(result);
  };

  const handleGetScanner = (scanResult) => {
    setSearch(scanResult);
    handleCloseScanner();
  };

  const handleScanError = (error) => {
    console.error(error);
    handleCloseScanner();
  };

  const searchData = async (e) => {
    setSearch(e);
    if (e.length < 3) {
      setPlayers([]);
    } else {
      await api
        .post('/donuz-historic', { search: e })
        .then((response) => {
          setPlayers(response.data);
        })
        .catch((error) => {
          ShowMessages(error);
        });
    }
  };

  const getExtract = async (e) => {
    setLoading(true);
    await api
      .post('/donuz-historic-extract', { cpf: e })
      .then((response) => {
        setExtract(response.data);

        let _rake = 0;
        let _tournament = 0;
        let _cash = 0;

        response.data.map((_item) => {
          _cash += _item.cashpoint;
          _tournament += _item.tournamentpoint;
          _rake += _item.rakepoint;
        });

        setTotalRake(_rake);
        setTotalCash(_cash);
        setTotalTournament(_tournament);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        ShowMessages(error);
      });
  };

  useEffect(() => {
    setStatistic('maintenance\\donuz-historic');
  }, []);

  return (
    <ContentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '15px',
          marginBottom: '15px',
          '@media (max-width: 600px)': {
            flexDirection: 'column',
          },
        }}
      >
        <div
          style={{
            maxWidth: '350px',
            width: '100%',
            '@media (max-width: 600px)': {
              maxWidth: '100%',
            },
          }}
        >
          <div>
            <label>Informe Nome ou CPF do Participante</label>
            <div className="d-flex">
              <input
                className="form-control me-2"
                value={search}
                onChange={(e) => searchData(e.target.value)}
                style={{ flex: '1' }}
              />
              <Button
                onClick={handleOpenScanner}
                className="btn btn-info"
                style={{ height: '2.5rem' }}
              >
                <FontAwesomeIcon icon={faBarcode} />
              </Button>
            </div>
            <Modal show={showScanner} onHide={handleCloseScanner}>
              <Modal.Body>
                <button
                  type="button"
                  onClick={handleCloseScanner}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    position: 'absolute',
                    top: '-9px',
                    right: '-5px',
                    cursor: 'pointer',
                  }}
                >
                  <FaTimes size={18} color="black" />
                </button>
                <ModalScanner
                  onResult={handleScanResult}
                  onError={handleScanError}
                  onGetResult={handleGetScanner}
                />
              </Modal.Body>
            </Modal>
          </div>

          {players?.length <= 0 ? (
            <div>
              <div
                className="bg-warning"
                style={{
                  borderRadius: '5px',
                  padding: '10px',
                  marginTop: '15px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div style={{ color: '#000' }}>
                  <i className="fa fa-exclamation-triangle"></i>
                  <span> Participante não encontrado</span>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                marginTop: '10px',
                height: '600px',
                overflowX: 'auto',
                '@media (max-width: 600px)': {
                  height: 'auto',
                },
              }}
            >
              {players?.map((_item) => {
                return (
                  <div
                    key={_item.cpf}
                    style={{
                      borderBottom: '1px solid #cacaca',
                      padding: '5px',
                      background: '#f7f8f9',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => getExtract(_item.cpf)}
                  >
                    <div>
                      <span style={{ fontWeight: 'bold' }}>IDPlayer:</span>{' '}
                      {_item.idplayer}
                      <span style={{ fontWeight: 'bold', marginLeft: '15px' }}>
                        Nome:
                      </span>{' '}
                      {_item.nome}
                    </div>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>CPF:</span>{' '}
                      {_item.cpf}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <Loading loading={loading} addClass="width400" />
        <div
          style={{
            marginTop: '10px',
            height: '600px',
            overflowX: 'auto',
            '@media (max-width: 600px)': {
              height: 'auto',
            },
          }}
          className={`${loading ? 'hidden' : ''}`}
        >
          <table className="table table-striped w-100">
            <thead className="thead-theme">
              <tr>
                <th style={{ width: '50px' }}>Data</th>
                <th style={{ width: '90px', textAlign: 'right' }}>Cash</th>
                <th style={{ width: '160px', textAlign: 'right' }}>Torneio</th>
                <th style={{ width: '90px', textAlign: 'right' }}>Rake</th>
              </tr>
            </thead>
            <tbody>
              {extract.map((_item) => {
                return (
                  <tr key={_item.datetournament}>
                    <td>
                      {format(
                        addHours(
                          new Date(_item.datetournament),
                          process.env.REACT_APP_DIFERENCE_TIME,
                        ),
                        'dd/MM/yyyy',
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>{_item.cashpoint}</td>
                    <td style={{ textAlign: 'right' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{_item.tournamentpoint}</span>
                        <span
                          style={{
                            fontSize: '9px',
                            marginTop: '-2px',
                            lineHeight: '11px',
                          }}
                        >
                          {_item.tournament?.replace('\n', '')}
                        </span>
                      </div>
                    </td>
                    <td style={{ textAlign: 'right' }}>{_item.rakepoint}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: 'flex',
              background: '#f7f8f9',
              padding: '10px',
              gap: '15px',
            }}
          >
            <div
              style={{
                width: '50%',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ fontWeight: 'bold' }}>Total Cash:</span>{' '}
                <span>{totalCash}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ fontWeight: 'bold' }}>Total Torneio:</span>{' '}
                <span>{totalTournament}</span>
              </div>
            </div>
            <div
              style={{
                width: '50%',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ fontWeight: 'bold' }}>Total Rake:</span>{' '}
                <span>{totalRake}</span>
              </div>
              <div
                className="text-success"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span style={{ fontWeight: 'bold' }}>Total Geral:</span>{' '}
                <span>{totalCash + totalRake + totalTournament}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
}
