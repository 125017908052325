import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reload: false,
    reloadDataboard: false,
}

export const updateRequestSlice = createSlice({
  name: 'updateRequest',
  initialState,
  reducers: {
    handleReload: (state) => {
        state.reload = !state.reload
    },
    handleReloadBoardInfo: (state) => {
      state.reloadDataboard = !state.reloadDataboard;
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
                handleReload, 
                handleReloadBoardInfo
              } = updateRequestSlice.actions

export default updateRequestSlice.reducer