import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import User from '../../assets/user.png';
import ContentWrapper from '../../pages/layouts/contentWrapper';
import api from '../../services/api';
import { BoardPosUsers } from '../game/BoardPosUsers';


import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import ShowMessages from '../Exceptions';
// import setStatistic from './../../services/apiStatistic';
// import {checkPermission} from './../../components/security';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import ModalAddPlayer from '../../pages/modals/modal-add-player';
import ModalChangeDealer from '../../pages/modals/modal-change-dealer';
import ModalCloseBoard from '../../pages/modals/modal-close-board';
import ModalConfigTable from '../../pages/modals/modal-config-table';
import ModalDeletePlayer from '../../pages/modals/modal-del-player';
import ModalHistorieTable from '../../pages/modals/modal-historie-table';

import {
  ReadDataStorage,
  REGISTRATION,
  WriteDataStorage,
} from '../../services/auth';
import {
  closeDealerModal,
  closeDelPlayerModal,
  closePlayerModal,
} from '../../store/slices/posBoardPlayerSlice';
import DragAndDrop from '../../_contexts/dragndrop';
import BoardInfo from '../Boards/boardInfo';
import Loading from '../Loading';
//import { BoardPosUsers } from '../game/BoardPosUsers';

const dealer = { id: 'dealer', name: 'Dealer' };

export default function GamePage({
  props,
  id,
  idGame,
  handleFullScreenGamePage,
  idBoard,
}) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.userType.userType);
  const showPlayerModal = useSelector(
    (state) => state.posBoard.showModalAddPlayer,
  );
  const showModalDelPlayer = useSelector(
    (state) => state.posBoard.showModalDelPlayer,
  );
  const showModalDealer = useSelector(
    (state) => state.posBoard.showModalDealer,
  );

  const flagReload = useSelector(
    (state) => state.updateRequest.reloadDataboard,
  );

  const [isOpen, setIsOpen] = useState([]);

  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [showModalCloseBoard, setShowModalCloseBoard] = useState(false);
  const [showModalConfigBoard, setShowModalConfigBoard] = useState(false);
  const [showModalHistorieTable, setShowModalHistorieTable] = useState(false);

  const [idgame, setIdGame] = useState(0);
  const [players, setPlayers] = useState([]);

  const [boardData, setBoardData] = useState({});
  const [playerAction, setPlayerAction] = useState({
    pos: -1,
    action: '',
    id: 0,
    name: '',
  });

  const handleClosePlayerModal = () => {
    dispatch(closePlayerModal());
  };
  const handleCloseDelPlayerModal = () => {
    dispatch(closeDelPlayerModal());
  };
  const handleCloseDealerModal = () => {
    dispatch(closeDealerModal());
  };

  const handleCloseBoardModal = () => {
    setShowModalCloseBoard(false);
  };
  const handleCloseConfigBoardModal = () => {
    setShowModalConfigBoard(false);
  };
  const handleCloseHistorieTable = () => {
    setShowModalHistorieTable(false);
  };

  const handleNewId = (_id) => {
    window.addTab(
      null,
      boardData.boardName.toUpperCase(),
      'fa fa-play',
      '/play-game',
      {
        idGame: _id,
      },
      0,
      true
    );
    setInterval(() => {
      window.removeTab(`tab_${props.id}`);
    }, 3000);
  };

  const handleReload = () => {
    setReload((s) => !s);
  };

  const handlePositionPlayer = (pos, action) => {
    setPlayerAction({ ...playerAction, pos: pos, action: action });
  };

  const getBoardOn = async (_id) => {
    setIdGame(_id);
    await api
      .get(`/game/${_id}`)
      .then((response) => {
        if (response.ok) {
          const databoard = response.data[0];
          console.log(databoard, 'databoard');
          if (databoard.length !== 0) {
            if (databoard.endgame === null) {
              setBoardData({
                boardName: databoard.board,
                boardTime: databoard.startgame,
                gameName: databoard.gametype,
                idGameName: databoard.idgametype,
                qntPlayers: databoard.maxplayers,
                idTableSize: databoard.idtablesize,
                boardimage: databoard.boardimage,
              });

              let arrPlayers = [];
              api
                .post('/getBoardPlayers', { idgame: databoard.idgame })
                .then((_response) => {
                  const dataBoardPlayers = _response.data;
                  // console.log("🚀 ~ file: GamePage.js:88 ~ api.post ~ dataBoardPlayers", dataBoardPlayers)

                  for (let i = 0; i < databoard.maxplayers; i++) {
                    let objPlayer = {
                      positionId: i,
                      isOn: false,
                      name: '',
                      time: '',
                      idUser: -1,
                      url: '',
                    };

                    dataBoardPlayers?.forEach((player) => {
                      if (
                        player.boardposition === i &&
                        player.endgame === null
                      ) {
                        objPlayer.idUser = player.idgamesplayers;
                        objPlayer.isOn = true;
                        objPlayer.time = player.startgame;
                        objPlayer.name = player.player.player;
                        objPlayer.url = player.url !== null ? player.url : User;
                      }
                    });

                    arrPlayers.push(objPlayer);
                  }

                  setPlayers(arrPlayers);
                  setLoading(false);
                })
                .catch((error) => {
                  ShowMessages(error);
                });
            } else {
              // setShowModal(true);
              setPlayers([]);
              setBoardData({});
            }
          } else {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log('erro');
        ShowMessages(error);
      });
  };

  useEffect(() => {
    getBoardOn(idGame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, playerAction, flagReload]);

  useEffect(() => {
    setShowMenu((s) => !s);
  }, []);

  const handleOpen = (id) => {
    let _data = [];
    if (_data['sub' + id] === undefined) _data['sub' + id] = true;

    if (isOpen['sub' + id] === true) _data['sub' + id] = false;

    setIsOpen(_data);
  };

  const Logout = async () => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente sair do sistema?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api
          .post('/logout')
          .then(() => {
            let _registration = ReadDataStorage(REGISTRATION);
            localStorage.clear();
            WriteDataStorage(REGISTRATION, _registration);
            window.location.href = '/login';
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };

  return (
    <>
      <DragAndDrop>
        <Loading loading={loading} />
        {!loading && (
          <ContentWrapper className="game-container content-wrapper-no-overflow position-relative p-0">
            <ModalAddPlayer
              idgame={idgame}
              showModal={showPlayerModal}
              handleCloseModal={handleClosePlayerModal}
              handlePositionPlayer={handlePositionPlayer}
              handleReload={handleReload}
            />

            <ModalChangeDealer
              idgame={idgame}
              showModal={showModalDealer}
              handleCloseModal={handleCloseDealerModal}
              handleReload={handleReload}
            />

            <ModalDeletePlayer
              idGame={idgame}
              showModal={showModalDelPlayer}
              handleReload={handleReload}
              handleCloseModal={handleCloseDelPlayerModal}
              handlePositionPlayer={handlePositionPlayer}
            />

            <ModalConfigTable
              idGame={idgame}
              boardName={boardData.boardName}
              gameName={boardData.gameName}
              idGameName={boardData.idGameName}
              maxPlayers={boardData.qntPlayers}
              idTableSize={boardData.idTableSize}
              showModal={showModalConfigBoard}
              handleCloseModal={handleCloseConfigBoardModal}
              handleNewId={handleNewId}
            />

            <ModalCloseBoard
              idGame={idgame}
              showModal={showModalCloseBoard}
              handleReload={handleReload}
              handleCloseModal={handleCloseBoardModal}
              idTab={id}
            />

            <ModalHistorieTable
              idGame={idgame}
              showModal={showModalHistorieTable}
              handleReload={handleReload}
              handleCloseModal={handleCloseHistorieTable}
            />

            <div
              className="board-container mx-auto d-flex align-items-center"
              style={{ height: +window.screen.height }}
            >
              <div
                className="game-table"
                style={{
                  background: `url(${
                    boardData?.boardimage 
                  }) no-repeat center center`,
                  backgroundSize: '100% 100%',
                  marginTop: '200px',
                }}
              >
                {
                  <BoardPosUsers
                    players={players}
                    dealer={dealer}
                    idGame={idgame}
                  />
                }

                <div className="float-btn-close">
                  <button
                    className="btn  d-none d-md-block d-lg-block d-xl-block"
                    onClick={() => {
                      setShowModalCloseBoard(true);
                    }}
                  >
                    <em
                      style={{ color: 'white', width: '100%' }}
                      className={`fas fa-circle-xmark fa-lg`}
                    ></em>
                  </button>
                  <button
                    className="btn d-md-none"
                    onClick={() => {
                      setShowModalCloseBoard(true);
                    }}
                  >
                    <em
                      style={{ color: 'white', width: '100%' }}
                      className={`fas fa-circle-xmark fa-lg`}
                    ></em>
                  </button>
                </div>

                {userType === 'admin' && (
                  <div className="float-btn-eye">
                    <button
                      className="btn  d-none d-md-block d-lg-block d-xl-block"
                      onClick={() => {
                        handleFullScreenGamePage();
                        setShowMenu((s) => !s);
                      }}
                    >
                      <em
                        style={{ color: 'white' }}
                        className={`fas ${
                          showMenu ? 'fa-eye' : 'fa-eye-slash'
                        } fa-lg`}
                      ></em>
                    </button>
                    <button
                      className="btn d-md-none"
                      onClick={() => {
                        handleFullScreenGamePage();
                        setShowMenu((s) => !s);
                      }}
                    >
                      <em
                        style={{ color: 'white' }}
                        className={`fas ${
                          showMenu ? 'fa-eye' : 'fa-eye-slash'
                        } fa-lg`}
                      ></em>
                    </button>
                  </div>
                )}
                {userType === 'dealer' && (
                  <div className="float-btn-eye">
                    {/* <button className="btn  d-none d-md-block d-lg-block d-xl-block" onClick={() => {toggleAside()}}> */}
                    <button
                      className="btn  d-none d-md-block d-lg-block d-xl-block"
                      onClick={(e) => Logout()}
                    >
                      <span
                        title="Sair do Sistema"
                        className="nav-link"
                        style={{ textAlign: 'right' }}
                      >
                        <em
                          style={{ color: 'white' }}
                          className={`fas fa-right-from-bracket fa-lg`}
                        ></em>
                      </span>
                    </button>
                    {/* <button className="btn d-md-none" onClick={() => {toggleAside()}}>
                                                <em style={{color: 'white'}} className={`fas  fa-right-from-bracket fa-lg`}></em>
                                            </button> */}
                  </div>
                )}

                <div
                  style={{
                    padding: '5px',
                    position: 'absolute',
                    zIndex: '200000',
                    display: 'flex',
                    color: 'rgb(255, 255, 255)',
                    top: '10px',
                    fontSize: '8px',
                    right: '5px',
                  }}
                >
                  <div className="d-flex flex-column align-items-end">
                    <BoardInfo boardData={boardData} />
                    <Dropdown
                      group
                      isOpen={isOpen['sub' + 0] === true ? true : false}
                      size="md"
                      toggle={(e) => handleOpen(0)}
                    >
                      <DropdownToggle
                        caret
                        style={{
                          marginLeft: '2px',
                          cursor: 'pointer',
                          background: 'transparent',
                          marginTop: '5px',
                          border: '1px solid #fff',
                        }}
                      >
                        <i className="fa fa-cogs" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setShowModalHistorieTable(true);
                          }}
                        >
                          <i className="fa fa-clock-rotate-left" /> Histórico da
                          Jogo
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setShowModalConfigBoard(true);
                          }}
                        >
                          <i className="fa fa-table" /> Configurações da Mesa
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </ContentWrapper>
        )}
      </DragAndDrop>
    </>
  );
}
