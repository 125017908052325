import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import { SelectGroup, TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalGameType({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]       = useState(false)

    const [URL, setURL]                       = useState('');
    const [datetournament, setDateTournament] = useState('');
    const [tournament, setTournament]         = useState('');

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-url'),document.querySelector('#submit-url').children[0].classList,document.querySelector('#submit-url').lastChild.nodeValue)

        try {
            let _data = {
                URL,
                datetournament,
                tournament
            }

            await api.post('/URL-Block', _data)
                      .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-url'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-url'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-url'))
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-url'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setURL('');
        setDateTournament('');
        setTournament('');  
    } ,[showModal])

    useEffect(() => {
        setStatistic('modals\\URLBlock')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-ban"></i> Bloqueio de URL
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}>  
                        <div>
                            <label>Torneio</label>
                            <TextInput 
                                name="tournament" 
                                id="tournament" 
                                required
                                errorMessage={{ required: "Informe o nome do torneio" }}
                                className="form-control "
                                value={tournament} 
                                maxLength={50}
                                autoComplete="off"
                                onChange={e => setTournament(e.target.value)}/>
                        </div>     
                        <div>
                            <label>Data do Torneio</label>
                            <TextInput 
                                name="datetournament" 
                                id="datetournament" 
                                required
                                type="date"
                                errorMessage={{ required: "Informe a data do torneio" }}
                                className="form-control "
                                value={datetournament} 
                                maxLength={50}
                                autoComplete="off"
                                onChange={e => setDateTournament(e.target.value)}/>
                        </div>  
                        <div>
                            <label>URL</label>
                            <TextInput 
                                name="url" 
                                id="url" 
                                required
                                type="number"
                                errorMessage={{ required: "Informe a URL" }}
                                className="form-control "
                                value={URL} 
                                autoComplete="off"
                                onChange={e => setURL(e.target.value)}/>
                        </div>                                                       
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-url" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}