import React from "react";

import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from 'rdndmb-html5-to-touch'

function DragAndDrop({ children }) {
  return <DndProvider options={HTML5toTouch}> {children}</DndProvider>;
}

export default DragAndDrop;
