import { differenceInSeconds, addHours } from 'date-fns';
import { memo, useEffect, useState } from 'react';
import api from '../../services/api';

const CardBoardInfo = ({
    name,
    startGame,
    idGame,
    players,
    gametype,
    maxplayers,
    idBoard,
}) => {
    const [seconds, setSeconds] = useState('');
    const [minutes, setMinutes] = useState('');
    const [hours, setHours] = useState('');
    const [image, setImage] = useState('');

    const getImage = async () => {
        await api
            .get('/getDataTable')
            .then((response) => {
                const boards = response.data.boards;
                const myBoard = boards.find((item) => item.idboard === idBoard);
                setImage(myBoard.boardimage);
            })
            .catch((error) => console.error(error));
    };

    //   console.log(image);

    useEffect(() => {
        getImage();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            let timeInSeconds = differenceInSeconds(
                new Date(),
                addHours(new Date(startGame), process.env.REACT_APP_DIFERENCE_TIME),
            );

            let seconds =
                Math.floor(timeInSeconds) % 60 < 10
                    ? `0${Math.floor(timeInSeconds) % 60}`
                    : Math.floor(timeInSeconds) % 60;
            let minutes =
                Math.floor(timeInSeconds / 60) % 60 < 10
                    ? `0${Math.floor(timeInSeconds / 60) % 60}`
                    : Math.floor(timeInSeconds / 60) % 60;
            let hours =
                Math.floor(timeInSeconds / 3600) < 10
                    ? `0${Math.floor(timeInSeconds / 3600)}`
                    : Math.floor(timeInSeconds / 3600);

            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);

    const showTab = () => {
        window.addTab(
            null,
            name.toUpperCase(),
            'fa fa-play',
            '/play-game',
            {
                idGame: idGame,
            },
            0,
            true
        );
    };
    return (
        <>
            <div
                className="card"
                style={{
                    backgroundColor: 'rgb(20, 2, 2)',
                    color: 'white',
                    fontWeight: 'bold',
                    height:"225px"
                }}
            >
                {image !== ''? (
                    <>
                        <div className="card-header d-flex align-items-center justify-content-between align-middle">
                            <p className="font-weight-bold">{name}</p>
                            <p className="font-weight-bold">
                                Tempo: {hours}:{minutes}:{seconds}
                            </p>
                        </div>

                        <div className="card-body d-flex align-items-center justify-content-center">
                            <img src={image} alt="mesa" />
                        </div>

                        <div
                            className="card-footer d-flex align-items-center"
                            style={{ justifyContent: 'space-between' }}
                        >
                            <div>
                                <div>{gametype}</div>
                                <div>
                                    {players} de {maxplayers}
                                </div>
                            </div>
                            <div>
                                <button className="btn btn-success" onClick={() => showTab()}>
                                    <i className="fab fa-gratipay" /> Ver Mesa
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", flex:"1"}}>
                        <em className="fas fa-circle-notch fa-spin fa-2x" style={{color:"#fff"}}></em>
                    </div>
                )}
            </div>
        </>
    );
};

export default memo(CardBoardInfo);
