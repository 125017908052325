import React, { useState } from 'react';
import ContentWrapper from '../../pages/layouts/contentWrapper';
import ModalPlayGame from '../../pages/modals/modal-play-game';
// import setStatistic from './../../services/apiStatistic';
import Mesa from '../../assets/mesa1.png';

export default function StartPage({ handleFullScreenStartPage }) {
  const [showModal, setShowModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ContentWrapper className="game-container content-wrapper-no-overflow position-relative">
        <div className="float-btn-eye">
          <button
            className="btn  d-none d-md-block d-lg-block d-xl-block"
            onClick={() => {
              handleFullScreenStartPage();
              setShowMenu((s) => !s);
            }}
          >
            <em
              style={{ color: 'white' }}
              className={`fas ${showMenu ? 'fa-eye' : 'fa-eye-slash'} fa-lg`}
            ></em>
          </button>
          <button
            className="btn d-md-none"
            onClick={() => {
              handleFullScreenStartPage();
              setShowMenu((s) => !s);
            }}
          >
            {/* <button className="btn d-md-none" onClick={() => {toggleShowHide();toggleAside(); setShowMenu(s => !s);}}> */}
            <em
              style={{ color: 'white' }}
              className={`fas ${showMenu ? 'fa-eye' : 'fa-eye-slash'} fa-lg`}
            ></em>
          </button>
        </div>

        <ModalPlayGame
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleFullScreenStartPage={handleFullScreenStartPage}
        />

        <div className="start-game d-flex flex-column">
          <div className="start-game-img"></div>

          <div className="d-flex justify-content-center align-items-start">
            <button
              type="button"
              className="btn-play-game btn btn-success btn-lg my-5"
              onClick={() => setShowModal(true)}
            >
              <i className="fa fa-play" /> Iniciar Jogo
            </button>
          </div>
        </div>
      </ContentWrapper>
    </>
  );
}
