import React, { useState, useEffect } from 'react';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';

const DraggableModalDialog = (props) => (
  <Draggable handle=".modal-header">
    <div {...props} />
  </Draggable>
);

const ModalAvailableProducts = ({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) => {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');

  const setData = (obj, clear) => {
    setDescription(clear ? '' : obj.available);
    setQuantity(clear ? '' : obj.quantity || '');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitButton = document.querySelector('#submit-available');

    if (submitButton) {
      loadingButton(true, submitButton);
    }

    try {
      const _data = {
        available: description,
        quantity,
      };

      if (editState) {
        const response = await api.put(`/available/${data.idavailable}`, _data);
        ShowMessages(response);
        if (response.ok && response.data.status === 200) {
          handleCloseModal();
          handleReload(true);
        } else {
          loadingButton(false, submitButton);
        }
      } else {
        const response = await api.post('/available', _data);
        ShowMessages(response);
        if (response.ok && response.data.status === 200) {
          handleCloseModal();
          handleReload(true);
        } else {
          loadingButton(false, submitButton);
        }
      }
    } catch (error) {
      loadingButton(false, document.querySelector('#submit-available'));
      ShowMessages(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editState) {
      setLoading(true);
      setData(data, false);
      setLoading(false);
    } else {
      setData({}, true);
    }
  }, [data, editState, showModal]);

  useEffect(() => {
    setStatistic('modals/available');
  }, []);

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-project-diagram"></i> Produtos
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} />
          <div className={`${loading ? 'hidden' : ''}`}>
            <div>
              <label htmlFor="description">Descrição</label>
              <TextInput
                name="description"
                id="description"
                required
                errorMessage={{ required: 'Informe a descrição!' }}
                className="form-control"
                value={description}
                maxLength={50}
                autoComplete="off"
                aria-label="Descrição do Produto"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="quantity">Quantidade do Produto</label>
              <TextInput
                name="quantity"
                id="quantity"
                required
                errorMessage={{
                  required: 'Por favor, informe a quantidade do produto.',
                }}
                className="form-control"
                value={quantity}
                maxLength={50}
                autoComplete="off"
                aria-label="Quantidade do Produto"
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="submit"
            id="submit-available"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-light-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
};

export default ModalAvailableProducts;
