import React, { useEffect, useState } from 'react';
import Filter from '../../components/Filter';
import Grid from '../../components/Grid';
import Loading from '../../components/Loading';
import ContentWrapper from '../layouts/contentWrapper';
import { checkPermission } from '../../components/security';
import setStatistic from '../../services/apiStatistic';
import { format, startOfWeek  }        from 'date-fns'
import ShowMessages from "../../components/Exceptions";
import api from "../../services/api";
import  loadingButton  from './../layouts/Buttons'

export default function ReportRakes() {
    const [loading, setLoading]                            = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [filters, setFilters]                            = useState([])

    const [startDate, setStartDate]                        = useState(format(startOfWeek(new Date()),'yyyy-MM-dd'))
    const [endDate, setEndDate]                            = useState(format(new Date(),'yyyy-MM-dd'))
    const [file, setFile]                                  = useState('')

    const getData = async() => {
        setGridParams({
            idimportsuprema : {text       : "",
                              primaryKey : true,
                              visible    : false},
            player          : {text       : "Jogador"},
            nickname        : {text       : "Nickname"},
            cpf             : {text       : "CPF"},
            totalfee        : {text       : "Total Fee",
                               className  : 'text-right',
                               item       : {
                                className  : 'text-right'
                               }},
            factortournament : {text       : "Fator",
                                className  : 'text-right',
                                item       : {
                                    className  : 'text-right'
                               }},
            rake             : {text       : "Pontuação",
                                className  : 'text-right',
                               item       : {
                                   className  : 'text-right'
                              }},

            create_at       : {text       : "Data Importação",
                               className  : 'text-right',
                               item       : {
                                    className  : 'text-right'}}
        })              
    }

    useEffect(() => {
        getData()
        handleDateFilter()
        setStatistic('maintenance\\reports-rakes')
    },[])
    
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}
    const handleDateFilter = () => {
        let _filters = [{ field      : 'create_at',
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true    
                        }]
         
        setFilters(_filters)
    }

    const handleImport = async(e) => {
        e.preventDefault()
        if ((!e.target.files[0])) {
            ShowMessages({status : 500, message : "Nenhum arquivo foi informado!"})
            return
        }

        if (file.size > 10e6) {
            ShowMessages({status : 500, message : "O arquivo informado não pode ser maior que 10Mb!"})
            return 
        }
        loadingButton(true,document.querySelector('#submit-import'),document.querySelector('#submit-import').children[0].classList,document.querySelector('#submit-import').lastChild.nodeValue)
        try {

            const formData = new FormData();
            formData.append('file', e.target.files[0]);

            await api.post('/reportRakesImport', formData, {headers: { "Content-Type": "multipart/form-data"}})
                      .then(response => {
                         ShowMessages(response)
                        loadingButton(false,document.querySelector('#submit-import'),'fa fa-upload','Importar')        
                       })
                       .catch( error => {  
                         loadingButton(false,document.querySelector('#submit-import'),'fa fa-upload','Importar')    
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-import'),'fa fa-upload','Importar')    
            ShowMessages(response)
        }
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div  className="box-maintance-buttons">
                        <div>
                            <button id="submit-import" className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => document.querySelector('#inputFile').click()}><i className="fa fa-upload"></i></button>
                            <button className="btn btn-success" title="Importar Planilha" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info"  title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                        <div style={{display:"flex", marginLeft:"25px", borderLeft:"1px dashed #cacaca"}}>
                            <div style={{marginLeft:"15px"}}>
                                <label>Data Inicial</label>
                                <input type="date" className="form-control" value={startDate} onChange={e => setStartDate(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px"}}>
                                <label>Data Final</label>
                                <input type="date" className="form-control" value={endDate} onChange={e => setEndDate(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px", display:"flex", alignItems:"center", marginTop:"12px"}}>
                                <button type="button" className="btn btn-success" onClick={e => handleDateFilter(e)}><i class="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                        
                        <div style={{display:'none'}}>
                            <input type="file" accept=".xlsx"  onChange={e => handleImport(e)} className="form-control" id="inputFile" aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/reportRakes' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      filename            = "Relatório de Rakes"
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}