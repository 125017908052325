import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../services/api';

import ShowMessages from '../../components/Exceptions';
// import setStatistic from './../../services/apiStatistic';
// import {checkPermission} from './../../components/security';
import { Modal } from 'react-bootstrap';
import GamePage from '../../components/GameAdmin/GamePage';
import StartPage from '../../components/GameAdmin/StartPage';
import Loading from '../../components/Loading';

export default function GameAdmin(props) {
    const userType = useSelector((state) => state.userType.userType);

    const [hasBoard, setHasBoard] = useState(false);
    const [showFullScreenStartPage, setShowFullScreenStartPage] = useState(true);
    const [showFullScreenGamePage, setShowFullScreenGamePage] = useState(true);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getBoardOn = async () => {
            await api
                .get(`/game/${props?.props?.idGame}`)
                .then((response) => {
                    if (response.ok) {
                        console.log(response.data)
                        const databoard = response.data[0];
                        if (databoard.isopen) {
                            if (databoard.length !== 0) {
                                setHasBoard(true);
                                setLoading(false);
                            }
                        } else {
                            ShowMessages({
                                status: 325,
                                message: 'Está mesa já foi encerrada!',
                            });
                            window.removeTab(`tab_${props.id}`);
                        }
                    }
                })
                .catch((error) => {
                    ShowMessages(error);
                });
        };
        if (props?.props?.idGame || userType === 'dealer') {
            getBoardOn();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFullScreenStartPage = () => {
        setShowFullScreenStartPage((s) => !s);
    };

    const handleFullScreenGamePage = () => {
        setShowFullScreenGamePage((s) => !s);
    };

    return (
        <>
            <Loading loading={loading} />
            {!loading && !hasBoard && (
                <StartPage handleFullScreenStartPage={handleFullScreenStartPage} />
            )}

            {!loading && hasBoard && (
                <GamePage
                    props={props}
                    id={props.id}
                    idGame={props.props.idGame}
                    handleFullScreenGamePage={handleFullScreenGamePage}
                />
            )}

            {!loading && !hasBoard && (
                <Modal show={showFullScreenStartPage} fullscreen={true}>
                    <StartPage handleFullScreenStartPage={handleFullScreenStartPage} />
                </Modal>
            )}

            {!loading && hasBoard && (
                <Modal show={showFullScreenGamePage} fullscreen={true}>
                    <GamePage
                        props={props}
                        id={props.id}
                        idGame={props.props.idGame}
                        handleFullScreenGamePage={handleFullScreenGamePage}
                    />
                </Modal>
            )}
        </>
    );
}
