import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkPermission } from '../../components/security';
import KSContainer from './../../pages/layouts/Container';
import ChatMessageContainer from './ChatMessageContainer';
import ChatUserList from './ChatUserList';
import Header from './Header';
import Sidebar from './Sidebar';
import { NotificationContainer } from 'react-notifications';
import { socket } from './../../services/chatAPI';
import defaultLogo from './../../assets/logo-404.png';
import { handleUserType } from '../../store/slices/userAuth';
import GameDealer from '../maintenance/game-dealer';
import Loading from '../../components/Loading';
import api from '../../services/api';
import { IDCOMPANY, ReadDataStorage } from '../../services/auth';

const usersType = ['admin', 'gerente', 'dealer'];

const BasePage = (props) => {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState('');
  const [company, setCompany] = useState(-1);
  const [reset, setReset] = useState(false);
  const [pages, setPages] = useState(-1);
  const [logo, setLogo] = useState(null);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const [isLogoLoading, setIsLogoLoading] = useState(true);

  useEffect(() => {
    setReset(true);
    if (checkPermission(8, 14)) {
      dispatch(handleUserType(usersType[2]));
      setUserType(usersType[0]); // RENATO
    } else {
      dispatch(handleUserType(usersType[0]));
      setUserType(usersType[0]);
    }
  }, [dispatch]);

  const fetchLogo = async () => {
    setIsLogoLoading(true);
    try {
      const companyId = ReadDataStorage(IDCOMPANY);
      if (companyId) {
        const response = await api.get(`/getLogo/${companyId}`);
        if (response.data && response.data.logobase64) {
          setLogo(response.data.logobase64);
        } else {
          setLogo(null);
        }
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
      setLogo(null);
    } finally {
      setIsLogoLoading(false);
    }
  };

  useEffect(() => {
    fetchLogo();
  }, [company]);

  useEffect(() => {
    if (logo) {
      const img = new Image();
      img.src = logo;
      img.onload = () => setIsLogoLoaded(true);
      img.onerror = () => {
        setIsLogoLoaded(true);
        setLogo(null);
      };
    } else {
      setIsLogoLoaded(true);
    }
  }, [logo]);

  if (userType === 'dealer') {
    document.querySelector('.content-wrapper')?.classList.add('padding-0');
    return (
      <>
        <GameDealer user={userType} />
        <NotificationContainer />
      </>
    );
  }

  return (
    <>
      <div className="wrapper">
        <Header history={props.history} callbackCompany={setCompany} />
        <Sidebar ActualCompany={company} />
        <ChatUserList socket={socket} />

        <section
          className={`section-container ${
            userType === 'dealer' ? 'hide-aside-margin' : ''
          }`}
        >
          <div
            id="content-tab"
            className={`${pages > 0 ? 'heigth100' : 'hidden'}`}
          >
            <KSContainer
              reset={reset}
              callbackReset={setReset}
              callbackPages={setPages}
            />
          </div>
          <div className="box-base">
            {isLogoLoading || !isLogoLoaded ? (
              <Loading loading={true} />
            ) : (
              <img
                src={logo || defaultLogo}
                alt="logo"
                style={{ maxWidth: '25%', width: '25%' }}
              />
            )}
          </div>

          <ChatMessageContainer socket={socket} />
        </section>
      </div>

      <NotificationContainer />
    </>
  );
};

export default BasePage;
