import React, { useState, useEffect } from 'react';
import ContentWrapper from '../layouts/contentWrapper';
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api';
import ShowMessages from '../../components/Exceptions';
import setStatistic from '../../services/apiStatistic';
import { checkPermission } from '../../components/security';
import Swal from 'sweetalert2';
import ModalBenefit from '../modals/modal-benefit';

const BenefitCategory = () => {
  const [loading, setLoading] = useState(false);
  const [editState, setEditState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [reload, setReload] = useState(false);
  const [print, setPrint] = useState(false);
  const [excel, setExcel] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [filters, setFilters] = useState([]);
  const [editPermission, setEditPermission] = useState(false);

  useEffect(() => {
    const getBenefitCategory = async () => {
      const _delCheck = !checkPermission(4, 7);
      const _editCheck = !checkPermission(8, 7);

      setEditPermission(_editCheck);
      setGridParams({
        idbenefitcategory: {
          text: '#ID',
          dataType    : "number",
          mask: { name: 'leftSlice', value: '00000' },
          primaryKey: true,
        },
        benefitcategory: { text: 'Descrição',searchDefault : true  },
        basemultiplier: { text: 'Multiplicador Base (%)',dataType    : "number" },
        Options: {
          text: 'Opções',
          className: 'text-center',
          order: false,
          filter: false,
          item: [
            {
              type: 'Custom',
              props: {
                tag: 'button',
                title: 'Editar Benefícios',
                className: 'btn btn-sm btn-primary',
                icon: 'fa fa-edit',
                disabled: _editCheck,
                indexcallback: 0,
              },
            },
            {
              type: 'Custom',
              props: {
                tag: 'button',
                title: 'Exclusão de Benefícios',
                className: 'btn btn-sm btn-danger',
                icon: 'fa fa-trash',
                disabled: _delCheck,
                indexcallback: 1,
              },
            },
          ],
        },
      });
    };

    getBenefitCategory();
    setStatistic('maintenance\\benefitcategory');
  }, []);

  const handleCloseModal = () => setShowModal(false);
  const handleFilter = () => setIsOpenFilter(!isOpenFilter);

  const removeBenefit = async (id) => {
    const result = await Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente excluir o benefício?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    });

    if (result.isConfirmed) {
      try {
        const response = await api.delete(`/benefitcategory/${id}`);
        ShowMessages(response.data);
        setReload(true);
      } catch (error) {
        ShowMessages(error);
      }
    }
  };

  const editBenefit = async (id) => {
    try {
      const response = await api.get(`/benefitcategory/${id}`);
      setData(response.data);
      setEditState(true);
      setShowModal(true);
    } catch (error) {
      ShowMessages(error);
    }
  };

  return (
    <ContentWrapper>
      <Loading loading={loading} />
      <ModalBenefit
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        editState={editState}
        data={data}
        handleReload={setReload}
      />

      <div className={`container-lg container-100 ${loading ? 'hidden' : ''}`}>
        <div className="content-heading">
          <div className="box-maintenance-buttons">
            <button
              className="btn btn-success"
              disabled={!checkPermission(2, 7)}
              title="Insere um novo registro"
              onClick={() => {
                setEditState(false);
                setShowModal(true);
              }}
            >
              <i className="fa fa-plus"></i>
            </button>
            <button
              className="btn btn-primary"
              title="Atualizar Dados"
              style={{ marginLeft: '3px' }}
              onClick={() => setReload(true)}
            >
              <i className="fa fa-redo"></i>
            </button>
            {showButtons && (
              <>
                <button
                  className="btn btn-dark"
                  title="Impressão"
                  style={{ marginLeft: '3px' }}
                  onClick={() => setPrint(true)}
                >
                  <i className="fa fa-print"></i>
                </button>
                <button
                  className="btn btn-info"
                  disabled={!checkPermission(128, 7)}
                  title="Exportar para excel"
                  style={{ marginLeft: '3px' }}
                  onClick={() => setExcel(true)}
                >
                  <i className="fa fa-file-excel"></i>
                </button>
                <button
                  className="btn btn-secondary"
                  title="Filtros Avançados"
                  style={{ marginLeft: '3px' }}
                  onClick={handleFilter}
                >
                  <i className="fa fa-search-plus"></i>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`content-box-cart ${loading ? 'hidden' : ''}`}>
        <Filter
          isOpenFilter={isOpenFilter}
          filters={gridParams}
          callback={setFilters}
          callBackOpenFilter={handleFilter}
        />
        <Grid
          url="/benefitcategory"
          headers={gridParams}
          loading={setLoading}
          reload={reload}
          print={print}
          excel={excel}
          filters={filters}
          filename="Categoria de Benefícios"
          callbackShowButtons={setShowButtons}
          callbackExcel={setExcel}
          callbackPrint={setPrint}
          callbackReload={setReload}
          callbackCellClick={editPermission ? undefined : editBenefit}
          callbackButtons={[editBenefit, removeBenefit]}
        />
      </div>
    </ContentWrapper>
  );
};

export default BenefitCategory;
