import React, { useState } from 'react';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import Select from 'react-select';
import ModalScanner from '../modals/modal-scanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faGift,
  faCheck,
  faTrash,
  faPrint,
  faBarcode,
} from '@fortawesome/free-solid-svg-icons';
import { FaTimes } from 'react-icons/fa';

const CashRegister = () => {
  const [nameOrId, setNameOrId] = useState('');
  console.log(nameOrId);
  const [balance, setBalance] = useState(100);
  const [transactions, setTransactions] = useState([
    {
      type: 'Inclusão de saldo',
      date: '01/05/2023',
      source: 'Loja A',
      points: '+50',
      amount: '150,00',
    },
    {
      type: 'Resgate',
      products: [
        { name: 'Produto A', value: 'R$ 10,00' },
        { name: 'Produto B', value: 'R$ 20,00' },
        { name: 'Produto C', value: 'R$ 80,00' },
      ],
      date: '03/05/2023',
      source: 'Prêmio X',
      points: '-30',
      amount: '120,00',
    },
    {
      type: 'Inclusão de saldo',
      date: '05/05/2023',
      source: 'Loja B',
      points: '+20',
      amount: '140,00',
    },
  ]);
  const [user, setUser] = useState({
    name: 'João Lima',
    code: 'XXX.XXX',
    cpf: 'XXX.XXX.XXX-XX',
  });

  const [sliderVisible, setSliderVisible] = useState(false);
  const [redeemSliderVisible, setRedeemSliderVisible] = useState(false);
  const [amount, setAmount] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [redeemedProducts, setRedeemedProducts] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  const [scanResult, setScanResult] = useState('');

  const handleOpenScanner = () => setShowScanner(true);
  const handleCloseScanner = () => setShowScanner(false);

  const handleScanResult = (result) => {
    setScanResult(result);
  };

  const handleGetScanner = (scanResult) => {
    setNameOrId(scanResult);
    handleCloseScanner();
  };

  const handleScanError = (error) => {
    console.error(error);
    handleCloseScanner();
  };

  const products = [
    { name: 'Produto A', value: 30.0 },
    { name: 'Produto B', value: 50.0 },
    { name: 'Produto C', value: 20.0 },
  ];

  const productOptions = products.map((product) => ({
    value: product.name,
    label: `${product.name}`,
    product,
  }));

  const handleToggleSlider = (type) => {
    if (type === 'include') {
      setSliderVisible(true);
      setRedeemSliderVisible(false);
    } else if (type === 'redeem') {
      setSliderVisible(false);
      setRedeemSliderVisible(true);
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleAddBalance = () => {
    if (sliderVisible) {
      setBalance(balance + parseFloat(amount));
      setAmount('');
    } else if (redeemSliderVisible) {
      if (selectedProduct) {
        setRedeemedProducts([...redeemedProducts, selectedProduct.product]);
        setBalance(balance - selectedProduct.product.value);
        setSelectedProduct(null);
      }
    }
    // Fecha sliders após a operação
    setSliderVisible(false);
    setRedeemSliderVisible(false);
  };

  const handleAddProduct = () => {
    if (selectedProduct) {
      setRedeemedProducts([...redeemedProducts, selectedProduct.product]);
      setBalance(balance - selectedProduct.product.value);
      setSelectedProduct(null);
    }
  };

  const handleRemoveProduct = (index) => {
    const product = redeemedProducts[index];
    const updatedProducts = redeemedProducts.filter((_, i) => i !== index);
    setRedeemedProducts(updatedProducts);
    setBalance(balance + product.value);
  };

  const handlePrintStatement = () => {
    const printWindow = window.open('', '', 'height=600,width=800');

    // Define estilos inline para a impressão
    const style = `
      <style>
        @media print {
          .printable-container {
            width: 100%;
            font-family: Arial, sans-serif;
          }
          .printable-grid {
            width: 100%;
            border: 1px solid #000;
            border-collapse: collapse;
            margin-bottom: 1rem;
          }
          .printable-grid > div {
            border: 1px solid #000;
            padding: 8px;
            text-align: left;
          }
          .printable-grid > div > div {
            background-color: #f2f2f2;
            font-weight: bold;
          }
          .printable-grid > div:nth-child(even) {
            background-color: #f9f9f9;
          }
          .printable-grid .sub-items {
          margin: 1rem;
            margin-top: 0.5rem;
          }
          .printable-grid .sub-item {
            display: flex;
            flex-direction: row;
            padding: 4px 8px;
          }
        }
      </style>
    `;

    const content = `
      <html>
      <head>
        <title>Extrato</title>
        ${style}
      </head>
      <body>
        <div class="printable-container">
          <h4>Extrato</h4>
          <div class="printable-grid">
            <div style="display: flex; flex-direction: row;">
              <div style="flex: 1;">Operação</div>
              <div style="flex: 1;">Data</div>
              <div style="flex: 1;">Origem</div>
              <div style="flex: 1;">Pontos</div>
              <div style="flex: 1;">Saldo</div>
            </div>
            ${transactions
              .map(
                (transaction) => `
              <div style="display: flex; flex-direction: row;">
                <div style="flex: 1;">${transaction.type}</div>
                <div style="flex: 1;">${transaction.date}</div>
                <div style="flex: 1;">${transaction.source}</div>
                <div style="flex: 1;">${transaction.points}</div>
                <div style="flex: 1;">R$ ${transaction.amount}</div>
              </div>
              ${
                transaction.type === 'Resgate' && transaction.products
                  ? `
                <div class="sub-items">
                  ${transaction.products
                    .map(
                      (product) => `
                    <div class="sub-item">
                      <div style="flex: 1; margin-right: 8px;">${product.name}</div>
                      <div>${product.value}</div>
                    </div>
                  `,
                    )
                    .join('')}
                </div>
              `
                  : ''
              }
            `,
              )
              .join('')}
          </div>
        </div>
      </body>
      </html>
    `;

    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="container-fluid mt-4 border rounded shadow-sm">
      <div className="row">
        {/* Caixa Section */}
        <div className="col-md-6">
          <div className="p-2">
            <h4>Caixa</h4>
            <div className="mb-3">
              <label htmlFor="nameOrId" className="form-label">
                Nome/CPF/ID
              </label>
              <div>{nameOrId}</div>

              <div className="d-flex">
                <Select
                  id="nameOrId"
                  type="text"
                  value={nameOrId}
                  onChange={(e) => {
                    setNameOrId(e.target.value);
                  }}
                  placeholder="Digite o nome, CPF ou ID"
                  className="me-2"
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: '500px',
                    }),
                  }}
                />
                <Button
                  onClick={handleOpenScanner}
                  className="btn btn-info"
                  style={{ height: '2.5rem' }}
                >
                  <FontAwesomeIcon icon={faBarcode} />
                </Button>
              </div>
              <Modal show={showScanner} onHide={handleCloseScanner}>
                <Modal.Body>
                  <button
                    type="button"
                    onClick={handleCloseScanner}
                    style={{
                      border: 'none',
                      background: 'transparent',
                      position: 'absolute',
                      top: '-9px',
                      right: '-5px',
                      cursor: 'pointer',
                    }}
                  >
                    <FaTimes size={18} color="black" />
                  </button>
                  <ModalScanner
                    onResult={handleScanResult}
                    onError={handleScanError}
                    onGetResult={handleGetScanner}
                  />
                </Modal.Body>
              </Modal>
              <div className="bg-light text-black p-3 mt-3 rounded d-flex justify-content-between">
                <div className="text-start">
                  <p className="mb-1 fs-5">{user.name}</p>
                  <p className="mb-1">Código: {user.code}</p>
                  <p className="mb-0">CPF: {user.cpf}</p>
                </div>
                <div className="text-success text-end">
                  <h4>SALDO</h4>
                  <p className="mb-0 fs-3" style={{ fontSize: '1.5rem' }}>
                    {balance.toFixed(2).replace('.', ',')}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-3 d-flex">
              <button
                onClick={() => handleToggleSlider('include')}
                className="btn btn-primary w-50 me-2"
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                INCLUIR SALDO
              </button>
              <button
                onClick={() => handleToggleSlider('redeem')}
                className="btn btn-secondary w-50"
              >
                <FontAwesomeIcon icon={faGift} className="me-2" />
                RESGATAR SALDO
              </button>
            </div>
            {redeemSliderVisible && (
              <div className="mt-3">
                <InputGroup className="mb-3">
                  <div className="d-flex">
                    <Select
                      value={selectedProduct}
                      onChange={setSelectedProduct}
                      options={productOptions}
                      placeholder="Selecione um produto"
                      className="me-2"
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: '510px',
                        }),
                      }}
                    />
                    <Button
                      onClick={handleAddProduct}
                      className="btn btn-success"
                      style={{ height: '2.5rem' }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </div>
                </InputGroup>
                {redeemedProducts.length > 0 && (
                  <div
                    className="mb-1"
                    style={{ maxHeight: '80px', overflowY: 'auto' }}
                  >
                    <div className="row bg-light py-2 fw-bold">
                      <div className="col">Produto</div>
                      <div className="col">Valor</div>
                      <div className="col">Ações</div>
                    </div>
                    {redeemedProducts.map((product, index) => (
                      <div key={index} className="row py-2 border-bottom">
                        <div className="col">{product.name}</div>
                        <div className="col">
                          R$ {product.value.toFixed(2).replace('.', ',')}
                        </div>
                        <div className="col">
                          <Button
                            variant="danger"
                            size="sm"
                            className="mb-1"
                            onClick={() => handleRemoveProduct(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {sliderVisible && (
              <div className="mt-3">
                <InputGroup className="mb-3">
                  <FormControl
                    type="number"
                    placeholder="Digite o valor"
                    value={amount}
                    onChange={handleAmountChange}
                    min="0"
                    step="0.01"
                  />
                </InputGroup>
              </div>
            )}
            <div className="d-flex justify-content-center mt-4">
              <Button
                className="btn btn-success"
                onClick={handleAddBalance}
                style={{
                  height: '2.5rem',
                  borderTopLeftRadius: '0.375rem',
                  borderBottomLeftRadius: '0.375rem',
                }}
              >
                <FontAwesomeIcon icon={faCheck} className="me-2" />
                Confirmar a operação
              </Button>
            </div>
          </div>
        </div>

        {/* Extrato Section */}
        <div className="col-md-6 p-2">
          <h4>Extrato</h4>
          <div className="mb-3">
            <div className="row bg-secondary text-white py-2 fw-bold">
              <div className="col">Operação</div>
              <div className="col">Data</div>
              <div className="col">Origem</div>
              <div className="col">Pontos</div>
              <div className="col">Saldo</div>
            </div>
            {transactions.map((transaction, index) => (
              <div
                key={index}
                className={`row py-2 ${
                  index % 2 === 0 ? 'bg-light' : 'bg-white'
                } border-bottom`}
              >
                <div className="col">{transaction.type}</div>
                <div className="col">{transaction.date}</div>
                <div className="col">{transaction.source}</div>
                <div className="col">{transaction.points}</div>
                <div className="col">R$ {transaction.amount}</div>

                {transaction.type === 'Resgate' && transaction.products && (
                  <div className="col-12 ms-1" style={{ fontSize: '0.7rem' }}>
                    {transaction.products.map((product, idx) => (
                      <div className="d-flex" key={idx}>
                        <div className="me-2">{product.name}</div>
                        <div>{product.value}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <Button
            onClick={handlePrintStatement}
            className="btn btn-primary w-100"
          >
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Imprimir extrato
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CashRegister;
