import React, { useState, useEffect } from 'react';

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function ModalBuyIn({
  showModal,
  handleCloseModal,
  editState,
  data,
  handleReload,
}) {
  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState('');
  const [basemultiplier, setBasemultiplier] = useState('');

  const setData = (obj, clear) => {
    setDescription(clear ? '' : obj.buyin);
    setBasemultiplier(clear ? '' : obj.basemultiplier || '');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    loadingButton(
      true,
      document.querySelector('#submit-by-in'),
      document.querySelector('#submit-by-in').children[0].classList,
      document.querySelector('#submit-by-in').lastChild.nodeValue,
    );

    try {
      let _data = {
        buyin: description,
        basemultiplier: parseFloat(basemultiplier) || 0,
      };

      console.log('Description:', _data.buyin);
      console.log('Base Multiplier:', _data.basemultiplier);

      const request = editState
        ? api.put(`/buy-in/${data.idbuyin}`, _data)
        : api.post('/buy-in', _data);

      const response = await request;
      ShowMessages(response);

      if (response.ok && response.data.status === 200) {
        handleCloseModal();
        handleReload(true);
      } else {
        loadingButton(false, document.querySelector('#submit-by-in'));
      }
    } catch (error) {
      loadingButton(false, document.querySelector('#submit-by-in'));
      ShowMessages(error);
    }
  };

  useEffect(() => {
    if (editState) {
      setLoading(true);
      setData(data, false);
      setLoading(false);
    } else setData({}, true);
  }, [data, editState, showModal]);

  useEffect(() => {
    setStatistic('modals\\buy-in');
  }, []);

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm
        onSubmit={handleSubmit}
        setFocusOnError={true}
        className="modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-project-diagram"></i> Propriedades do Buy In
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Loading loading={loading} />
          <div className={`${loading ? 'hidden' : ''}`}>
            <div>
              <label>Descrição</label>
              <TextInput
                name="descriptionBuyIn"
                id="descriptionBuyIn"
                required
                errorMessage={{ required: 'Infome o jogo!' }}
                className="form-control "
                value={description}
                maxLength={50}
                autoComplete="off"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <label>Multiplicador Base (%)</label>
              <TextInput
                name="basemultiplier"
                id="basemultiplier"
                type="number"
                step="0.01"
                min="0"
                required
                errorMessage={{
                  required: 'Informe o multiplicador base!',
                  min: 'O valor não pode ser negativo!',
                }}
                className="form-control w-50"
                value={basemultiplier}
                autoComplete="off"
                onChange={(e) => setBasemultiplier(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-by-in"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
