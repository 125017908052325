import React, { memo, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CardBoardInfo from '../../components/Boards/CardBoardInfo';
import ShowMessages from '../../components/Exceptions';
import api from '../../services/api';
import ContentWrapper from '../layouts/contentWrapper';

const TablesOpened = () => {
  const reload = useSelector((state) => state.updateRequest.reload);
  const [board, setBoard] = useState([]);

  useEffect(() => {
    const getBoardOn = async () => {
      await api
        .get('/getAllOpenBoards')
        .then((response) => {
          if (response.ok) {
            setBoard(response.data);
          } else {
          }
        })
        .catch((error) => {
          ShowMessages(error);
        })
        .finally
        // setReload(false),
        // setLoading(false)
        ();
    };

    getBoardOn();
  }, [reload]);

  return (
    <ContentWrapper className="container-table-opened">
      <Container fluid>
        <Row>
          {board.length > 0 ? (
            board.map((item) => {
              return (
                <Col
                  key={item.idgame}
                  sm={12}
                  lg={3}
                  md={4}
                  style={{ height: 'fit-content' }}
                >
                  <CardBoardInfo
                    name={item.board}
                    players={item.players}
                    maxplayers={item.maxplayers}
                    gametype={item.gametype}
                    startGame={item.startgame}
                    idGame={item.idgame}
                    idBoard={item.idboard}
                  />
                </Col>
              );
            })
          ) : (
            <div>
              <div
                className="bg bg-warning"
                style={{
                  padding: '15px',
                  borderRadius: '5px',
                  fontSize: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}
              >
                <i className="fa fa-face-frown" />
                <span style={{ marginLeft: '5px' }}>
                  {' '}
                  Nenhuma mesa aberta no momento!
                </span>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </ContentWrapper>
  );
};

export default memo(TablesOpened);
