import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalUserGroup from '../modals/modal-user-group';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from './../../components/Exceptions'
import setStatistic from './../../services/apiStatistic'
import {checkPermission} from './../../components/security'
import Swal from 'sweetalert2'

export default function UserGroup() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [print, setPrint]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])

    const [editPermission, setEditPermisson]               = useState(false)

    const getGroupParams = async() => {
        let _delCheck  = !checkPermission(4,8)
        let _editCheck = !checkPermission(8,8)
        let _inatCheck = !checkPermission(64,8)
        let _permCheck = !checkPermission(16,8)

        setEditPermisson(_editCheck)
        await api.get('/groupstatus')
                 .then(response => {
                    setGridParams({idusergroup : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                               value : '00000'},
                                                  primaryKey  : true                          },
                                   usergroup   : {text        : "Nome",
                                                  searchDefault : true},
                                   status      : {text        : "Status",
                                                  className   : "text-center",
                                                  item        : {className : "text-center",
                                                                 switch    : {data  : response.data,
                                                                 key       : 'idgroupstatus'}}},
                                   Options     : {text        : "Opções",
                                                  className   : "text-center grid-option-300",
                                                  order       : false,
                                                  filter      : false,
                                                  item        : [{type  : "Custom",
                                                                  props : {tag            : "button",
                                                                           title          : "Exclusão de Grupo de Usuários",
                                                                           className      : 'btn btn-sm btn-danger',
                                                                           icon           : "fa fa-trash",
                                                                           disabled       : _delCheck,
                                                                           indexcallback  : 0}},
                                                                 {type  : "Custom",
                                                                  props : {tag            : "button",
                                                                           title          : "Editar grupo de usuário",
                                                                           className      : 'btn btn-sm btn-warning',
                                                                           disabled       : _editCheck,
                                                                           icon           : "fa fa-edit",
                                                                           indexcallback  : 1}},
                                                                 {type  : "Custom",
                                                                  props : {tag            : "button",
                                                                           title          : "Ativar grupo de usuário",
                                                                           className      : 'btn btn-sm btn-dark',
                                                                           icon           : "fa fa-user-check",
                                                                           disabled       : _inatCheck,
                                                                           visualConditional : {idgroupstatus : {operator : 'not in',
                                                                                                                 values : [0]}},
                                                                           indexcallback  : 2}},
                                                                 {type  : "Custom",
                                                                           props : {tag   : "button",
                                                                           title          : "Inativar grupo de usuário",
                                                                           className      : 'btn btn-sm btn-secondary',
                                                                           icon           : "fa fa-user-times",
                                                                           disabled       : _inatCheck,
                                                                           visualConditional : {idgroupstatus : {operator : 'not in',
                                                                                                                 values : [1]}},
                                                                           indexcallback  : 2}},
                                                                 {type  : "Custom",
                                                                  props : {tag            : "button",
                                                                           title          : "Permissões do grupo de usuário",
                                                                           disabled       : _permCheck,
                                                                           className      : 'btn btn-sm btn-success padding0',
                                                                           icon           : "fa fa-users-cog ",                                                           
                                                                           indexcallback  : 3}}]}             
                    })
                })
                .catch( error => { 
                    ShowMessages(error)
                })
    }

    useEffect(() => {
        getGroupParams()
        setStatistic('maintenance\\user-group')
    },[])
    
    const handleCloseModal = () => {setShowModal(false)}
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    const InactiveGroup    = async (id) => {   
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente inativar o grupo de usuário?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/usergroup/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => { 
                            ShowMessages(error)
                         })
            }
            
        })
    }

    const Permissions = async(id, value) => {
        let props = {
            id 
        }
        window.addTab(null, value.toUpperCase() + ' | Permissões de Grupo', 'fa fa-users-cog', '/usergroup/permissions',props,0,true)
    }

    const RemoveGroup = async (id) => {      
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o grupo de usuário?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/usergroup/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => { 
                            ShowMessages(error)
                         })
            }
            
        })
    }

    const EditGroup = async(id) => {
        await api.get('/usergroup/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalUserGroup showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" disabled={!checkPermission(2,8)} title="Insere um novo registro" onClick={e => setShowModal(true)}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? (
                                <>
                                    <button className="btn btn-dark" title="Impressão" style={{marginLeft:"3px"}} onClick={e => setPrint(true)}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(128,8)} title="Exportar para excel" style={{marginLeft:"3px"}} onClick={e => setExcel(true)}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button></>)
                            : ""}    
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/usergroup' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Grupos de Usuários"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackCellClick   = {editPermission ? undefined : EditGroup}
                      callbackButtons     = {[RemoveGroup,
                                              EditGroup,
                                              InactiveGroup,
                                              Permissions]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}