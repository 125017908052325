import { differenceInSeconds, addHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import User from '../../assets/user.png';
import { BoardPosUsers } from '../../components/game/BoardPosUsers';
import api from '../../services/api';
import ContentWrapper from '../layouts/contentWrapper';

import ModalAddPlayer from '../modals/modal-add-player';
import ModalChangeDealer from '../modals/modal-change-dealer';
import ModalCloseBoard from '../modals/modal-close-board';
import ModalDeletePlayer from '../modals/modal-del-player';
import ModalPlayGame from '../modals/modal-play-game';

import ShowMessages from './../../components/Exceptions';
// import setStatistic from './../../services/apiStatistic';
// import {checkPermission} from './../../components/security';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading';
import {
  ReadDataStorage,
  REGISTRATION,
  WriteDataStorage,
} from '../../services/auth';
import {
  closeDealerModal,
  closeDelPlayerModal,
  closePlayerModal,
} from '../../store/slices/posBoardPlayerSlice';


const DEALER = { id: 'dealer', name: 'Dealer' };

export default function GameDealer(props) {
  const dispatch = useDispatch();
  const showPlayerModal = useSelector(
    (state) => state.posBoard.showModalAddPlayer,
  );
  const showModalDelPlayer = useSelector(
    (state) => state.posBoard.showModalDelPlayer,
  );
  const showModalDealer = useSelector(
    (state) => state.posBoard.showModalDealer,
  );

  const [screenHeigth, setScreenHeigth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalCloseBoard, setShowModalCloseBoard] = useState(false);

  const [idgame, setIdGame] = useState(0);
  const [players, setPlayers] = useState([]);

  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  const [boardData, setBoardData] = useState({});
  const [playerAction, setPlayerAction] = useState({
    pos: -1,
    action: '',
    id: 0,
    name: '',
  });

  const handleClosePlayerModal = () => {
    dispatch(closePlayerModal());
  };
  const handleCloseDelPlayerModal = () => {
    dispatch(closeDelPlayerModal());
  };
  const handleCloseDealerModal = () => {
    dispatch(closeDealerModal());
  };

  const handleCloseBoardModal = () => {
    setShowModalCloseBoard(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleReload = () => {
    setReload((s) => !s);
  };

  const handlePositionPlayer = (pos, action) => {
    setPlayerAction({ ...playerAction, pos: pos, action: action });
  };

  useEffect(() => {
    const getBoardOn = async () => {
      await api
        .get(`/getTables`)
        .then((response) => {
          if (response.ok) {
            const databoard = response.data;
            if (databoard.length !== 0) {
              if (databoard.endgame === null) {
                setIdGame(databoard.idgame);
                setBoardData({
                  boardName: databoard.board,
                  boardTime: databoard.startgame,
                });

                let arrPlayers = [];
                api
                  .post('/getBoardPlayers', { idgame: databoard.idgame })
                  .then((_response) => {
                    const dataBoardPlayers = _response.data;
                    for (let i = 0; i < databoard.maxplayers; i++) {
                      let objPlayer = {
                        id: i,
                        isOn: false,
                        name: '',
                        time: '',
                        idUser: -1,
                        url: '',
                      };

                      dataBoardPlayers?.forEach((player) => {
                        if (
                          player.boardposition === i &&
                          player.endgame === null
                        ) {
                          objPlayer.idUser = player.idgamesplayers;
                          objPlayer.isOn = true;
                          objPlayer.time = player.startgame;
                          objPlayer.name = player.player.player;
                          objPlayer.url = User;
                        }
                      });

                      arrPlayers.push(objPlayer);
                    }

                    setPlayers(arrPlayers);
                    setLoading(false);
                  })
                  .catch((error) => {
                    ShowMessages(error);
                  });
              } else {
                setShowModal(true);
                setPlayers([]);
                setBoardData({});
              }
            } else {
              setPlayers([]);
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          ShowMessages(error);
        });
    };
    getBoardOn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, playerAction]);

  //Retorna o tempo que a mesa está aberta
  useEffect(() => {
    const interval = setInterval(() => {
      let timeInSeconds = differenceInSeconds(
        new Date(),
        addHours(
          new Date(boardData.boardTime),
          process.env.REACT_APP_DIFERENCE_TIME,
        ),
      );
      console.log(boardData)

      let seconds =
        Math.floor(timeInSeconds) % 60 < 10
          ? `0${Math.floor(timeInSeconds) % 60}`
          : Math.floor(timeInSeconds) % 60;
      let minutes =
        Math.floor(timeInSeconds / 60) % 60 < 10
          ? `0${Math.floor(timeInSeconds / 60) % 60}`
          : Math.floor(timeInSeconds / 60) % 60;
      let hours =
        Math.floor(timeInSeconds / 3600) < 10
          ? `0${Math.floor(timeInSeconds / 3600)}`
          : Math.floor(timeInSeconds / 3600);

      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);
    return () => clearInterval(interval);
  }, [boardData]);

  useEffect(() => {
    function handleWindowResize() {
      setScreenHeigth(window.screen.height);
      setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Logout = async () => {
    Swal.fire({
      title: 'Confirmação',
      text: 'Deseja realmente sair do sistema?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api
          .post('/logout')
          .then(() => {
            let _registration = ReadDataStorage(REGISTRATION);
            localStorage.clear();
            WriteDataStorage(REGISTRATION, _registration);
            window.location.href = '/login';
          })
          .catch((error) => {
            ShowMessages(error);
          });
      }
    });
  };
  return (
    <>
      <Loading loading={loading} />
      {!loading && players.length === 0 && (
        <ContentWrapper className="game-container-dealer content-wrapper-no-overflow position-relative m-0">
          <ModalPlayGame
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            handleReloadBoard={handleReload}
          />

          <div className="start-game d-flex flex-column">
            <div className="start-game-img"></div>
            <div className="d-flex justify-content-center align-items-start">
              <button
                type="button"
                className="btn-play-game btn btn-success btn-lg my-5"
                onClick={() => setShowModal(true)}
              >
                <i className="fa fa-play" /> Iniciar Jogo
              </button>
            </div>
          </div>
        </ContentWrapper>
      )}
      {!loading && players.length !== 0 && (
        <ContentWrapper className="game-container-dealer content-wrapper-no-overflow position-relative p-0">
          <ModalAddPlayer
            idgame={idgame}
            showModal={showPlayerModal}
            handleCloseModal={handleClosePlayerModal}
            handlePositionPlayer={handlePositionPlayer}
            handleReload={handleReload}
          />

          <ModalChangeDealer
            idgame={idgame}
            showModal={showModalDealer}
            handleCloseModal={handleCloseDealerModal}
            handleReload={handleReload}
          />

          <ModalDeletePlayer
            idGame={idgame}
            showModal={showModalDelPlayer}
            handleReload={handleReload}
            handleCloseModal={handleCloseDelPlayerModal}
            handlePositionPlayer={handlePositionPlayer}
          />

          <ModalCloseBoard
            idGame={idgame}
            showModal={showModalCloseBoard}
            handleReloadReq={handleReload}
            handleCloseModal={handleCloseBoardModal}
            idTab={props.id}
          />

          <div
            className="board-container mx-auto d-flex align-items-center"
            style={{ height: +window.screen.height }}
          >
            <div className="game-table">
              {<BoardPosUsers players={players} dealer={DEALER} />}

              <div className="float-btn-close">
                <button
                  className="btn  d-none d-md-block d-lg-block d-xl-block"
                  onClick={() => {
                    setShowModalCloseBoard(true);
                  }}
                >
                  <em
                    style={{ color: 'white', width: '100%' }}
                    className={`fas fa-circle-xmark fa-lg`}
                  ></em>
                </button>
                <button
                  className="btn d-md-none"
                  onClick={() => {
                    setShowModalCloseBoard(true);
                  }}
                >
                  <em
                    style={{ color: 'white', width: '100%' }}
                    className={`fas fa-circle-xmark fa-lg`}
                  ></em>
                </button>
              </div>

              <div className="float-btn-eye">
                {/* <button className="btn  d-none d-md-block d-lg-block d-xl-block" onClick={() => {toggleAside()}}> */}
                <button
                  className="btn  d-none d-md-block d-lg-block d-xl-block"
                  onClick={() => {}}
                >
                  <a
                    title="Sair do Sistema"
                    onClick={(e) => Logout()}
                    className="nav-link"
                    style={{ textAlign: 'right' }}
                  >
                    <em
                      style={{ color: 'white' }}
                      className={`fas fa-right-from-bracket fa-lg`}
                    ></em>
                  </a>
                </button>
                <button className="btn d-md-none" onClick={() => {}}>
                  <a
                    title="Sair do Sistema"
                    onClick={(e) => Logout()}
                    className="nav-link"
                    style={{ textAlign: 'right' }}
                  >
                    <em
                      style={{ color: 'white' }}
                      className={`fas fa-right-from-bracket fa-lg`}
                    ></em>
                  </a>
                </button>
              </div>

              <div
                style={{
                  justifyContent: 'flex-end',
                  padding: '5px',
                  position: 'absolute',
                  borderRadius: '5px',
                  border: '1px solid #fff',
                  zIndex: '200000',
                  display: 'flex',
                  color: 'rgb(255, 255, 255)',
                  top: '10px',
                  fontSize: '8px',
                  right: '5px',
                  flexDirection: 'column',
                }}
              >
                <span style={{ color: '#fff' }}>{boardData.boardName}</span>
                <span style={{ color: '#fff' }}>
                  Tempo de Jogo: {hours}:{minutes}:{seconds}
                </span>
                <span style={{ color: '#fff' }}>
                  R: {screenWidth}x{screenHeigth}
                </span>
              </div>
            </div>
          </div>
        </ContentWrapper>
      )}
    </>
  );
}
