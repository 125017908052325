import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userType: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    handleUserType: (state, action) => {
        state.userType = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
                handleUserType, 
              } = userSlice.actions

export default userSlice.reducer