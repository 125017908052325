import React, { useState, useEffect } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from './../../components/Exceptions';
import setStatistic from './../../services/apiStatistic';
import AsyncSelect from 'react-select/async';
import { Button } from 'reactstrap';
import Draggable from 'react-draggable';
import Loading from '../../components/Loading';

import { removeAcento } from '../../common/utils';

import zxcvbn from 'zxcvbn';
import PasswordStrengthBar from 'react-password-strength-bar';

import {
  ValidationForm,
  SelectGroup,
  TextInput,
} from 'react-bootstrap4-form-validation';
import api from '../../services/api';
import loadingButton from './../layouts/Buttons';

class DraggableModalDialog extends React.Component {
  render() {
    return (
      <Draggable handle=".modal-header">
        <ModalDialog {...this.props} />
      </Draggable>
    );
  }
}

export default function User({ showModal, handleCloseModal, handleReload }) {
  const [loading, setLoading] = useState(false);

  const [employeer, setEmployeer] = useState('');
  const [userGroup, setUserGroup] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [updatepassword, setUpdatePassword] = useState(false);
  const [registration, setRegistration] = useState('');

  const [dataEmployeer, setDataEmployeer] = useState([]);
  const [dataUserGroup, setDataUserGroup] = useState([]);

  const matchPassword = (value) => {
    return value && value === password;
  };

  const setData = () => {
    setEmployeer('');
    setUserGroup('');
    setPassword('');
    setConfirm('');
    setRegistration('');
  };

  useEffect(() => {
    setData();
    getData();
  }, [showModal]);

  async function handleSubmit(event) {
    event.preventDefault();
    const score = zxcvbn(password);

    if (score.score < 3) {
      ShowMessages({
        status: 500,
        message: 'A senha não atende os requisitos mínimos de segurança!',
      });
      return;
    }

    loadingButton(
      true,
      document.querySelector('#submit-user'),
      document.querySelector('#submit-user').children[0].classList,
      document.querySelector('#submit-user').lastChild.nodeValue,
    );
    try {
      let _data = {
        employeer,
        userGroup,
        password,
        updatepassword,
        registration,
      };

      await api
        .post('/user', _data)
        .then((response) => {
          ShowMessages(response);
          if (response.ok) {
            if (response.data.status === 200) {
              handleCloseModal();
              handleReload(true);
            } else loadingButton(false, document.querySelector('#submit-user'));
          } else loadingButton(false, document.querySelector('#submit-user'));
        })
        .catch((error) => {
          loadingButton(false, document.querySelector('#submit-user'));
          ShowMessages(error);
        });
    } catch (response) {
      loadingButton(false, document.querySelector('#submit-user'));
      ShowMessages(response);
    }
  }

  const loadOptions = (input, callback) => {
    setTimeout(() => {
      callback(
        dataEmployeer.filter(({ label }) =>
          removeAcento(label.toLowerCase()).includes(input.toLowerCase()),
        ),
      );
    }, 200);
  };

  const checkValidate = (obj) => {
    let element = document.querySelector('#dv-employee').classList;
    if (obj === null || obj === '' || obj === 0) {
      element.remove('search-valid');
      element.add('search-invalid');
    } else {
      element.remove('search-invalid');
      element.add('search-valid');
    }
  };

  const getData = async () => {
    setLoading(true);
    await api
      .get('/getUserData')
      .then((response) => {
        if (response.ok) {
          setDataEmployeer(response.data.employeer);
          setDataUserGroup(response.data.usergroup);
          setRegistration(response.data.registration);
          setLoading(false);
        }
      })
      .catch((error) => {
        ShowMessages(error);
      });
  };

  useEffect(() => {
    setStatistic('modals\\modal-user');
  }, []);

  return (
    <Modal
      dialogClassName="width500"
      show={showModal}
      onHide={handleCloseModal}
      animation={true}
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: '0px' }}>
            <i className="fa fa-user-check"></i> Propriedades do Usuário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading loading={loading} addClass="heigth230px" />
          <div className={`heigth230px ${loading ? 'hidden' : ''}`}>
            <div>
              <div id="dv-employee" className="mb-2">
                <div className="input-group">
                  <label>Colaborador</label>
                  <AsyncSelect
                    options={dataEmployeer}
                    ignoreCase
                    required
                    ignoreAccents
                    value={employeer}
                    loadOptions={loadOptions}
                    onChange={(value) => {
                      setEmployeer(value === null ? 0 : value);
                      checkValidate(value === null ? '' : value);
                    }}
                    placeholder="Digite para pesquisar..."
                    noOptionsMessage={() => 'Não encontrado'}
                  />
                </div>
                <div className="invalid-feedback">
                  Por favor, informe um colaborador
                </div>
              </div>

              <div className=" mb-2">
                <label>Grupo de Usuários</label>
                <SelectGroup
                  name="usergroup"
                  id="usergroup"
                  placeholder="Selecione"
                  required
                  errorMessage={{
                    required: 'Por favor, informe um grupo de usuários!',
                  }}
                  className="form-select"
                  value={userGroup}
                  onChange={(e) => setUserGroup(parseInt(e.target.value))}
                >
                  <option value="">Selecione</option>
                  {dataUserGroup.map((data, id) => {
                    return (
                      <option key={id} value={data.value}>
                        {data.label}
                      </option>
                    );
                  })}
                </SelectGroup>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '33%' }}>
                <label>Matrícula</label>
                <TextInput
                  name="registration"
                  id="registration"
                  required
                  errorMessage={{
                    required: 'Por favor, informe uma matrícula!',
                  }}
                  className="form-control "
                  value={registration}
                  maxLength={25}
                  autoComplete="off"
                  onChange={(e) =>
                    setRegistration(removeAcento(e.target.value).toUpperCase())
                  }
                />
              </div>
              <div style={{ marginLeft: '15px', width: '33%' }}>
                <label>Senha.</label>
                <TextInput
                  name="password"
                  id="password"
                  type="password"
                  required
                  errorMessage={{
                    required: 'Por favor, informe sua senha!',
                    pattern: 'A senha devem conter pelo menos 6 dígitos.',
                  }}
                  value={password}
                  style={{ flex: ' 0 0' }}
                  pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                  className="form-control "
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                  maxLength={16}
                />
              </div>
              <PasswordStrengthBar
                password={password}
                shortScoreWord="Muito curta"
                scoreWords={['Muito Fraca', 'Fraca', 'OK', 'Boa', 'Muito Boa']}
              />
              <div style={{ marginLeft: '15px', width: '33%' }}>
                <label>Confirme a senha.</label>
                <TextInput
                  name="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  style={{ flex: ' 0 0' }}
                  required
                  errorMessage={{
                    required: 'Por favor, confirme sua senha!',
                    validator: 'As senhas informadas não conferem.',
                    pattern: 'A senha devem conter pelo menos 6 dígitos.',
                  }}
                  value={confirm}
                  pattern="(?=.*[A-Z,0-9,!@#$%&*()-=+,.;:/}{\|}]).{6,}"
                  validator={matchPassword}
                  className="form-control "
                  onChange={(e) => setConfirm(e.target.value)}
                  autoComplete="off"
                  maxLength={16}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <label
                className="switch switch-sm"
                style={{ justifyContent: 'center' }}
              >
                <input
                  type="checkbox"
                  checked={updatepassword}
                  value={updatepassword}
                  onClick={(e) => setUpdatePassword(!updatepassword)}
                />
                <span style={{ marginTop: '5px' }}></span>
              </label>
              <label style={{ marginLeft: '5px' }}>
                Alterar a senha no próximo login?
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            id="submit-user"
            className={`btn-theme ${loading ? 'hidden' : ''}`}
            onClick={(e) => checkValidate(employeer)}
          >
            <em className="fa fa-save"></em> Salvar
          </Button>
          <Button className="btn-ligth-gray" onClick={handleCloseModal}>
            <em className="fa fa-door-closed"></em> Fechar
          </Button>
        </Modal.Footer>
      </ValidationForm>
    </Modal>
  );
}
