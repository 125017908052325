import React, { useEffect, useState } from 'react';
import Filter from '../../components/Filter';
import Grid from '../../components/Grid';
import Loading from '../../components/Loading';
import api from '../../services/api';
import ContentWrapper from '../layouts/contentWrapper';
import ModalHistorieTable from '../../pages/modals/modal-historie-table';
import { checkPermission } from '../../components/security';
import setStatistic from '../../services/apiStatistic';
import { format, startOfWeek  }        from 'date-fns'

export default function ReportTables() {
    const [loading, setLoading]                            = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [filters, setFilters]                            = useState([{ field      : 'startgame',
                                                                        key        : 'between',  
                                                                        value      : format(startOfWeek(new Date()),'yyyy-MM-dd'),
                                                                        value2     : format(startOfWeek(new Date()),'yyyy-MM-dd'),
                                                                        ignoretime : true    
                                                                        }])

    const [startDate, setStartDate]                           = useState(format(startOfWeek(new Date()),'yyyy-MM-dd'))
    const [endDate, setEndDate]                               = useState(format(new Date(),'yyyy-MM-dd'))
    const [fieldSearch,setFieldSearch]                        = useState('startgame')

    const [idgame, setIdGame]                                 = useState(0)
    const [showModal, setShowModal]                           = useState(false)                                                                                  

    const getData = async() => {
        setGridParams({
            idgame     : {text       : "",
                          primaryKey : true,
                          visible    : false},
            startgame  : {text       : "Inicio"},
            endgame    : {text       : "Fim"},
            board      : {text       : "Mesa"},
            gametype   : {text       : "GameType"},
            buyin      : {text       : "BuyIn"},
            tablesize  : {text       : "TableSize"},
            name       : {text       : "Usuário"},
            maxplayers : {text       : "Max. Players"},
            Options    : {text       : "Opções",
                          className   : "text-center",
                          order       : false,
                          filter      : false,
                          item        : [{type  : "Custom",
                                          props : {tag               : "button",
                                                   title             : "Visualizar Jogadores",
                                                   className         : 'btn btn-sm btn-success',
                                                   icon              : "fa fa-search",
                                                   indexcallback     : 0}}]}
                    
        })              
    }

    useEffect(() => {
        getData()
        handleDateFilter()
        setStatistic('maintenance\\reports-tables')
    },[])
    
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}
    const handleDateFilter = () => {
        let _filters = [{ field      : fieldSearch,
                          key        : 'between',  
                          value      : startDate,
                          value2     : endDate,
                          ignoretime : true    
                        }]
         
        setFilters(_filters)
    }

    const handleClose = () => {setShowModal(false)}
    const showPlayers = (id) => {
        setIdGame(id)
        setShowModal(true)
    }

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>
            <ModalHistorieTable idGame={idgame} showModal={showModal} handleCloseModal = {handleClose} />

            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div  className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,4)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                        <div style={{display:"flex", marginLeft:"25px", borderLeft:"1px dashed #cacaca"}}>
                            <div style={{marginLeft:"15px"}}>
                                <label>Período</label>
                                <select className="form-select" onChange={e => setFieldSearch(e.target.value)}>
                                    <option value={'create_at'}>Data da Criação</option>
                                    <option value={'startgame'}>Data da Entrada</option>
                                    <option value={'endgame'}>Data da Saída</option>
                                </select>
                            </div>
                            <div style={{marginLeft:"15px"}}>
                                <label>Data Inicial</label>
                                <input type="date" className="form-control" value={startDate} onChange={e => setStartDate(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px"}}>
                                <label>Data Final</label>
                                <input type="date" className="form-control" value={endDate} onChange={e => setEndDate(e.target.value)}/>
                            </div>
                            <div style={{marginLeft:"15px", display:"flex", alignItems:"center", marginTop:"12px"}}>
                                <button type="button" className="btn btn-success" onClick={e => handleDateFilter(e)}><i class="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                        <div style={{display:"flex", marginLeft:"25px", borderLeft:"1px dashed #cacaca"}}>

                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/reportTables' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      callbackCellClick   = {showPlayers}
                      filename            = "Relatório de Mesas"
                      callbackButtons     = {[showPlayers]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}