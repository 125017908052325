/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import api from '../../services/api';
// import setStatistic from '../../services/apiStatistic'
import loadingButton from '../layouts/Buttons';
import Swal from 'sweetalert2';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

 function ModalDonuzTournament({
    data,
    showModal, 
    handleCloseModal,
    }) {


    const [players, setPlayers]         = useState([])
    const [name, setName]               = useState('')
    const [loading, setLoading]         = useState(false)
    const [showBP, setShowBP]           = useState(false)
    const [showBI, setShowBI]           = useState(false)
    const [showAD, setShowAD]           = useState(false)
    

    useEffect(() => {
        let _aux  = data
        if (name.length > 0) {
            _aux = _aux.filter(_item => {
                return _item[3].toLowerCase().indexOf(name.toLowerCase()) >= 0 
            })
        }

        
        if ((showBP) || (showBI) || (showAD)) {
            let idxBP = _aux[0].indexOf('BP')
            let idxAD = _aux[0].indexOf('AD')
            let idxBI = _aux[0].indexOf('BI')

            _aux = _aux.filter(_item => {
                let i = 0
                if ((idxBP >= 0) && (showBP)) {
                    i += parseInt(_item[idxBP])
                }
                if ((idxAD >= 0) && (showAD)) {
                    i += parseInt(_item[idxAD])
                }
                
                if ((idxBI >= 0) && (showBI)){
                    i += parseInt(_item[idxBI])
                }
  
                return i > 0
            })   
            console.log(_aux.length) 
        }
        setPlayers(_aux)
    },[showBP,showBI,showAD, name])


    useEffect(() => {
        setName('')
        setPlayers(data)
    },[data])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
                <Modal.Header closeButton>
                    <Modal.Title className="mb-0" >
                        <i className="fa fa-list-check"></i> Participantes do Torneio
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth200px"/>
                    <div style={{display:"flex"}}>
                        <div style={{width:"100%"}}>
                            <label>
                                Digite o nome para pesquisar...
                            </label>
                            <input className='form-control' value={name} onChange={e => setName(e.target.value)}></input>
                        </div>
                        <div style={{marginLeft:"15px"}}>
                            <div>
                                <label style={{width:"fit-content", background:"#fff"}}>Tipos</label>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", marginTop:"7px"}}>                 
                                <div style={{display:"flex", border:"1px solid #cacaca", padding:"6px", borderRadius:"5px", marginTop:"-7px"}}>
                                    <div style={{display:"flex"}}>
                                        <label className="switch switch-sm">
                                            <input type="checkbox" checked={showBP} value={showBP} onClick={e => setShowBP(!showBP)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "2px"}}>BP</span>
                                    </div>  
                                    <div style={{display:"flex", marginLeft:"20px"}}>
                                        <label className="switch switch-sm">
                                            <input type="checkbox" checked={showAD} value={showAD} onClick={e => setShowAD(!showAD)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "2px"}}>AD</span>
                                    </div>
                                    <div style={{display:"flex", marginLeft:"20px"}}>
                                        <label className="switch switch-sm">
                                            <input type="checkbox" checked={showBI} value={showBI} onClick={e => setShowBI(!showBI)}  />
                                            <span></span>
                                        </label>
                                        <span style={{marginLeft: "2px"}}>BI</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={` row ${loading ? "hidden" : ""}`} style={{height:"300px", overflowY:"auto", marginTop:"15px"}}>  
                        {players && players.map((_player, id) => {
                            if (_player[3] === 'Nome')
                                return 

                            return(
                                    <div style={{display:"flex", borderBottom:"1px dashed #cacaca", paddingBottom:"7px", marginBottom:"7px"}}>
                                        <div style={{marginLeft:"5px", width:"350px", fontSize:"12px"}}>
                                            <div><span style={{fontWeight:"bold"}}>{data[0][3]}:</span> {_player[3]}</div>
                                            <div><span style={{fontWeight:"bold"}}>{data[0][1]}:</span> {_player[1]}</div>
                                        </div>
                                        <div style={{marginLeft:"15px"}}>
                                            <div><span style={{fontWeight:"bold"}}>{data[0][4]}:</span> {_player[4]}</div> 
                                            <div><span style={{fontWeight:"bold"}}>{data[0][5]}:</span> {_player[5]}</div> 
                                            <div><span style={{fontWeight:"bold"}}>{data[0][6]}:</span> {_player[6]}</div> 
                                            <div><span style={{fontWeight:"bold"}}>{data[0][7]}:</span> {_player[7]}</div> 
                                        </div>
                                        <div style={{marginLeft:"15px"}}>
                                            <div><span style={{fontWeight:"bold"}}>{data[0][8]}:</span> {_player[8]}</div> 
                                            {data[0][9] !== "" ? 
                                                <div><span style={{fontWeight:"bold"}}>{data[0][9]}:</span> {_player[9]}</div> 
                                            : ""}
                                            {data[0][10] !== "" ? 
                                                <div><span style={{fontWeight:"bold"}}>{data[0][10]}:</span> {_player[10]}</div> 
                                            : ""}
                                            {data[0][11] !== "" ?
                                                <div><span style={{fontWeight:"bold"}}>{data[0][11]}:</span> {_player[11]}</div> 
                                            :''}
                                        </div>
                                        <div style={{marginLeft:"15px"}}>
                                            <div><span style={{fontWeight:"bold"}}>{data[0][13]}:</span> {_player[13]}</div> 
                                            <div><span style={{fontWeight:"bold"}}>{data[0][14]}:</span> {_player[14]}</div>
                                            {data[0][15] !== "" ? 
                                                <div><span style={{fontWeight:"bold"}}>{data[0][15]}:</span> {_player[15]}</div>
                                            : ''}
                                            {data[0][16] !== "" && data[0][16] !== undefined? 
                                                <div><span style={{fontWeight:"bold"}}>{data[0][16]}:</span> {_player[16]}</div>
                                            : ''}
                                        </div>
                                        <div style={{marginLeft:"15px", alignItems:"center", display:"flex"}}>
                                            <div>
                                                {(4 === null) ?
                                                    <button type="button"  className='btn btn-sm btn-warning'><i className='fa fa-person-walking-arrow-right'></i></button>
                                                :
                                                    ''}
                                            </div>
                                        </div>
                                    </div>  
                                )
                        })}             
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <div className='d-flex w-100' style={{justifyContent:"flex-end"}}>
                        <div >
                            <Button  className="btn-ligth-gray" onClick={handleCloseModal}>
                                <em className="fa fa-door-closed"></em> Fechar
                            </Button>
                        </div> 
                    </div>

                </Modal.Footer>
        </Modal>
    )
}

export default memo(ModalDonuzTournament)