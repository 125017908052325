import { DeCrypt, Crypt } from "../components/security"

export const TOKEN_KEY    = "@kernelsystem-token"
export const USER_DATA    = "@kernelsystem-user"
export const ADMIN_DATA   = "@kernelsystem"
export const IDCOMPANY    = "@cfokrtgbl"
export const COMPANY      = '@flkgbmrot'
export const IP           = '@pprbjrjrt'
export const USERNAME     = '@tphiuennh'
export const IDUSER       = '@nmrocurph'
export const REGISTRATION = '@Mqzdborpb'
export const CONTRACT     = '@Dv8r454Rb'
export const EXPIRES_IN   = '@t5RqQx-Eg'

//CHAT
export const IDSTATUS     = '@lotbirkg'

export const getToken         = () => ReadDataStorage(TOKEN_KEY)
export const getUserData      = () => {
    if (ReadDataStorage(USER_DATA))
        return JSON.parse(ReadDataStorage(USER_DATA))
    else
        return null    

}

export const ReadDataStorage  = (variable)        => DeCrypt(localStorage.getItem(variable) || '')
export const WriteDataStorage = (variable, value) => (localStorage.setItem(variable,Crypt(value.toString())))
export const isAuthenticated  = ()                => ReadDataStorage(TOKEN_KEY) !== ''


