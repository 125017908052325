import { create } from 'apisauce'

export const removeAcento = (text) => {       
    text = text.toLowerCase();                                                         
    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    return text;                 
}

export default function imageExists (image_url) {
    const api = create({
        baseURL : process.env.REACT_APP_IP_BACKEND + ':' + process.env.REACT_APP_PORT_BACKEND,
        headers: {
            "Access-Control-Allow-Origin": "*"
        }
    })


    return api.get(image_url)
              .then(request => {
                    return true
              })
              .catch(err => {
                    return false
              })

}