import React, { memo } from 'react';
import DragndropContainer from './dragndropContainer';
import Person from './player';
import { useDragLayer, XYCoord } from 'react-dnd';

function getDragLayerStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'block',
        };
    }

    let { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}
function CustomDragLayer() {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }));

    const renderItem = () => {
        return (
            <div className="dragitem">
                <Person
                    playerData={item.playerData}
                    isDealer={false}
                    isBottomRow={false}
                />
            </div>
        );
    };

    if (!isDragging) {
        return null;
    }

    return (
        <div className="draglayer">
            <div style={getDragLayerStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    );
}

export const BoardPosUsers = memo(({ players, dealer, idGame }) => {
    return (
        <>
            {players?.length === 2 && (
                <div className="grid-board row d-flex">
                    <CustomDragLayer />

                    <div className="d-flex justify-content-center" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-center box-player-center" style={{ width: '33.33%' }} >
                            <Person
                                playerData={dealer}
                                isDealer={true}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '80%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "210px", marginTop: "105px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={1}
                            />
                        </div>

                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "195px", marginTop: "105px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={2}
                            />
                        </div>
                    </div>
                </div>
            )}
            {players?.length === 3 && (
                <div className="grid-board row d-flex">
                    <CustomDragLayer />

                    <div className="d-flex justify-content-center" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-center box-player-center" style={{ width: '33.33%' }} >
                            <Person
                                playerData={dealer}
                                isDealer={true}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '80%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "210px", marginTop: "105px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={1}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginTop: "185px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={2}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "195px", marginTop: "105px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={3}
                            />
                        </div>
                    </div>
                </div>
            )}
            {players?.length === 4 && (
                <div className="grid-board row d-flex">
                    <CustomDragLayer />

                    <div className="d-flex justify-content-center" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-center box-player-center" style={{ width: '33.33%' }} >
                            <Person
                                playerData={dealer}
                                isDealer={true}
                            />
                        </div>
                    </div>

               

                    <div className="d-flex justify-content-between" style={{ height: '40%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "135px", marginTop: "125px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={1}
                            />
                        </div>

                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "115px", marginTop: "125px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={2}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '40%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "210px", marginTop: "-53px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={3}
                            />
                        </div>

                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "195px", marginTop: "-53px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={4}
                            />
                        </div>
                    </div>
                </div>
            )}
            {players?.length === 5 && (
                <div className="grid-board row d-flex">
                    <CustomDragLayer />

                    <div className="d-flex justify-content-center" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-center box-player-center" style={{ width: '33.33%' }} >
                            <Person
                                playerData={dealer}
                                isDealer={true}
                            />
                        </div>
                    </div>

               

                    <div className="d-flex justify-content-between" style={{ height: '40%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "135px", marginTop: "125px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={1}
                            />
                        </div>

                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "115px", marginTop: "125px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={2}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '40%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "210px", marginTop: "-53px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={3}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginTop: "25px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={4}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "195px", marginTop: "-53px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={5}
                            />
                        </div>
                    </div>
                </div>
            )}
            {players?.length === 6 && (
                <div className="grid-board row d-flex">
                    <CustomDragLayer />

                    <div className="d-flex justify-content-center" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-center box-player-center" style={{ width: '33.33%' }} >
                            <Person
                                playerData={dealer}
                                isDealer={true}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "95px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={1}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "80px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={2}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "130px", marginTop: "30px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={3}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "115px", marginTop: "30px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={4}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '40%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "210px", marginTop: "-53px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={5}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "195px", marginTop: "-53px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={6}
                            />
                        </div>
                    </div>
                </div>
            )}
            {players?.length === 7 && (
                <div className="grid-board row d-flex">
                    <CustomDragLayer />

                    <div className="d-flex justify-content-center" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-center box-player-center" style={{ width: '33.33%' }} >
                            <Person
                                playerData={dealer}
                                isDealer={true}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "95px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={1}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "80px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={2}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '20%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "130px", marginTop: "30px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={3}
                            />
                        </div>

                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "115px", marginTop: "30px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={4}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ height: '40%' }}>
                        <div className="d-flex justify-content-start box-player-center" style={{ width: '80px', justifyContent: 'flex-start', marginLeft: "210px", marginTop: "-53px" }}>
                            <DragndropContainer
                                playerData={players[2]}
                                idGame={idGame}
                                positionBoard={5}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginTop: "25px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={6}
                            />
                        </div>
                        <div className="d-flex box-player-center" style={{ width: '80px', marginRight: "195px", marginTop: "-53px" }}
                        >
                            <DragndropContainer
                                playerData={players[3]}
                                idGame={idGame}
                                positionBoard={7}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});
