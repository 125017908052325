import { differenceInSeconds, addHours } from 'date-fns';
import { useEffect, useState } from 'react';
import ShowMessages from '../Exceptions';

const BoardInfo = ({ boardData }) => {
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  const [screenHeigth, setScreenHeigth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleFullScreen = (e) => {
    if (window.screen.orientation?.type?.indexOf('portrait') >= 0) {
      ShowMessages({
        message: 'Layout desenvolvido para ser usado na posição horizontal!',
        status: 325,
      });
    } else {
      if (window.screen.orientation === '') {
        ShowMessages({
          message: 'Layout desenvolvido para ser usado na posição horizontal!',
          status: 325,
        });
      }
    }
  };

  //Retorna o tempo que a mesa está aberta
  useEffect(() => {
    const interval = setInterval(() => {

      let timeInSeconds = differenceInSeconds(
        new Date(),
        addHours(
          new Date(boardData.boardTime),
          process.env.REACT_APP_DIFERENCE_TIME,
        ),
      );

      let seconds =
        Math.floor(timeInSeconds) % 60 < 10
          ? `0${Math.floor(timeInSeconds) % 60}`
          : Math.floor(timeInSeconds) % 60;
      let minutes =
        Math.floor(timeInSeconds / 60) % 60 < 10
          ? `0${Math.floor(timeInSeconds / 60) % 60}`
          : Math.floor(timeInSeconds / 60) % 60;
      let hours =
        Math.floor(timeInSeconds / 3600) < 10
          ? `0${Math.floor(timeInSeconds / 3600)}`
          : Math.floor(timeInSeconds / 3600);

      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);
    return () => clearInterval(interval);
  }, [boardData]);

  useEffect(() => {
    handleFullScreen(this);

    function handleWindowResize() {
      setScreenHeigth(window.screen.height);
      setScreenWidth(window.screen.width);
    }
    handleWindowResize()
  }, []);

  return (
    <div
      className="d-flex flex-column p-2 rounded"
      style={{ border: '1px solid #fff' }}
    >
      <span>{boardData.gameName}</span>
      <span>{boardData.gameType}</span>
      <span>{boardData.boardName}</span>
      <span>
        Tempo de Jogo: {hours}:{minutes}:{seconds}
      </span>
      <span>
        R: {screenWidth}x{screenHeigth}
      </span>
    </div>
  );
};

export default BoardInfo;
